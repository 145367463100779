<mat-dialog-content>
  <div fxLayout="row" fxLayout.lt-md="column">
    <app-frat-read-only [frat]="data" [disabled]="true" fxFlex="60"></app-frat-read-only>
    <span fxFlex="1"></span>
    <div fxLayout="column">
      <mat-toolbar>
        Metars
      </mat-toolbar>

      <app-nearest-points-list [metars]="$any(data).metars"></app-nearest-points-list>

    </div>
  </div>
  <p><small>id: {{ data.id }}</small></p>
</mat-dialog-content>
