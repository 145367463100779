import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { SentryModule } from '../../global-shared/modules/sentry.module';
import { FirebaseModule } from '../../global-shared/modules/firebase-v9.module';

import { AppNgxsModule } from './modules/ngxs.module';

import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';

import { MaterialDesignModule } from 'global-shared/modules/material-design.module';

// App Shell Components
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './views/home/home.component';

import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { UserProfileFormComponent } from './views/user-profile/user-profile-form/user-profile-form.component';
import { UsersTableComponent } from './views/users-table/users-table.component';
import { UserDialogComponent } from './layout/user-dialog/user-dialog.component';
import { AboutComponent } from './views/about/about.component';
import { SelectTenantComponent } from './components/select-tenant/select-tenant.component';
import { TenantNotFoundComponent } from './views/tenant-not-found/tenant-not-found.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

// Imported Project Shared Modules
import { AuthModule } from './auth/auth.module';
import { AlseSharedModule } from 'projects/alse/src/app/alse/alse.module';
import { CurrencySharedModule } from 'projects/currency/src/app/currency.module';
import { FratSharedModule } from 'projects/frat/src/app/frat.module';

import { SettingsComponent } from './components/settings/settings.component';
import { NavTreeComponent } from './nav-tree/nav-tree.component';
import { CanAccessDirective } from './directives/can-access.directive';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    NavComponent,
    NavTreeComponent,
    UserDialogComponent,
    UserProfileComponent,
    UserProfileFormComponent,
    UsersTableComponent,
    AboutComponent,
    TenantNotFoundComponent,
    SelectTenantComponent,
    PageNotFoundComponent,
    SettingsComponent,
    CanAccessDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SentryModule,
    FirebaseModule,
    MaterialDesignModule,
    ReactiveFormsModule,
    AppNgxsModule,
    AuthModule,
    RouterModule.forRoot(APP_ROUTES),
    AlseSharedModule.forRoot(),
    FratSharedModule.forRoot(),
    CurrencySharedModule.forRoot(),
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
