import { Observable } from 'rxjs';


import { DataSource } from '@angular/cdk/collections';
import { AppUser } from 'global-shared/models/user-account.model';
import { Store } from '@ngxs/store';
import { UsersState } from 'src/app/state/users/users.state';


/**
 * Data source for the UsersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class UsersTableDataSource extends DataSource<AppUser> {
  users$: Observable<AppUser[]>;
  count$: Observable<number>;

  constructor(
    private store: Store,
  ) {
    super();
    this.users$ = this.store.select(UsersState.usersByTenant);
    this.count$ = this.store.select(UsersState.queryCount);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<AppUser[]> {
    return this.users$;
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
  }

}
