import { Component } from '@angular/core';
import { Observable } from 'rxjs'

import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';

import { UserService } from 'global-shared/services/users/user.service';

import { AppUser } from 'global-shared/models/user-account.model';

import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from '@angular/fire/auth';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent {

  loading$: Observable<boolean>;
  user$: Observable<AppUser>;

  constructor(
    private store: Store,
    private userService: UserService
  ) {
    this.loading$ = this.store.select(AuthState.loading);
    this.user$ = this.store.select(AuthState.user);
   }

  async googleLogin(): Promise<void> {
    const response = await signInWithPopup(getAuth(), new GoogleAuthProvider());
    this.updateUser(response);
  }

  async microsoftLogin(): Promise<void> {
    console.log('microsoftLogin');
    const response = await signInWithPopup(getAuth(), new OAuthProvider('microsoft.com'));
    console.log(response);
    this.updateUser(response);
  }

  private updateUser(response): void {
    this.userService.upsertUser(response.user.uid, AppUser.fromLogin(response.user));
  }

}
