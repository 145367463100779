import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UnitFormControl } from 'global-shared/models/unit.model';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { TenantState } from 'src/app/state/tenant/tenant.state';

@Component({
  selector: 'form-unit-controls',
  templateUrl: './unit-controls.component.html',
  styleUrls: ['./unit-controls.component.css'],
  providers: subformComponentProviders(UnitControlsComponent),
})
export class UnitControlsComponent {

  callSigns$: Observable<string[]>;
  tailNumbers$: Observable<string[]>;

  constructor(
    private store: Store,
  ) {
    this.callSigns$ = this.store.select(TenantState.callSigns);
    this.tailNumbers$ = this.store.select(TenantState.tailNumbers);
  }

  public form = createForm<UnitFormControl>(this, {
    formType: FormType.SUB,
    formControls: {
      tailNumber: new FormControl(null, Validators.required),
      callSign: new FormControl(null, Validators.required),
    }
  })

}
