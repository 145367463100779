<div *ngIf="(item$ | async) as item; else loading" fxLayout="row" fxLayout.xs="column">

  <app-item-root-form
   [claims]="claims$ | async"
   [item]="item"
   (formUpdated)="upsertItem($event)"
   (handleDelete)="handleDelete($event)">
  </app-item-root-form>

  <div>
    <!-- <app-item-attachments [item]="item"></app-item-attachments> -->
    <app-item-history [item]="item"></app-item-history>
  </div>

</div>

<ng-template #loading>
  <div>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
