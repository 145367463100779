import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LASER, OneSurvival, SurvivalType } from 'global-shared/models/alse-base-models/item-types/survival-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

interface OneSurvivalForm {
  laser: LASER | null;
  itemType: SurvivalType | null;
}

@Component({
  selector: 'alse-survival-item-form',
  templateUrl: './survival-item-form.component.html',
  styleUrls: ['./survival-item-form.component.css'],
  providers: subformComponentProviders(SurvivalItemFormComponent),
})
export class SurvivalItemFormComponent {

  public SurvivalType = SurvivalType;

  public form = createForm<OneSurvival, OneSurvivalForm>(this, {
    formType: FormType.SUB,
    formControls: {
      laser: new FormControl(null),
      itemType: new FormControl(null, { validators: [Validators.required] }),
    },
    toFormGroup: (obj: LASER): OneSurvivalForm => {
      const { itemType, ...commonValues } = obj;

      return {
        laser: obj.itemType === SurvivalType.LASER ? obj : null,
        itemType: obj.itemType,
      };
    },
    fromFormGroup: (formValue: OneSurvivalForm): OneSurvival => {
      const { itemType, laser, ...commonValues } = formValue;
      switch (itemType) {
        case SurvivalType.LASER:
          return laser;
        case null:
          return null;
      }
    }
  })

}
