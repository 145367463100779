// eslint-disable-next-line no-shadow
export enum CrewMemberRole {
  PIC = 'PIC',
  SIC = 'SIC',
  TFO = 'TFO',
  HO = 'HO',
  RS = 'RS',
  RSW = 'RSW',
  CFI = 'CFI',
  INST = 'INST',
};

export interface CrewMember {
  role: CrewMemberRole;
  user: { uid: string, displayName: string }
}

