import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import { State, Action, StateContext, Selector, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { GeolocationService } from '@ng-web-apis/geolocation';

import { UpdatePosition } from './position.actions';
import { GetMetars } from '../metars/metars.actions';

export const POSITION_STATE_TOKEN = new StateToken<GeolocationPosition>('position');

@State<GeolocationPosition>({
  name: POSITION_STATE_TOKEN,
  defaults: {
    coords: {
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: null,
      longitude: null,
      speed: null,
    },
    timestamp: null,
  }
})
@Injectable()
export class PositionState {
  @Selector()
  static coords(state: GeolocationPosition): GeolocationCoordinates {
    return state.coords;
  }

  constructor(
    private readonly geolocation$: GeolocationService,
  ) { }

  @Action(UpdatePosition, { cancelUncompleted: true })
  updatePosition(ctx: StateContext<GeolocationPosition>, action: UpdatePosition): Observable<void> {
    return this.geolocation$.pipe(
      tap((position: GeolocationPosition) => {
        ctx.setState(
          patch({
            coords: patch(position.coords),
            timestamp: position.timestamp,
          })
        );
      }),
      take(1),
      mergeMap(() => ctx.dispatch(new GetMetars()))
    );
  }
}
