<mat-card *ngIf="(user$ | async) === (null) as user">
  <mat-card-header>
    <mat-card-title>
      Login
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <mat-form-field>
      <input matInput type="email" placeholder="Email" #email autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Password" #password autocomplete="off">
    </mat-form-field>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="center">
    <button mat-raised-button (click)="login(email.value, password.value)" tabindex="0">
      <mat-icon>login</mat-icon>
      Login
    </button>
    <button mat-raised-button (click)="signUp(email.value, password.value)" tabindex="1">
      <mat-icon>person_add</mat-icon>
      Create user
    </button>
  </mat-card-actions>

</mat-card>
