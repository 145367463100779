import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LASER, SurvivalType, SurvivalTypeNumber } from 'global-shared/models/alse-base-models/item-types/survival-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'alse-laser-item',
  templateUrl: './laser-item.component.html',
  styleUrls: ['./laser-item.component.css'],
  providers: subformComponentProviders(LaserItemComponent),
})
export class LaserItemComponent {

  public form = createForm<LASER>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(SurvivalType.LASER, { validators: [Validators.required] }),
      itemNumber: new FormControl(SurvivalTypeNumber.LASER, { validators: [Validators.required] }),
    }
  })
}
