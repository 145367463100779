<fieldset [formGroup]="form.formGroup">
  <legend>Hardware form</legend>

  <mat-form-field>
    <mat-select
      data-select-hardware-type
      placeholder="Select hardware type"
      [formControlName]="form.formControlNames.itemType"
    >
      <mat-option
        data-select-hardware-type-option
        *ngFor="let hardwareType of HardwareType | keyvalue"
        [value]="hardwareType.value"
      >
        {{ hardwareType.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div [ngSwitch]="form.formGroup.value?.itemType">
    <app-carabiner-item *ngSwitchCase="HardwareType.CARABINER"
    [formControlName]="form.formControlNames.carabiner"></app-carabiner-item>
    <app-delta-link-item *ngSwitchCase="HardwareType.DELTALINK"
    [formControlName]="form.formControlNames.deltaLink"></app-delta-link-item>
    <app-map-ring-item *ngSwitchCase="HardwareType.MAPRING"
    [formControlName]="form.formControlNames.mapRing"></app-map-ring-item>
  </div>

</fieldset>
