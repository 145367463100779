import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CurrencyRecord } from '../models/currency-record.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyTrackerService {

  constructor(
    private http: HttpClient
  ) { }

  /* GET trainee whose id contains search term */
  getCurrencyRecordJSON(traineeId: string): Observable<CurrencyRecord> {
    const URL = `https://script.google.com/macros/s/AKfycbwY17k3p72nkzKh03Ij9U3mU4dbP01Jiwj0LrEYRXK6L7OWIquWgp6R/exec?key=currencyTracker&id=${traineeId}`;

    return this.http.get<CurrencyRecord>(URL).pipe(
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
