import { Component } from '@angular/core';

@Component({
  selector: 'app-alse-home',
  templateUrl: './alse-home.component.html',
  styleUrls: ['./alse-home.component.css']
})
export class AlseHomeComponent {

}
