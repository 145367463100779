import { Component, OnDestroy } from '@angular/core';
import { CurrencyRecord } from './models/currency-record.model';
import { CurrencyTrackerService } from './services/currency-tracker.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'currency-root',
  templateUrl: './currency-app.component.html',
  styleUrls: ['./currency-app.component.css']
})
export class CurrencyAppComponent {
  loading = new BehaviorSubject<boolean>(false);
  traineeCurrency: CurrencyRecord | null;
  error;

  constructor(
    private currencyService: CurrencyTrackerService
  ) { }

  lookup(id: string): void {
    this.error = '';
    this.traineeCurrency = null;
    this.loading.next(true);
    this.currencyService.getCurrencyRecordJSON(id).pipe(
      tap(record => record.error ? this.error = record : this.traineeCurrency = record),
      tap(record => this.loading.next(false)),
      take(1)
    ).subscribe(console.log);
  }
}
