<fieldset [formGroup]="form.formGroup"
 [class.invalid]="form.formGroupErrors">
  <legend>Details</legend>

<mat-form-field>
  <mat-label>Flight Type</mat-label>
  <mat-select [formControlName]="form.formControlNames.type">
    <mat-option *ngFor="let type of (flightTypes$ | async)" [value]="type">
      {{ type }}
    </mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field appearance="fill" style="width: 100%;">
  <mat-label>Comments</mat-label>
  <textarea matInput placeholder="Comments..." [formControlName]="form.formControlNames.comments"></textarea>
</mat-form-field>

</fieldset>
