import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { UsersTableDataSource } from './users-table-datasource';
import { UserService } from 'global-shared/services/users/user.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { AppUser } from 'global-shared/models/user-account.model';
import { Store } from '@ngxs/store';
import { UsersActions } from 'src/app/state/users/users.actions';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: [
    './users-table.component.css',
    '../../../../global-shared/styles/tables.global.css'
  ]
})
export class UsersTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: UsersTableDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['displayName', 'email'];

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private store: Store,
  ) {
    // this.userService.queryConstraints$.next({
    //   active: 'displayName', direction: 'asc', pageSize: 5
    // })
  }

  ngOnInit(): void {
    // this.store.dispatch(new UsersActions.GetQuery());
    this.dataSource = new UsersTableDataSource(this.store);
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap((values) => {
    //       this.userService.queryConstraints$.next({
    //         ...this.userService.queryConstraints$.value,
    //         ...values
    //       })
    //     }),
    //   ).subscribe();
  }

  openDialog(user: AppUser): void {
    console.log({ user })
    this.dialog.open(UserProfileComponent, {
      data: user
    });
  }
}
