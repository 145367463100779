import { NgModule } from '@angular/core';

import { environment } from 'src/environments/environment';

import { NgxsModule } from '@ngxs/store';
import { ngxsConfig } from 'global-shared/config/ngxs.config';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';

import { AppRouterStateSerializer } from '../core/ngxs/router.serializer';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';

import { LayoutState } from '../layout/state/layout.state';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { AuthState } from '../auth/state/auth.state';
import { QuestionsState } from 'projects/frat/src/app/core/state/tenant-questions/questions.state';
import { FratState } from 'projects/frat/src/app/core/state/frat/frat.state';
import { NavTreeState } from '../nav-tree/state/nav-tree.state';
import { TenantState } from '../state/tenant/tenant.state';
import { UsersState } from '../state/users/users.state';


const MODULES = [
  NgxsModule.forRoot([
    AuthState,
    LayoutState,
    NavTreeState,
    PreferencesState,
    TenantState,
    UsersState,
  ], ngxsConfig),
  NgxsStoragePluginModule.forRoot({
    key: [
      PreferencesState,
      QuestionsState,
      FratState,
    ]
  }),
  NgxsDispatchPluginModule.forRoot(),
  NgxsSelectSnapshotModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
  // These need to always be included last.
  environment.ngxs.plugins,
];

@NgModule({
  declarations: [],
  imports: [
    MODULES
  ],
  exports: [
    MODULES
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: AppRouterStateSerializer,
    }
  ]
})
export class AppNgxsModule { }
