import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';
import { switchMap, map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { UserService } from 'global-shared/services/users/user.service';
import { AppUser, AppUserMetadata } from 'global-shared/models/user-account.model';

import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { AuthActions } from './state/auth.actions';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { SetTenant } from 'global-shared/states/preferences/preferences.actions';
import { environment } from 'src/environments/environment';
import { Auth, authState, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, User } from '@angular/fire/auth';
import { AuthState } from './state/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userMetaData$: Observable<AppUserMetadata>;

  constructor(
    private auth: Auth,
    private store: Store,
    private userService: UserService
  ) { }

  checkClaims(roles: string[], role: string): boolean {
    console.log({ roles, role })
    if (roles) {
      return roles.includes(role as never);
    }
    return false;
  }

  hasDisplayName() {
    // const { displayName } = this.store.selectSnapshot(AuthState.user);
    return Boolean(this.store.selectSnapshot(AuthState.user)?.displayName);
  }

  async login(email: string, password: string): Promise<void> {
    const response = await signInWithEmailAndPassword(this.auth, email, password);
    const user = AppUser.fromLogin(response.user);
    this.userService.upsertUser(response.user.uid, user);
  }

  // Using this for ngxs auth state will be transitioning to this compeletely.
  // There are some random afAuth.signout codeded esle where.
  async signout(): Promise<void> {
    await signOut(this.auth);
  }

  async signup(email: string, password: string): Promise<void> {
    const response = await createUserWithEmailAndPassword(this.auth, email, password);
    const user = AppUser.fromSignup(response.user);
    this.userService.upsertUser(response.user.uid, user);
  }



  // Intiaizlied in app.component.ts so its available at bootstrap;
  /**
   * Initiate the firebase auth and pass to the auth store
   */
  public init(): Observable<User | Observable<null>> {
    // if (environment.useEmulators) {
    //   this.login('lenny@dev.com', 'devtest');
    // }

    return authState(this.auth).pipe(
      switchMap(async user => {
        // Logged in
        if (user) {
          this.store.dispatch([
            new AuthActions.User(AppUser.fromLogin(user)),
          ]);
          this.updateUser(user);
          // Subscribe and listen to claim changes in one observable
          (await this.watchUserMetadata(user)).subscribe();
          return user;
        } else {
          // Logged out
          this.store.dispatch([
            new AuthActions.User(null),
            new AuthActions.Claims(null),
            new Navigate(['signin'])
          ]);
          return of(null);
        }
      })
    );
  }

  private async watchUserMetadata(user?: User) {
    return this.userService.getUserMetadata(user.uid).pipe(
      distinctUntilChanged((prev, curr) => prev?.refreshTime === curr?.refreshTime),
      withLatestFrom(this.store.select(PreferencesState.tenant)),
      map(async (values: [AppUserMetadata, string]) => {
        const [metaData, tenant] = values;
        if (metaData) {
          const token = await user.getIdTokenResult(true);
          const claims = token?.claims;
          const tenants = claims?.tenants;
          const tenantsKeys = Object.keys(tenants);

          if (!tenant && tenantsKeys.length > 0) {
            this.store.dispatch([
              new SetTenant(tenantsKeys[0]),
              new Navigate([tenantsKeys[0], 'home'])
            ]);
          }
          this.store.dispatch(new AuthActions.Claims(claims || null));
        }
      })
    );
  }

  private updateUser(user: User): void {
    this.userService.upsertUser(user.uid, AppUser.fromLogin(user));
  }

}
