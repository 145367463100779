import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';
import { FLIGHT_STATUS_OPTIONS } from 'global-shared/models/flight.model';
import { Observable } from 'rxjs';
import { FratActions } from '../../../core/state/frat/frat.actions';
import { FratState } from '../../../core/state/frat/frat.state';

@Component({
  selector: 'frat-actions-bar',
  templateUrl: './frat-actions-bar.component.html',
  styleUrls: ['./frat-actions-bar.component.css']
})
export class FratActionsBarComponent {

  _flightStatus = FLIGHT_STATUS_OPTIONS;

  scores$: Observable<any>;

  @Input() submitReady: boolean;

  @Output() manualSave = new EventEmitter<boolean>();

  constructor(
    private store: Store,
  ) {
    this.scores$ = this.store.select(FratState.scores);
  }

  // @Dispatch()
  // private setStatus = (status) => new FratActions.SetStatus(status);

  handleSubmission(status: FLIGHT_STATUS_OPTIONS) {
    this.store.dispatch(new FratActions.SetStatus(status))
      .subscribe(status => this.manualSave.emit(true))
      .unsubscribe()
  }

}
