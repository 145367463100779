import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { collection, collectionData, CollectionReference, doc, docData, DocumentData, DocumentReference, Firestore, OrderByDirection, Query, serverTimestamp, setDoc, Timestamp, updateDoc, writeBatch } from '@angular/fire/firestore';

import { Store } from '@ngxs/store';

import { DB } from 'global-shared/models/db.model';
import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';
import { convertTimestampsPipe } from 'global-shared/pipes/convert-firebase-timestamp/convert-firebase-timestamp.pipe';
import { OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';
import { ItemInspections } from 'global-shared/models/alse-base-models/inspection-presets';
import { AlseItemInspection, InspectionStatus, InspectionType } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';

// import firebase from 'firebase/compat/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
// import { FirestoreService } from 'src/app/core/firebase/firestore.service';


@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(
    // private afs: FirestoreService,
    private db: AngularFirestore,
    private firestore: Firestore,
    private store: Store,
  ) { }

  get tenant(): string {
    return this.store.selectSnapshot(PreferencesState.tenant);
  }

  get basePath(): string {
    return `tenants/${this.tenant}/alse/metadata`;
  }

  private get collection() {
    return <T = DocumentData>(path?: string) => {
      return collection(this.firestore, this.basePath, path) as CollectionReference<T>;
    }
  }

  private get doc() {
    return <T = DocumentData>(path?: string) => {
      return doc(this.firestore, this.basePath, path) as DocumentReference<T>;
    }
  }

  /// Firebase Server Timestamp
  private get timestamp() {
    return serverTimestamp();
  }

  createItemId(): string {
    return doc(this.collection('items')).id;
  }

  createItem(item: OneAlseItem): void {
    item.tenant = this.tenant;
    item.createdAt = new Date();
    item.updatedAt = new Date();
    item.inspections = [];
    const itemNumber = ItemInspections[item.category.categoryNumber][item.item.itemNumber] ? item.item.itemNumber : 'default';
    const inspections = [...ItemInspections[item.category.categoryNumber][itemNumber]];
    inspections.map((inspection: AlseItemInspection, index) => {
      let startInspectionsDate = item.startInspectionsDate;
      if (inspection.type === InspectionType.ENDOFLIFE &&
        item.manufacturerDetails.manufacturerDate &&
        item.manufacturerDetails.manufacturerEndOfLifeSpan
      ) {
        inspection.interval = item.manufacturerDetails.manufacturerEndOfLifeSpan * 365;
        startInspectionsDate = item.manufacturerDetails.manufacturerDate;
      }
      inspection.status = InspectionStatus.DUE;
      AlseItemInspection.bumpInspectionCycle(inspection, startInspectionsDate);
    });

    const batch = writeBatch(this.firestore);

    const itemRef = `${DB.Collection.ITEMS}/${item.id}`;
    const itemDoc = this.doc(itemRef);

    const itemInspectionsCol = this.collection<AlseItemInspection>(`${itemRef}/${DB.Collection.INSPECTIONS}`);
    const itemHistoryCol = this.collection<InspectionRecord>(`${itemRef}/${DB.Collection.HISTORY}`);

    inspections.map((inspection: AlseItemInspection) => {
      const inspId = doc(itemInspectionsCol).id;
      inspection.id = inspId;
      inspection.tenant = this.tenant;
      inspection.itemId = item.id;
      inspection.itemType = item.item.itemType;
      inspection.categoryNumber = item.category.categoryNumber;
      inspection.itemNumber = item.item.itemNumber;
      batch.set(this.doc(`${itemRef}/${DB.Collection.INSPECTIONS}/${inspId}`), inspection);
    });

    batch.set(itemDoc, item);

    const record: InspectionRecord = {
      id: doc(itemHistoryCol).id,
      itemId: item.id,
      inspectionId: null,
      ssn: item.ssn,
      tenant: item.tenant,
      itemType: item.item.itemType,
      categoryNumber: item.category.categoryNumber,
      itemNumber: item.item.itemNumber,
      location: item.location,
      type: InspectionType.ADDED,
      status: InspectionStatus.COMPLETE,
      user: '', // this.auth.user.displayName,
      qaUser: null,
      notes: 'Added to the ALSE System',
      dateDue: null,
      datePerformed: new Date(),
      attachment: null,
    };

    //batch.set(itemHistoryCol.ref.doc(record.id), record);
    batch.set(doc(itemHistoryCol, record.id), record)

    batch.commit();
  }

  // deleteItem(id: string): void {
  //   deleteCollection(`${this.basePath}/${DB.Collection.ITEMS}/${id}/${DB.Collection.HISTORY}`, 1000)
  //     .toPromise();
  //   this.afs.deleteCollection(`${this.basePath}/${DB.Collection.ITEMS}/${id}/${DB.Collection.ATTACHMENTS}`, 1000)
  //     .toPromise();
  //   this.afs.deleteCollection(`${this.basePath}/${DB.Collection.ITEMS}/${id}/${DB.Collection.INSPECTIONS}`, 10)
  //     .toPromise()
  //     .then(res => this.db.doc(`${this.basePath}/${DB.Collection.ITEMS}/${id}`).delete());
  // }

  // findItems(
  //   active: string,
  //   sortDirection: OrderByDirection,
  //   pageSize: number,
  //   cursor?): AngularFirestoreCollection<OneAlseItem> {

  //   return this.db.collection<OneAlseItem>(`${this.basePath}/${DB.Collection.ITEMS}`, ref => {
  //     return ref.where('tenant', '==', this.tenant)
  //       .orderBy(active, sortDirection)
  //       .startAfter(cursor)
  //       .limit(pageSize);
  //   });
  // }

  // getItem(id: string): Observable<OneAlseItem> {
  //   console.log('getItem.id', id);
  //   const itemDocRef = this.db.doc<OneAlseItem>(`${this.basePath}/${DB.Collection.ITEMS}/${id}`);
  //   const itemInspectionsCol = itemDocRef.collection<AlseItemInspection>(DB.Collection.INSPECTIONS);

  //   const item$ = docData<OneAlseItem>(itemDocRef.ref, 'id');
  //   const inspections$ = collectionData<AlseItemInspection>(itemInspectionsCol.ref, 'id');

  //   return item$.pipe(
  //     tap((value) => console.log('start', value)),
  //     withLatestFrom(inspections$),
  //     tap((value) => console.log('after.withLatestFrom', value)),
  //     map((values: [OneAlseItem, AlseItemInspection[]]) => {
  //       const [item, inspections] = values;
  //       return {
  //         ...item,
  //         inspections,
  //       };
  //     }),
  //     tap((value) => console.log('after', value)),
  //     convertTimestampsPipe(),
  //   );
  // }

  getItem(id: string): Observable<OneAlseItem> {
    const itemRef = `${DB.Collection.ITEMS}/${id}`;
    const itemDocRef = this.doc<OneAlseItem>(itemRef);
    const itemInspectionsCol = this.collection<AlseItemInspection>(`${itemRef}/${DB.Collection.INSPECTIONS}`);

    const item$ = docData<OneAlseItem>(itemDocRef, { idField: 'id' });
    const inspections$ = collectionData<AlseItemInspection>(itemInspectionsCol, { idField: 'id' });

    return combineLatest([item$, inspections$])
      .pipe(
        map(values => {
          const [item, inspections] = values;
          return {
            ...item,
            inspections
          };
        }),
        // convertTimestampsPipe(),
      );
  }

  listItems(): Observable<OneAlseItem[]> {
    return collectionData<OneAlseItem>(this.collection(`${DB.Collection.ITEMS}`), { idField: 'id' });
    // return this.db.collection<OneAlseItem>(`${this.basePath}/${DB.Collection.ITEMS}`, ref =>
    //   ref.where('tenant', '==', this.tenant)
    // ).valueChanges({ idField: 'id' });
  }

  tableItems(
    // filter: string,
    active: string,
    sortDirection: OrderByDirection,
    pageSize: number,
    pageIndex: number,
    previousPageIndex: number,
    cursor?): AngularFirestoreCollection<OneAlseItem> {
    console.log('table filter/sort/paginator', active, sortDirection, pageSize, cursor);
    return this.db.collection<OneAlseItem>(`${this.basePath}/${DB.Collection.ITEMS}`, ref => {
      let query: any = ref;
      query = query.where('tenant', '==', this.tenant);
      // if (pageSize) { query = query.limit(pageSize); }
      if (active && sortDirection) { query = query.orderBy(active, sortDirection).limit(pageSize); }
      if (cursor && previousPageIndex < pageIndex) { query = query.startAfter(cursor).limit(pageSize); }
      if (cursor && previousPageIndex > pageIndex) { query = query.endBefore(cursor).limitToLast(pageSize); }
      return query;
    });
  }

  alseMetadata(): Observable<DB.AlseMetadata> {
    return docData(doc(this.firestore, this.basePath) as DocumentReference<DB.AlseMetadata>);
  }

  upsertItem(item: OneAlseItem): void {
    item.ssn ? this.updateItem(item) : this.createItem(item);
  }

  updateItem(item: Partial<OneAlseItem>): void {
    updateDoc(this.doc(item.id), {
      ...item,
      updatedAt: this.timestamp
    })
  }

}
