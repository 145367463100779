<fieldset [formGroup]="form.formGroup" class="container">
  <legend>Harness form</legend>

  <mat-form-field>
    <mat-select
      data-select-harness-type
      placeholder="Select harness type"
      [formControlName]="form.formControlNames.itemType"
    >
      <mat-option
        data-select-harness-type-option
        *ngFor="let harnessType of HarnessType | keyvalue"
        [value]="harnessType.value"
      >
        {{ harnessType.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div [ngSwitch]="form.formGroup.value?.itemType">
    <app-chameleon-item *ngSwitchCase="HarnessType.CHAMELEON"
    [formControlName]="form.formControlNames.chameleon"></app-chameleon-item>
    <app-triton-item *ngSwitchCase="HarnessType.TRITON"
     [formControlName]="form.formControlNames.triton"></app-triton-item>
  </div>

</fieldset>
