import { ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@NgModule({
  // ...
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  // ...
})
export class SentryModule { }
