import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { AppUser } from "global-shared/models/user-account.model";
import { tap } from "rxjs/operators";
import { TenantService } from "src/app/services/tenant.service";
import { TenantActions } from "./tenant.actions";

export interface TenantStateModel {
  loading: boolean;
  appModules: string[];
  callSigns: string[];
  callTypes: string[];
  flightTypes: string[];
  locations: string[];
  tailNumbers: string[];
  theme: string;
  tenant: any;
  users: Partial<AppUser>[];
}

export const TENANT_STATE_TOKEN = new StateToken<TenantStateModel>('tenant');

@State({
  name: TENANT_STATE_TOKEN,
  defaults: {
    loading: false,
    appModules: [],
    callSigns: [],
    callTypes: [],
    flightTypes: [],
    locations: [],
    tailNumbers: [],
    theme: null,
    tenant: null,
    users: [],
  }
})
@Injectable()
export class TenantState {

  @Selector()
  public static appModules(state: TenantStateModel): string[] {
    return state.appModules;
  }

  @Selector()
  public static callSigns(state: TenantStateModel): string[] {
    return state.callSigns
  }

  @Selector()
  public static callTypes(state: TenantStateModel): string[] {
    return state.callTypes
  }

  @Selector()
  public static flightTypes(state: TenantStateModel): string[] {
    return state.flightTypes;
  }

  @Selector()
  public static locations(state: TenantStateModel): string[] {
    return [
      ...state.locations,
      ...state.tailNumbers,
    ]
  }

  @Selector()
  public static tailNumbers(state: TenantStateModel): string[] {
    return state.tailNumbers;
  }

  @Selector()
  public static theme(state: TenantStateModel): string {
    return state.theme;
  }

  @Selector()
  public static tenant(state: TenantStateModel): any {
    return state.tenant;
  }

  @Selector()
  public static users(state: TenantStateModel): Partial<AppUser>[] {
    return state.users
  }

  constructor(
    private tenantService: TenantService,
  ) { }

  @Action(TenantActions.GetTenant)
  getCurrentTenant({ setState }: StateContext<TenantStateModel>) {
    return this.tenantService.getCurrentTenant().pipe(
      tap((tenantData: TenantStateModel) => {
        const users = Object.values(tenantData.users)
        setState({
          ...tenantData,
          users
        })
      })
    ).subscribe()
  }
}
