
import { FLIGHT_STATUS_OPTIONS, FlightStatus } from 'global-shared/models/flight.model';
import { FratValues } from 'global-shared/models/frat.model';
import { UnitFormControl } from 'global-shared/models/unit.model';
import { FratSectionV2 } from 'global-shared/models/question.model';
import { FratStateModel } from './frat.state';

export namespace FratActions {

  export class New {
    static readonly type = '[FRAT] Start New';
    constructor(public action: 'dynamic' | 'new') { }
  }

  export class SetLoading {
    static readonly type = '[FRAT] Set Loading Status';
    constructor(public payload: boolean) { }
  }

  export class SetMetars {
    static readonly type = '[FRAT] Set Metars';
    constructor(public payload: any) { }
  }

  export class SetPosition {
    static readonly type = '[FRAT] Set Position';
    constructor(public payload: GeolocationPosition) { }
  }

  export class SetSections {
    static readonly type = '[FRAT] Set Sections';
    constructor(public payload: FratSectionV2[]) { }
  }

  export class SetSaved {
    static readonly type = '[FRAT] Set Saved';
    constructor(public payload: boolean) { }
  }

  export class SetScore {
    static readonly type = '[FRAT] Set Score';
    constructor(public payload: FratValues) { }
  }

  export class SetUnit {
    static readonly type = '[FRAT] Set Unit';
    constructor(public payload: UnitFormControl) {}
  }

  export class SetStatus {
    static readonly type = '[FRAT] Set Flight Status';
    constructor(public payload: FLIGHT_STATUS_OPTIONS) {};
  }

  export class SetSubmittedBy {
    static readonly type = '[FRAT] Submitted By';
    constructor(public payload: string) {}
  }

  export class UpdateFrat {
    static readonly type = '[FRAT] Update Frat';
    constructor(public payload: Partial<FratStateModel>) {}
  }

}

