<fieldset [formGroup]="form.formGroup"
[class.invalid]="form.formGroupErrors"
 fxLayout.gt-sm="row"
 fxFlex fxLayout="row wrap" fxLayoutAlign="center">
  <legend>Time information</legend>

  <mat-form-field appearance="fill" fxFlex.gt-sm="50%">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate
        #start
        (ngModelChange)="addTimesToDates()"
        [formControlName]="form.formControlNames.start">
      <input matEndDate
        #end
        (ngModelChange)="addTimesToDates()"
       [formControlName]="form.formControlNames.end">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field [ngClass.gt-sm]="'time-input'">
    <mat-label>Start Time</mat-label>
    <input #startTime matInput type="time" (ngModelChange)="addTimesToDates()" [formControlName]="form.formControlNames.startTime" />
  </mat-form-field>

  <mat-form-field [ngClass.gt-sm]="'time-input'">
    <mat-label>End Time</mat-label>
    <input #endTime matInput type="time" (ngModelChange)="addTimesToDates()" [formControlName]="form.formControlNames.endTime" />
  </mat-form-field>

  <mat-form-field [ngClass.gt-sm]="'time-input'">
    <mat-label>Flight time</mat-label>
    <input matInput type="number" [formControlName]="form.formControlNames.flightTime"/>
  </mat-form-field>

</fieldset>

<!-- <section>
  <pre>{{ form.controlValue$ | async | json }}</pre>
</section> -->
