import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetProject, SetTenant } from './preferences.actions';

export interface PreferencesStateModel {
  tenant: string;
  project: string;
}

const PREFERENCES_STATE_TOKEN = new StateToken<PreferencesStateModel>('preferences');

@State({
  name: PREFERENCES_STATE_TOKEN,
  defaults: {
    tenant: null,
    project: null,
  }
})
@Injectable()
export class PreferencesState {
  @Selector()
  public static tenant(state: PreferencesStateModel): string {
    return state.tenant;
  }

  @Selector()
  public static project(state: PreferencesStateModel): string {
    return state.project;
  }

  @Action(SetTenant)
  setTenant({ patchState }: StateContext<PreferencesStateModel>, { payload }: SetTenant): void {
    patchState({ tenant: payload });
  }

  @Action(SetProject)
  setApp({ patchState }: StateContext<PreferencesStateModel>, { payload }: SetProject): void {
    patchState({ project: payload });
  }
}
