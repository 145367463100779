<ng-container [formGroup]="form.formGroup" class="container" data-crew-member>

  <div fxLayout="row" fxLayout.lt-md="column" fxFlex>

    <mat-form-field appearance="fill">
      <mat-label>Role</mat-label>
      <mat-select [formControlName]="form.formControlNames.role" name="role">
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Crew member</mat-label>
      <mat-select [compareWith]="compareUserWith" [formControlName]="form.formControlNames.user" name="user">
        <mat-option *ngFor="let user of (users$ | async)" [value]="user">
          {{ user.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

</ng-container>
