import { Attachment } from '../item-records/alse-file-attachment';
import { InspectionStatus, InspectionType } from './alse-inspection';

export interface InspectionRecord {
  id: string;
  itemId: string;
  inspectionId: string;
  ssn: string;
  tenant: string;
  itemType: string;
  categoryNumber: string;
  itemNumber: string;
  location: string;
  type: InspectionType;
  status: InspectionStatus;
  user: string;
  qaUser: string;
  notes: string;
  dateDue: Date;
  datePerformed: Date;
  attachment: Attachment;
}

export interface InspectionRecordForm {
  id: string;
  location: string;
  status: string;
  datePerformed: Date;
  notes: string;
  user: string;
  qaUser: string;
}

export class InspectionRecord {
  constructor(data: Partial<InspectionRecord>) {
    this.id = data.id || null;
    this.itemId = data.itemId || null;
    this.inspectionId = data.inspectionId || null;
    this.ssn = data.ssn || null;
    this.tenant = data.tenant || null;
    this.itemType = data.itemType || null;
    this.categoryNumber = data.categoryNumber || null;
    this.itemNumber = data.itemNumber || null;
    this.location = data.location || null;
    this.type = data.type || null;
    this.status = data.status || InspectionStatus.DUE;
    this.user = data.user || null;
    this.qaUser = data.qaUser || null;
    this.notes = data.notes || null;
    this.dateDue = data.dateDue || null;
    this.datePerformed = data.datePerformed || null;
    this.attachment = data.attachment || null;
  }

  static forNgxForm(data: Partial<InspectionRecord>): InspectionRecordForm {
    // console.log({data});
    return {
      id: data.id || null,
      location: data.location || null,
      status: data.status || null,
      datePerformed: data.datePerformed || null,
      notes: data.notes || null,
      user: data.user || null,
      qaUser: data.qaUser || null,
    };
  }
}
