import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Size } from 'global-shared/models/alse-base-models/item-base/alse-base';
import { HELMET, PpeType, PpeTypeNumber } from 'global-shared/models/alse-base-models/item-types/ppe-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'alse-helmet-item',
  templateUrl: './helmet-item.component.html',
  styleUrls: ['./helmet-item.component.css'],
  providers: subformComponentProviders(HelmetItemComponent),
})
export class HelmetItemComponent {
  public size = Size;

  public form = createForm<HELMET>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(PpeType.HELMET, { validators: [Validators.required] }),
      itemNumber: new FormControl(PpeTypeNumber.HELMET, { validators: [Validators.required] }),
      size: new FormControl(null, { validators: [Validators.required] })
    }
  })
}
