<fieldset [formGroup]="form.formGroup" class="container">
  <legend>Manufacturer details</legend>

  <mat-form-field>
    <input matInput placeholder="Manufacturer name" [formControlName]="form.formControlNames.manufacturerName">
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Serial number" [formControlName]="form.formControlNames.serialNumber">
  </mat-form-field>

  <mat-form-field>
    <input matInput #manufacturerDateInput [matDatepicker]="manufacturerDate" placeholder="Manufacturer Date"
      [formControlName]="form.formControlNames.manufacturerDate">
    <mat-datepicker-toggle matSuffix [for]="manufacturerDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #manufacturerDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput
     #manufacturerEndOfLifeSpan
     type="number"
     placeholder="Life span in years"
     [formControlName]="form.formControlNames.manufacturerEndOfLifeSpan">
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="manufacturerEndOfLifeDate" placeholder="Manufacturer EOL Date"
      [formControlName]="form.formControlNames.manufacturerEndOfLifeDate">
    <mat-datepicker-toggle matSuffix [for]="manufacturerEndOfLifeDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #manufacturerEndOfLifeDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Item number" [formControlName]="form.formControlNames.itemNumber">
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Lot number" [formControlName]="form.formControlNames.lotNumber">
  </mat-form-field>

</fieldset>
