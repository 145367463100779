import { InspectionType } from './item-inspections/alse-inspection';

export const ItemInspections = {
  '00': { // Miscellaneous
    '002': [
      {
        type: InspectionType.POSTUSE,
        interval: 0,
      }
    ]
  },
  '10': { // Hardware
    default: [// Carabiner
      {
        type: InspectionType.BUILDUP,
        interval: 0,
      },
      {
        type: InspectionType.INSPECT,
        interval: 30,
      },
      {
        type: InspectionType.ENDOFLIFE,
        interval: 3652
      }
    ],
  },
  '20': { // Webbing, Rope, and Web Gear

  },
  '60': { // Harnesses
    '001': [ // Chameleon
      {
        type: InspectionType.BUILDUP,
        interval: 0
      },
      {
        type: InspectionType.INSPECT,
        interval: 180
      },
      {
        type: InspectionType.ENDOFLIFE,
        interval: 3652
      }
    ],
    '002': [ // Triton
      {
        type: InspectionType.BUILDUP,
        interval: 0
      },
      {
        type: InspectionType.INSPECT,
        interval: 180
      },
      {
        type: InspectionType.FUNCTIONAL,
        interval: 730
      },
      {
        type: InspectionType.ENDOFLIFE,
        interval: 3652
      }
    ],
  },
  '70': { //Signaling, Survival Gear
    '004': [
      {
        type: InspectionType.BUILDUP,
        interval: 0
      },
      {
        type: InspectionType.INSPECT,
        interval: 90
      },
      {
        type: InspectionType.ENDOFLIFE,
        interval: 3652
      }
    ]
  },
  '90': { // Flight Gear, Protection, and Floatation
    '001': [ // Hemlet
      {
        type: InspectionType.BUILDUP,
        interval: 0
      },
      {
        type: InspectionType.INSPECT,
        interval: 180
      }
    ],
    '002': [ // NVG
      {
        type: InspectionType.BUILDUP,
        interval: 0
      },
      {
        type: InspectionType.INSPECT,
        interval: 180,
      }
    ]
  }
};
