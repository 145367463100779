import { Component } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetQuestions } from '../../core/state/tenant-questions/questions.actions';
import { QuestionsState } from '../../core/state/tenant-questions/questions.state';

@Component({
  selector: 'app-frat-get-questions-button',
  templateUrl: './frat-get-questions-button.component.html',
  styleUrls: ['./frat-get-questions-button.component.css']
})
export class FratGetQuestionsButtonComponent {
  syncing$: Observable<boolean>;

  constructor(
    private store: Store,
  ) {
    this.syncing$ = this.store.select(QuestionsState.syncing);
  }

  @Dispatch() syncQuestions = () => new GetQuestions();
}
