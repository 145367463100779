<mat-card>
  <mat-card-header>
    <mat-card-title>
      Recent Inspection Records
    </mat-card-title>
    <mat-card-subtitle>
      Item inspections that occured in the last 15 days.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let record of recentInspectionRecords$ | async">
        <h3 matLine> S/N: {{ record.ssn }} | {{ record.itemType }}</h3>
        <p matLine> {{ record.type }} {{ record.status }} by {{ record.user }} on {{ record.datePerformed | fsDate | date:'short' }} </p>
        <p matLine> Notes: {{ record.notes }} </p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
