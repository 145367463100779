<form [formGroup]="form.formGroup" [ngxsForm]="ngxsFormLink" autocomplete="off">
  <mat-toolbar class="user-toolbar">
    {{ form.formGroup.value.user.displayName }}
    <span fxFlex></span>
  </mat-toolbar>
  <app-frat-status-form [formControlName]="form.formControlNames.status"></app-frat-status-form>
  <form-unit-controls [formControlName]="form.formControlNames.unit"></form-unit-controls>
  <form-crewmember-array [formControlName]="form.formControlNames.crewMembers"></form-crewmember-array>
  <app-frat-sections-array [formControlName]="form.formControlNames.sections"></app-frat-sections-array>
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Mitigation notes</mat-label>
    <textarea matInput placeholder="Notes..." [formControlName]="form.formControlNames.mitigationNotes"></textarea>
  </mat-form-field>
  <frat-actions-bar [submitReady]="(form.formGroupErrors ? true : false) || form.formGroup.disabled"
    (manualSave)="manualSave$$.next()">
  </frat-actions-bar>
</form>

<!-- <mat-card appearance="outlined" class="errors" data-card-errors>
  <mat-card-title>Form errors</mat-card-title>

  <mat-card-content>
    <pre *ngIf="form.formGroupErrors as errors" data-errors>{{ errors | json }}</pre>

    <ng-template #noError>
      <span data-no-error>Form is valid, no error!</span>
    </ng-template>
  </mat-card-content>
</mat-card> -->
