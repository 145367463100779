import { Component } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { MaintanenceDetails } from 'src/app/log/models/log.model';

@Component({
  selector: 'maintanence-details-control',
  templateUrl: './maintanence-details.component.html',
  styleUrls: ['./maintanence-details.component.css'],
  providers: subformComponentProviders(MaintanenceDetailsControl)
})
export class MaintanenceDetailsControl {

  collectiveInHoursCantBeLessThanOutHours: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const [collectiveOut, collectiveIn] = [control.get('collectiveOut'), control.get('collectiveIn')];
    return collectiveIn && collectiveOut && collectiveOut?.value > collectiveIn?.value ? { collectiveInHoursLessThanOut: true } : null;
  }

  engineInHoursCantBeLessThanOutHours: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const [engineOut, engineIn] = [control.get('engineOut'), control.get('engineIn')]
    return engineIn && engineOut && engineOut?.value > engineIn?.value ? { engineInHoursLessThanOut: true } : null;
  };

  collectiveHoursCantBeMoreThanEngineHours: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const [engineHours, collectiveHours] = [control.get('engineHours'), control.get('collectiveHours')];
    return engineHours && collectiveHours && (engineHours?.value < collectiveHours?.value) ? { collectiveHoursMoreThanEngine: true } : null
  };


  public form = createForm<MaintanenceDetails>(this, {
    formType: FormType.SUB,
    formGroupOptions: {
      validators: [
        this.collectiveInHoursCantBeLessThanOutHours,
        this.engineInHoursCantBeLessThanOutHours,
        this.collectiveHoursCantBeMoreThanEngineHours,
      ]
    },
    formControls: {
      collectiveOut: new FormControl(null, Validators.required),
      collectiveIn: new FormControl(null, Validators.required),
      collectiveHours: new FormControl(null, Validators.required),
      engineOut: new FormControl(null, Validators.required),
      engineIn: new FormControl(null, Validators.required),
      engineHours: new FormControl(null, Validators.required),
    },
  })

  proccesFormUpdates() {
    const { collectiveOut, collectiveIn, engineOut, engineIn } = this.form.formGroup.value;
    if (collectiveOut && collectiveIn) {
      this.form.formGroup.controls.collectiveHours.patchValue(Number((collectiveIn - collectiveOut).toFixed(2)))
    }
    if (engineOut && engineIn) {
      this.form.formGroup.controls.engineHours.patchValue(Number((engineIn - engineOut).toFixed(2)))
    }
  }

}
