export interface Flight {
  status: FlightStatus
}

export interface FlightStatus {
  flight: FLIGHT_STATUS_OPTIONS
}

// eslint-disable-next-line no-shadow
export enum FLIGHT_STATUS_OPTIONS {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}
