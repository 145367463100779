import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Store, Actions, } from '@ngxs/store';

import { AuthService } from './auth/auth.service';

import { Layout } from './layout/state/layout.actions';
import { TenantActions } from './state/tenant/tenant.actions';
import { ConfigService } from './config/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private config: ConfigService,
    private store: Store,
  ) {
    // this.config.getConfig('honolulu-fd').subscribe(console.log)

    this.store.dispatch([
      new TenantActions.GetTenant(),
    ])
    // Initialize afAuth Module and listen for user signin status
    this.authService.init().pipe(
      takeUntil(this.destroy$)
    ).subscribe();

    // Initialize breakpoint and listen for handset changes.
    this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      takeUntil(this.destroy$),
      tap(result => this.store.dispatch(new Layout.IsHandset(result.matches)))
    ).subscribe();
  }

  ngOnInit(): void {
    // this.actions$.pipe(
    //   ofActionSuccessful(Auth.Signout),
    //   takeUntil(this.destroy$)
    // ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
