import { RouteTreeNode } from "./nav-tree.component";

export const allowPublic = ['*'];
export const onlyAdmin = ['admin'];
export const onlyEditor = ['editor','admin'];
export const onlyViewer = ['viewer', 'editor', 'admin'];

/** */
export const appRoutes: RouteTreeNode[] = [
  {
    name: 'Home',
    icon: 'home',
    route: ['home'],
    acl: allowPublic,
  },
  {
    name: 'ALSE',
    icon: 'construction',
    project: 'alse',
    acl: onlyViewer,
    children: [
      {
        name: 'Dashboard',
        icon: 'dashboard',
        route: ['alse'],
        acl: [],
      },
      {
        name: 'Items',
        icon: 'list',
        route: ['alse','items'],
        acl: [],
      }
    ]
  },
  {
    name: 'Currency',
    icon: '',
    project: 'currency',
    route: ['currency'],
    acl: onlyViewer,
  },
  {
    name: 'FRAT',
    icon: 'fact_check',
    project: 'frat',
    acl: onlyViewer,
    children: [
      {
        name: 'New',
        icon: 'add_box',
        route: ['frat'],
        acl: [],
      },
      {
        name: 'Submited',
        icon: 'list',
        route: ['frat', 'list'],
        acl: [],
      }
    ]
  },
  {
    name: 'LOG',
    icon: 'airplane_ticket',
    project: 'log',
    acl: onlyViewer,
    children: [
      // {
      //   name: 'New',
      //   icon: 'add_box',
      //   route: ['log'],
      //   acl: [],
      // },
      {
        name: 'Pending',
        icon: 'pending_actions',
        route: ['logs', 'pending'],
        acl: [],
      },
      {
        name: 'Completed',
        icon: 'verified',
        route: ['logs', 'complete'],
        acl: [],
      }
    ]
  },
  {
    name: 'User',
    icon: 'account_circle',
    route: ['user'],
    acl: allowPublic,
  },
  {
    name: 'Users',
    icon: 'people',
    project: 'users',
    acl: onlyAdmin,
    route: ['users']
  }
];
