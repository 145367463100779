import { OneAlseItem } from "./alse-base-models/item-base/alse-main-item";

// tslint:disable-next-line: no-namespace
export namespace DB {

  export enum Collection {
    AGGREGATION = 'aggregation',
    ATTACHMENTS = 'attachments',
    FRAT = 'frat',
    FRATS = 'frats',
    HISTORY = 'history',
    ITEMS = 'items',
    INSPECTIONS = 'inspections',
    METADATA = 'metadata',
    RESPONSES = 'responses',
    USERS = 'users',
    USERSMETADATA = 'usersMetadata',
  }

  export enum Documents {
    AGGREGATION = 'aggregation',
    SYSTEMSERIALNUMBERS = 'systemSerialNumbers',
    NEXTNUMBER = 'nextNumber',
    TOTALCOUNT = 'total_count',
  }

  export interface AlseMetadata {
    items: OneAlseItem[];
    aggregation: {
      items: {
        last_updated: Date,
        total_count: number,
      }
    };
    systemSerialNumbers: {
      last_updated: Date,
      nextNumber: number,
    };
  }

}
