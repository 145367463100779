import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { subformComponentProviders, createForm, FormType } from 'ngx-sub-form';
import { ManufacturerDetails } from 'global-shared/models/alse-base-models/item-base/manufacturer-details';

@Component({
  selector: 'app-item-manufactor-detail-form',
  templateUrl: './item-manufactor-detail-form.component.html',
  styleUrls: ['./item-manufactor-detail-form.component.css'],
  providers: [
    subformComponentProviders(ItemManufactorDetailFormComponent)
  ]
})
export class ItemManufactorDetailFormComponent {
  public form = createForm<ManufacturerDetails>(this, {
    formType: FormType.SUB,
    formControls: {
      serialNumber: new FormControl(null),
      manufacturerName: new FormControl(null),
      itemNumber: new FormControl(null),
      lotNumber: new FormControl(null),
      manufacturerDate: new FormControl(null),
      manufacturerEndOfLifeSpan: new FormControl(null),
      manufacturerEndOfLifeDate: new FormControl(null),
    }
  })
}
