import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Observable, of } from 'rxjs';
import { FlightDetails } from 'src/app/log/models/log.model';
import { TenantState } from 'src/app/state/tenant/tenant.state';


@Component({
  selector: 'flight-type-control',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.css'],
  providers: [subformComponentProviders(FlightDetailsControl)]
})
export class FlightDetailsControl {

  flightTypes$: Observable<string[]>;

  constructor(
    private store: Store,
  ) {
    this.flightTypes$ = this.store.select(TenantState.flightTypes)
  }

  public form = createForm<FlightDetails>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new FormControl(null, Validators.required),
      comments: new FormControl(null, Validators.required),
    }
  })

}
