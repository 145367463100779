import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { TenantState } from 'src/app/state/tenant/tenant.state';
import { STATUS } from 'global-shared/models/alse-base-models/item-base/alse-base';

@Component({
  selector: 'control-item-status',
  templateUrl: './item-status.component.html',
  styleUrls: ['./item-status.component.css'],
  providers: [
    subformComponentProviders(ItemStatusComponent),
  ]
})
export class ItemStatusComponent {

  Status = STATUS;
  locations$: Observable<string[]>;

  constructor(
    private store: Store,
  ) {
    this.locations$ = this.store.select(TenantState.locations)
  }

  public form = createForm<{location: string, status: STATUS}>(this, {
    formType: FormType.SUB,
    formControls: {
      location: new FormControl(null),
      status: new FormControl(null),
    }
  })

}
