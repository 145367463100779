import { Injectable } from "@angular/core";
import { Selector, State, StateToken } from "@ngxs/store";
import { AuthState } from "src/app/auth/state/auth.state";
import { allowPublic, appRoutes } from "../app-route-data";
import { RouteTreeNode } from "../nav-tree.component";

export interface NavTreeStateModel {
  loading: boolean;
  appRoutes: RouteTreeNode[];
}

export const NAVTREE_STATE_TOKEN = new StateToken<NavTreeStateModel>('navtree')

@State({
  name: NAVTREE_STATE_TOKEN,
  defaults: {
    loading: false,
    appRoutes
  }
})
@Injectable()
export class NavTreeState {
  @Selector()
  public static loading(state: NavTreeStateModel): boolean {
    return state.loading;
  }

  @Selector([NAVTREE_STATE_TOKEN, AuthState.userCurrentTenantClaims])
  public static appRoutes(state: NavTreeStateModel, userCurrentTenantClaims: any): any {
    // console.log({userCurrentTenantClaims});
   return userCurrentTenantClaims
   ? state.appRoutes.filter(node => node.acl === allowPublic || node.acl.includes(userCurrentTenantClaims[node.project]?.role))
   : state.appRoutes.filter(node => node.acl === allowPublic)
  }
}
