export const PROJECT_CONFIG = {
  hec133: {
    fratKeyId: '1wap12S6m8uwwwV9tVLjYmaZc3JpH9VwB6EpPpXRjles',
    version: 'v2',
  },
  sr3: {
    fratKeyId: '16GN0iWDhaWW2XWtm4jzN3BkXajVk0inBxaA4HphfbHM',
    version: 'v2',
  },
  aramco: {
    fratKeyId: '1wap12S6m8uwwwV9tVLjYmaZc3JpH9VwB6EpPpXRjles',
    version: 'v2',
  },
  gcpdau: {
    fratKeyId: '18LcPPrqv0CeRJFWYOycfNdMI6TrTO7FxGZqiOdgUP8Y',
    version: 'v2',
  },
  "honolulu-fd":{
    fratKeyId: '1GtLc7K-tO4ouHQKprjHEpq2YXNzfnWxJcAcaV-1UK3k',
    version: 'v2'
  }
}
