import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ItemInspections } from 'global-shared/models/alse-base-models/inspection-presets';
import { AlseCategoryNumber, AlseCategoryType, STATUS } from 'global-shared/models/alse-base-models/item-base/alse-base';
import { AlseCategory, OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';
import { ManufacturerDetails } from 'global-shared/models/alse-base-models/item-base/manufacturer-details';
import { AlseItemInspection } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';
import { Attachment } from 'global-shared/models/alse-base-models/item-records/alse-file-attachment';

import { OneSurvival } from 'global-shared/models/alse-base-models/item-types/survival-types';
import { OneHardware } from 'global-shared/models/alse-base-models/item-types/hardware-types';
import { OneHarness } from 'global-shared/models/alse-base-models/item-types/harness-types';
import { OneMisc } from 'global-shared/models/alse-base-models/item-types/misc-types';
import { OnePpe } from 'global-shared/models/alse-base-models/item-types/ppe-types';

import { createForm, FormType } from 'ngx-sub-form';
import { Subject } from 'rxjs';

interface OneAlseItemForm {
  hardwareItem: OneHardware | null;
  harnessItem: OneHarness | null;
  survivalItem: OneSurvival | null;
  miscItem: OneMisc | null;
  ppeItem: OnePpe | null;
  category: AlseCategory | null;
  id: string | null;
  ssn: string | null;
  tenant: string | null;
  location: string | null;
  status: STATUS | null;
  manufacturerDetails: ManufacturerDetails | null;
  startInspectionsDate: Date | null;
  inspections: AlseItemInspection[] | null;
  attachments: Attachment[] | null;
  updatedAt: Date | null;
  createdAt: Date | null;
}

@Component({
  selector: 'app-item-root-form',
  templateUrl: './item-root-form.component.html',
  styleUrls: ['./item-root-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemRootFormComponent {
  public AlseCategoryType: typeof AlseCategoryType = AlseCategoryType;
  public Status: typeof STATUS = STATUS;
  public ItemInspections = ItemInspections;
  @Input() claims: { roles: string[] };

  private input$: Subject<OneAlseItem | undefined> = new Subject();
  @Input() set item(item: OneAlseItem | undefined) {
    this.input$.next(item);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() formUpdated: Subject<OneAlseItem> = new Subject();

  public manualSave$$: Subject<void> = new Subject();

  @Output('handleDelete')
  public _handleDelete: EventEmitter<boolean> = new EventEmitter();

  handleDelete(): void {
    return this._handleDelete.next(true);
  }

  // get link(): string {
  //   const categoryNumber = this.form.formGroup.value.category.categoryNumber;
  //   const itemNumber = this.formGroupValues[`${this.formGroupValues.category?.categoryType?.toLowerCase()}Item`]?.itemNumber;
  //   return `/assets/alse-cards/${categoryNumber}-${itemNumber}.pdf`;
  // }

  public form = createForm<OneAlseItem, OneAlseItemForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.formUpdated,
    manualSave$: this.manualSave$$,
    formControls: {
      hardwareItem: new FormControl(null),
      harnessItem: new FormControl(null),
      survivalItem: new FormControl(null),
      miscItem: new FormControl(null),
      ppeItem: new FormControl(null),
      category: new FormControl(null),
      id: new FormControl(null),
      ssn: new FormControl(null),
      tenant: new FormControl(null),
      location: new FormControl(null),
      status: new FormControl(null),
      manufacturerDetails: new FormControl(null),
      startInspectionsDate: new FormControl(null),
      inspections: new FormControl(null),
      attachments: new FormControl(null),
      updatedAt: new FormControl(null),
      createdAt: new FormControl(null),
    },
    toFormGroup: (obj: OneAlseItem): OneAlseItemForm => {
      if (!obj) {
        return null;
      }

      const { category, item, ...commonValues } = obj;

      return {
        hardwareItem: category?.categoryType === AlseCategoryType.HARDWARE ? item as OneHardware : null,
        harnessItem: category?.categoryType === AlseCategoryType.HARNESS ? item as OneHarness : null,
        survivalItem: category?.categoryType === AlseCategoryType.SURVIVAL ? item as OneSurvival : null,
        miscItem: category?.categoryType === AlseCategoryType.MISC ? item as OneMisc : null,
        ppeItem: category?.categoryType === AlseCategoryType.PPE ? item as OnePpe : null,
        category,
        ...commonValues,
      };
    },
    fromFormGroup: (formValue: OneAlseItemForm): OneAlseItem => {
      const { hardwareItem,
        harnessItem,
        survivalItem,
        miscItem,
        ppeItem,
        category, ...commonValues } = formValue;

      switch (category.categoryType) {
        case AlseCategoryType.HARDWARE:
          return hardwareItem ? {
            item: hardwareItem, category: {
              categoryType: AlseCategoryType.HARDWARE,
              categoryNumber: AlseCategoryNumber.HARDWARE,
            }, ...commonValues
          } : null;
        case AlseCategoryType.HARNESS:
          return harnessItem ? {
            item: harnessItem, category: {
              categoryType: AlseCategoryType.HARNESS,
              categoryNumber: AlseCategoryNumber.HARNESS,
            }, ...commonValues
          } : null;
        case AlseCategoryType.SURVIVAL:
          return survivalItem ? {
            item: survivalItem, category: {
              categoryType: AlseCategoryType.SURVIVAL,
              categoryNumber: AlseCategoryNumber.SURVIVAL,
            }, ...commonValues
          } : null;
        case AlseCategoryType.MISC:
          return miscItem ? {
            item: miscItem, category: {
              categoryType: AlseCategoryType.MISC,
              categoryNumber: AlseCategoryNumber.MISC,
            }, ...commonValues
          } : null;
        case AlseCategoryType.PPE:
          return ppeItem ? {
            item: ppeItem, category: {
              categoryType: AlseCategoryType.PPE,
              categoryNumber: AlseCategoryNumber.PPE,
            }, ...commonValues
          } : null;
        case null:
          return null;
      }
    },
  })


}
