import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { AppUser } from 'global-shared/models/user-account.model';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { AuthService } from '../auth.service';
import { AuthActions } from './auth.actions';
import { SetTenant } from 'global-shared/states/preferences/preferences.actions';

export interface AuthStateModel {
  loading: boolean;
  user: AppUser;
  claims: any;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State({
  name: AUTH_STATE_TOKEN,
  defaults: {
    loading: true,
    user: null,
    claims: null,
  }
})
@Injectable()
export class AuthState {
  @Selector()
  public static loading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static claims(state: AuthStateModel): any {
    return state.claims;
  }

  @Selector()
  public static allowedTenants(state: AuthStateModel): any {
    return state.claims?.tenants;
  }

  @Selector()
  public static user(state: AuthStateModel): AppUser {
    return state.user;
  }

  @Selector([AUTH_STATE_TOKEN, PreferencesState.tenant])
  public static userCurrentTenantClaims(state: AuthStateModel, tenant: string) {
    return state.claims?.tenants ? state.claims?.tenants[tenant] : {};
  }

  constructor(
    private authService: AuthService,
  ) { }

  @Action(AuthActions.Signout)
  async signout({ dispatch, setState }: StateContext<AuthStateModel>): Promise<void> {
    return await this.authService.signout()
      .then(() => {
        setState({
          loading: false,
          user: null,
          claims: null,
        });
        dispatch(new SetTenant(null))
      });
  }

  @Action(AuthActions.Claims)
  setClaims({ patchState }: StateContext<AuthStateModel>, { payload }: AuthActions.Claims): void {
    patchState({
      loading: false,
      claims: payload
    });
  }

  @Action(AuthActions.Loading)
  setLoading({ patchState }: StateContext<AuthStateModel>, { payload }: AuthActions.Loading): void {
    patchState({ loading: payload });
  }

  // @Action(AuthActions.GetUser)
  // getUser({ patchState }: StateContext<AuthStateModel>, action: AuthActions.GetUser): Observable<User> {
  //   return user();
  // }

  @Action(AuthActions.User)
  setUser({ patchState }: StateContext<AuthStateModel>, { payload }: AuthActions.User): void {
    patchState({
      loading: false,
      user: payload
    });
  }

}
