<mat-toolbar class="frat-mainheader">
  <button mat-raised-button color="primary"
   matTooltip="Clear dynamic questions"
   (click)="clearQuestions('dynamic')">New</button>

  <span fxFlex></span>

  <button mat-raised-button color="warn"
   matTooltip=""
   (click)="clearQuestions('new')">Clear</button>

  <button mat-icon-button (click)="syncQuestions()" matTooltip="Click here to sync questions from agency spreadsheet">
    <mat-icon [ngClass]="{'icn-spinner': syncing$ | async}">cached</mat-icon>
  </button>
</mat-toolbar>
