import { Routes } from '@angular/router';

import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { appViewerOnly, hasDisplayName, onlyAllowSelf, tenantAdminOnly, tenantViewerOnly } from 'global-shared/guard-helpers/guard-helpers.module';

import { ALSE_ROUTES } from 'projects/alse/src/app/alse.routes';
import { FRAT_ROUTES } from 'projects/frat/src/app/frat.routes';
import { CURRENCY_ROUTES } from 'projects/currency/src/app/currency.routes';

import { AboutComponent } from './views/about/about.component';
import { HomeComponent } from './views/home/home.component';

import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { TenantNotFoundComponent } from './views/tenant-not-found/tenant-not-found.component';

import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { UsersTableComponent } from './views/users-table/users-table.component';
import { SettingsComponent } from './components/settings/settings.component';

export const APP_ROUTES: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'user/:uid',
    component: UserProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: (next) => onlyAllowSelf(next) },
  },
  {
    path: ':tenantId',
    canActivate: [hasDisplayName, AngularFireAuthGuard],
    data: { authGuardPipe: (next) => tenantViewerOnly(next) },
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'alse',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: (next) => appViewerOnly(next, 'alse') },
        children: ALSE_ROUTES,
      },
      {
        path: 'currency',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: (next) => appViewerOnly(next, 'currency') },
        children: CURRENCY_ROUTES,
      },
      {
        path: 'frat',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: (next) => appViewerOnly(next, 'frat') },
        children: FRAT_ROUTES,
      },
      {
        path: 'logs',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: (next) => appViewerOnly(next, 'log') },
        loadChildren: () => import('./log/log.module').then(m => m.LogModule),
      },
      {
        path: 'map',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule),
      },
      {
        path: 'users',
        component: UsersTableComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: (next) => tenantAdminOnly(next) },
      },
      {
        path: '',
        redirectTo: ':tenantId/home',
        pathMatch: 'full'
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      }
    ]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: TenantNotFoundComponent,
  }
];
