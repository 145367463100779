<fieldset [formGroup]="form.formGroup" fxLayout="row">
  <legend>Category - {{ AlseCategoryNumber[form.formGroup.value.categoryType?.toUpperCase()] }}</legend>

  <mat-form-field>
    <mat-select placeholder="Select category type" [formControlName]="form.formControlNames.categoryType">

      <mat-option *ngFor="let categoryType of AlseCategoryType | keyvalue" [value]="categoryType.value">
        {{ categoryType.value }}
      </mat-option>

    </mat-select>
  </mat-form-field>

</fieldset>
