import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FratStatus } from 'global-shared/models/frat.model';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';


@Component({
  selector: 'app-frat-status-form',
  templateUrl: './frat-status-form.component.html',
  styleUrls: ['./frat-status-form.component.css'],
  providers: subformComponentProviders(FratStatusFormComponent),
})
export class FratStatusFormComponent {
  public form = createForm<FratStatus>(this, {
    formType: FormType.SUB,
    formControls: {
      approved: new FormControl(null),
      emailSent: new FormControl(null),
      releaseNumber: new FormControl(null),
      flight: new FormControl(null),
    }
  })
}
