import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FratSectionV2 } from 'global-shared/models/question.model';

@Component({
  selector: 'app-frat-section-control',
  templateUrl: './frat-section-control.component.html',
  styleUrls: ['./frat-section-control.component.css'],
  providers: subformComponentProviders(FratSectionControlComponent)
})
export class FratSectionControlComponent {
  public form = createForm<FratSectionV2>(this, {
    formType: FormType.SUB,
    formControls: {
      label: new FormControl(null, [Validators.required]),
      questions: new FormControl(null, [Validators.required]),
    }
  })
}
