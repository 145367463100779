import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HELMET, NVG, PpeType, OnePpe } from 'global-shared/models/alse-base-models/item-types/ppe-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

interface OnePpeForm {
  helmet: HELMET | null;
  nvg: NVG | null;
  itemType: PpeType | null;
}

@Component({
  selector: 'app-ppe-item-form',
  templateUrl: './ppe-item-form.component.html',
  styleUrls: ['./ppe-item-form.component.css'],
  providers: subformComponentProviders(PpeItemFormComponent),
})
export class PpeItemFormComponent {

  public PpeType = PpeType;

  public form = createForm<OnePpe, OnePpeForm>(this, {
    formType: FormType.SUB,
    formControls: {
      helmet: new FormControl(null),
      nvg: new FormControl(null),
      itemType: new FormControl(null, { validators: [Validators.required] }),
    },
    toFormGroup: (obj: OnePpe): OnePpeForm => {
      if (!obj) {
        return null;
      }

      return {
        helmet: obj.itemType === PpeType.HELMET ? obj : null,
        nvg: obj.itemType === PpeType.NVG ? obj : null,
        itemType: obj.itemType,
      };
    },
    fromFormGroup: (formValue: OnePpeForm): OnePpe => {
      switch (formValue.itemType) {
        case PpeType.HELMET:
          return formValue.helmet;
        case PpeType.NVG:
          return formValue.nvg;
        case null:
          return null;
      }
    }
  })
}
