import { inject } from '@angular/core';
import { of, pipe } from 'rxjs';
import { map, skipUntil, skipWhile, switchMap, tap } from 'rxjs/operators';

import { customClaims } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';
import { APPS } from 'global-shared/models/apps/app.model';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/state/auth.state';

// User only guards
export const onlyAllowSelf = (next: ActivatedRouteSnapshot) => {
  return pipe(
    customClaims,
    map(claims => claims.user_id === next.params.uid ? true : ['signin']),
  );
};

export const hasDisplayName: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  return store.select(AuthState.user).pipe(
    skipWhile(user => !user),
    switchMap(user => user?.displayName ? of(true) : of(createUrlTreeFromSnapshot(route, ['/user', user?.uid]))),
  )
}

// Tenant specific guards
export const tenantViewerOnly = (next: ActivatedRouteSnapshot) => {
  const tenant = next.params?.tenantId;
  return pipe(
    customClaims,
    map(claims => claims?.tenants[tenant]?.role
      ? hasAny(claims?.tenants[tenant]?.role, [`viewer`, 'editor', `admin`]) : false),
    map(verdict => verdict ? true : ['signin']),
  );
};

export const tenantAdminOnly = (next: ActivatedRouteSnapshot) => {
  const tenant = next.params?.tenantId;
  return pipe(
    customClaims,
    map(claims => claims?.tenants[tenant]?.role
      ? hasAny(claims?.tenants[tenant]?.role, ['admin']) : false),
    map(verdict => verdict ? true : [tenant, 'home']),
  );
};

// App specific guards
export const appViewerOnly = (next: ActivatedRouteSnapshot, app: APPS) => {
  const tenant = next.params?.tenantId;
  return pipe(
    customClaims,
    map(claims => claims?.tenants[tenant][app]?.role
      ? hasAny(claims?.tenants[tenant][app]?.role, [`viewer`, `editor`, 'admin']) : false),
    map(verdict => verdict ? true : ['signin']),
  );
};

export const appEditorOnly = (next: ActivatedRouteSnapshot, app: APPS) => {
  const tenant = next.params?.tenantId;
  return pipe(
    customClaims,
    map(claims => claims?.tenants[tenant][app]?.role
      ? hasAny(claims?.tenants[tenant][app]?.role, [`editor`, 'admin']) : false),
    map(verdict => verdict ? true : [tenant, app]),
  );
};

export const appAdminOnly = (next: ActivatedRouteSnapshot, app: APPS) => {
  const tenant = next.params?.tenantId;
  return pipe(
    customClaims,
    map(claims => claims?.tenants[tenant][app]?.role
      ? hasAny(claims?.tenants[tenant][app]?.role, ['admin']) : false),
    map(verdict => verdict ? true : [tenant, app]),
  );
};

export function hasAny(role: string, permissions: string[]): boolean {
  const verdict = permissions?.includes(role);
  return verdict;
}
