<mat-form-field [formGroup]="form.formGroup">
  <mat-select
    data-select-harness-size
    placeholder="Select harness size"
    [formControlName]="form.formControlNames.size"
  >
    <mat-option
      data-select-harness-size-option
      *ngFor="let harnessSize of HarnessSize | keyvalue"
      [value]="harnessSize.value"
    >
      {{ harnessSize.value }}
    </mat-option>
  </mat-select>
</mat-form-field>
