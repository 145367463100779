<form [formGroup]="form.formGroup" fxLayout="column" autocomplete="off">
  <mat-card>
    <mat-card-title>
      User profile
    </mat-card-title>

    <mat-card-content>
    <mat-form-field>
      <label>User name</label>
      <input matInput type="text" [formControlName]="form.formControlNames.displayName" />
    </mat-form-field>
  </mat-card-content>

  <mat-card-actions>
    <button mat-stroked-button (click)="manualSave$$.next()">Submit</button>
  </mat-card-actions>

  </mat-card>
</form>
