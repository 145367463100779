<fieldset [formGroup]="form.formGroup" class="container">
  <legend>Survival Item form</legend>

  <mat-form-field>
    <mat-select data-select-survival-type placeholder="Select survial type"
      [formControlName]="form.formControlNames.itemType">
      <mat-option data-select-survival-type-option *ngFor="let type of SurvivalType | keyvalue" [value]="type.value">
        {{ type.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div [ngSwitch]="form.formGroup.value?.itemType">

    <alse-laser-item *ngSwitchCase="SurvivalType.LASER" [formControlName]="form.formControlNames.laser"></alse-laser-item>

  </div>

</fieldset>
