import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from 'global-shared/services/users/user.service';

import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/state/auth.state';

import { AppUser } from 'global-shared/models/user-account.model';
import { AuthActions } from 'src/app/auth/state/auth.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {

  user$: Observable<AppUser>;
  claims$: Observable<any>;

  @Input() user: AppUser;

  constructor(
    private snackBar: MatSnackBar,
    private store: Store,
    private userService: UserService
  ) {
    this.user$ = this.store.select(AuthState.user);
    this.claims$ = this.store.select(AuthState.claims);
  }

  public signout(): void {
    this.store.dispatch(new AuthActions.Signout());
  }

  public userUpdated(user: AppUser): void {
    this.userService.upsertUser(user.uid, user)
    .then(() => {
      this.snackBar.open('User updated successfuly');
    })
    .catch(err => {
      this.snackBar.open(`Error: ${err}`)
    })
  }

}
