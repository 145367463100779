import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';

import { AlseHomeComponent } from './alse-home/alse-home.component';

import { DeleteButtonComponent } from 'global-shared/components/delete-button/delete-button.component';
import { FsDatePipe } from 'global-shared/pipes/fs-date/fs-date.pipe';

import { ItemContainerComponent } from './item-container/item-container.component';
import { ItemRootFormComponent } from './item-container/item-root-form/item-root-form.component';
import { ItemManufactorDetailFormComponent } from './item-container/item-root-form/item-manufactor-detail-form/item-manufactor-detail-form.component';
import { ItemHistoryComponent } from './item-container/item-root-form/item-history/item-history.component';
import { ItemInspectionComponent } from './item-container/item-root-form/item-inspection/item-inspection.component';
import { ItemsContainerComponent } from './items-container/items-container.component';
import { ItemsTableComponent } from './items-container/items-table/items-table.component';
import { HardwareItemFormComponent } from './item-container/item-root-form/item-type-forms/hardware-item-form/hardware-item-form.component';
import { HarnessItemFormComponent } from './item-container/item-root-form/item-type-forms/harness-item-form/harness-item-form.component';
import { ItemsDueContainerComponent } from './items-due-container/items-due-container.component';
import { ItemPerformInspectionComponent } from './item-perform-inspection/item-perform-inspection.component';
import { PeformInspectionFormComponent } from './item-perform-inspection/peform-inspection-form/peform-inspection-form.component';
import { RecentInspectionRecordsComponent } from './views/recent-inspection-records/recent-inspection-records.component';
import { MiscItemFormComponent } from './item-container/item-root-form/item-type-forms/misc-item-form/misc-item-form.component';
import { TestItemComponent } from './item-container/item-root-form/item-type-forms/misc-item-form/test-item/test-item.component';
import { ItemCategoryComponent } from './item-container/item-root-form/item-category/item-category.component';
import { CarabinerItemComponent } from './item-container/item-root-form/item-type-forms/hardware-item-form/carabiner-item/carabiner-item.component';
import { DeltaLinkItemComponent } from './item-container/item-root-form/item-type-forms/hardware-item-form/delta-link-item/delta-link-item.component';
import { MapRingItemComponent } from './item-container/item-root-form/item-type-forms/hardware-item-form/map-ring-item/map-ring-item.component';
import { ChameleonItemComponent } from './item-container/item-root-form/item-type-forms/harness-item-form/chameleon-item/chameleon-item.component';
import { TritonItemComponent } from './item-container/item-root-form/item-type-forms/harness-item-form/triton-item/triton-item.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { FileUploadDirective } from './directives/file-upload.directive';
import { DropzoneDirective } from './directives/dropzone.directive';
import { UploaderComponent } from './components/uploader/uploader.component';
import { LocationChipsFormControlComponent } from './components/location-chips-form-control/location-chips-form-control.component';
import { ItemAttachmentsComponent } from './views/item-attachments/item-attachments.component';
import { MaterialDesignModule } from 'global-shared/modules/material-design.module';
import { PpeItemFormComponent } from './item-container/item-root-form/item-type-forms/ppe-item-form/ppe-item-form.component';
import { NvgItemComponent } from './item-container/item-root-form/item-type-forms/ppe-item-form/nvg-item/nvg-item.component';
import { RouterModule } from '@angular/router';
import { ALSE_ROUTES } from '../alse.routes';
import { HelmetItemComponent } from './item-container/item-root-form/item-type-forms/ppe-item-form/helmet-item/helmet-item.component';
import { SurvivalItemFormComponent } from './item-container/item-root-form/item-type-forms/survival-item-form/survival-item-form.component';
import { LaserItemComponent } from './item-container/item-root-form/item-type-forms/survival-item-form/laser-item/laser-item.component';
import { ItemStatusComponent } from './item-container/item-root-form/item-status/item-status.component';

const providers = [];

@NgModule({
    declarations: [
        FsDatePipe,
        DeleteButtonComponent,
        AlseHomeComponent,
        ItemContainerComponent,
        ItemRootFormComponent,
        ItemManufactorDetailFormComponent,
        ItemHistoryComponent,
        ItemInspectionComponent,
        ItemsContainerComponent,
        ItemsTableComponent,
        HardwareItemFormComponent,
        HarnessItemFormComponent,
        ItemsDueContainerComponent,
        ItemPerformInspectionComponent,
        PeformInspectionFormComponent,
        RecentInspectionRecordsComponent,
        MiscItemFormComponent,
        TestItemComponent,
        ItemCategoryComponent,
        NvgItemComponent,
        CarabinerItemComponent,
        DeltaLinkItemComponent,
        MapRingItemComponent,
        ChameleonItemComponent,
        TritonItemComponent,
        UploadFileComponent,
        FileUploadDirective,
        DropzoneDirective,
        UploaderComponent,
        LocationChipsFormControlComponent,
        ItemAttachmentsComponent,
        PpeItemFormComponent,
        HelmetItemComponent,
        SurvivalItemFormComponent,
        LaserItemComponent,
        ItemStatusComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MaterialDesignModule,
        RouterModule.forRoot(ALSE_ROUTES),
    ],
    bootstrap: [
        AlseHomeComponent
    ]
})
export class AlseModule { }

@NgModule({})
export class AlseSharedModule {
  static forRoot(): ModuleWithProviders<AlseModule> {
    return {
      ngModule: AlseModule,
      providers,
    };
  }
}
