<fieldset [formGroup]="form.formGroup"
[class.invalid]="form.formGroupErrors">
  <legend>Unit info</legend>

  <div fxLayout="row" fxLayout.lt-md="column" fxFlex>

    <mat-form-field appearance="fill">
      <mat-label>Call sign</mat-label>
      <mat-select [formControlName]="form.formControlNames.callSign">
        <mat-option *ngFor="let callSign of (callSigns$ | async)" [value]="callSign">
          {{ callSign }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Tailnumber</mat-label>
      <mat-select [formControlName]="form.formControlNames.tailNumber">
        <mat-option *ngFor="let tailNumber of (tailNumbers$ |  async)" [value]="tailNumber">
          {{ tailNumber }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</fieldset>
