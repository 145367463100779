import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Store } from '@ngxs/store';
import { MetarsState } from 'global-shared/states/metars/metars.state';
import { Observable } from 'rxjs';
import { LayoutState } from 'src/app/layout/state/layout.state';
import { MetarsBottomSheetComponent } from '../../views/metars-bottom-sheet/metars-bottom-sheet.component';
@Component({
  selector: 'app-frat-bottom-toolbar',
  templateUrl: './frat-bottom-toolbar.component.html',
  styleUrls: ['./frat-bottom-toolbar.component.css']
})
export class FratBottomToolbarComponent implements OnInit {

  isHandset$: Observable<boolean>;
  metarsLoading$: Observable<boolean>;

  constructor(
    private bottomSheet: MatBottomSheet,
    private store: Store,
  ) {
    this.isHandset$ = this.store.select(LayoutState.isHandset);
    this.metarsLoading$ = this.store.select(MetarsState.metarsLoading);
  }

  ngOnInit(): void {
  }

  openMetarSheet(): void {
    this.bottomSheet.open(MetarsBottomSheetComponent);
  }

}
