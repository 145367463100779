import { Component, OnInit, Optional } from '@angular/core';
import { getAllChanges, RemoteConfig } from '@angular/fire/remote-config';
import { Store } from '@ngxs/store';
import { EMPTY, Observable, tap } from 'rxjs';
import { AuthActions } from 'src/app/auth/state/auth.actions';
import { TenantActions } from 'src/app/state/tenant/tenant.actions';
import { TenantState } from 'src/app/state/tenant/tenant.state';
import { UsersActions } from 'src/app/state/users/users.actions';
import { UsersState } from 'src/app/state/users/users.state';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  tenantState$: Observable<TenantState>;
  tenantUnits$: Observable<string[]>;
  tenantUsers$: Observable<any>;

  usersState$: Observable<UsersState>;

  readonly change$: Observable<any>;

  constructor(
    @Optional() remoteConfig: RemoteConfig,
    private store: Store,
  ) {
    this.tenantState$ = this.store.select(TenantState);
    this.tenantUnits$ = this.store.select(TenantState.callSigns);
    this.tenantUsers$ = this.store.select(TenantState.users);

    this.usersState$ = this.store.select(UsersState);

    /** Remote config */
    if (remoteConfig) {
      this.change$ = getAllChanges(remoteConfig).pipe(
        // traceUntilFirst('remote-config'),
        tap(it => console.log('REMOTE CONFIG', it)),
      );
    } else {
      this.change$ = EMPTY;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new TenantActions.GetTenant);
    this.store.dispatch(new UsersActions.GetAllByTenant());
  }

  resetApp(): void {
    this.store.dispatch(new AuthActions.Signout())
      .subscribe(_ => this.store.reset({}))
      .unsubscribe();
    location.reload();
  }

}
