import { Component } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AuthActions } from '../state/auth.actions';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent {
  @Dispatch()
  public signout = () => new AuthActions.Signout()

  constructor() {
    this.signout();
  }
}
