import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

import { Store } from '@ngxs/store';

import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';
import { DB } from 'global-shared/models/db.model';
import { Attachment } from 'global-shared/models/alse-base-models/item-records/alse-file-attachment';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent {
  @Input() record: InspectionRecord;
  @Output() attachment: EventEmitter<Attachment> = new EventEmitter(null);

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(
    // private appContext: AppContextService,
    private storage: AngularFireStorage,
    private store: Store,
  ) { }

  uploadFile(event): void {
    const file = event.target.files[0];

    const tenant = this.store.selectSnapshot(state => state.preferences.tenant);
    // The storage path
    const path = `tenants/${tenant}/alse/${DB.Collection.ITEMS}/${this.record.itemId}/${DB.Collection.INSPECTIONS}/${this.record.id}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      // tap(console.log),
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        const result = { downloadURL: this.downloadURL, path };

        this.attachment.emit(result);
      }),
    );
  }

  isActive(snapshot): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
