import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Frat } from 'global-shared/models/frat.model';
import { createForm, FormType } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { FRAT_FORM_PATH } from '../../core/state/frat/frat.state';

@Component({
  selector: 'app-frat-root-form',
  templateUrl: './frat-root-form.component.html',
  styleUrls: ['./frat-root-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FratRootFormComponent {
  ngxsFormLink = FRAT_FORM_PATH

  public input$: Subject<Frat | undefined> = new Subject();
  @Input() set frat(frat: Frat | undefined) {
    this.input$.next(frat);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!value ? false : value);
  }

  @Output() fratUpdate: Subject<Frat> = new Subject();

  public manualSave$$: Subject<void> = new Subject();

  public form = createForm<Frat>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.fratUpdate,
    // Cant actually use input$ cause form is bound to ngxsForm
    // or manualSave$ does not work
    manualSave$: this.manualSave$$,
    formControls: {
      id: new FormControl(null),
      user: new FormControl(null),
      crewMembers: new FormControl(null, Validators.required),
      scores: new FormControl(null),
      status: new FormControl(null),
      timestamps: new FormControl(null),
      unit: new FormControl(null, Validators.required),
      sections: new FormControl(null, Validators.required),
      mitigationNotes: new FormControl(null, Validators.required),
    }
  });
}
