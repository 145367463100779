import { Component, Input } from '@angular/core';
import { MetarsGeoJSON } from 'global-shared/models/metars/metars.model';

@Component({
  selector: 'app-nearest-points-list',
  templateUrl: './nearest-points-list.component.html',
  styleUrls: ['./nearest-points-list.component.css']
})
export class NearestPointsListComponent {
  @Input() metars: MetarsGeoJSON;

  constructor() {}
}
