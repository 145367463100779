<fieldset [formGroup]="form.formGroup" class="container">
  <legend>PPE Item form</legend>

  <mat-form-field>
    <mat-select
      data-select-misc-type
      placeholder="Select misc type"
      [formControlName]="form.formControlNames.itemType"
    >
      <mat-option
        data-select-misc-type-option
        *ngFor="let type of PpeType | keyvalue"
        [value]="type.value"
      >
        {{ type.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div [ngSwitch]="form.formGroup.value?.itemType">

    <alse-helmet-item *ngSwitchCase="PpeType.HELMET"
    [formControlName]="form.formControlNames.helmet"></alse-helmet-item>

    <app-nvg-item *ngSwitchCase="PpeType.NVG"
    [formControlName]="form.formControlNames.nvg"></app-nvg-item>

  </div>

</fieldset>
