import { Injectable } from '@angular/core';

import { State, Action, StateContext, StateToken, Selector, Store } from '@ngxs/store';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { produce } from 'immer';

import { FratActions } from './frat.actions';

import { FratSectionV2 } from 'global-shared/models/question.model';
import { Frat, FratValues } from 'global-shared/models/frat.model';
import { MetarsGeoJSON } from 'global-shared/models/metars/metars.model';
import { QuestionsState } from '../tenant-questions/questions.state';

export interface FratStateModel {
  loading: boolean;
  position: GeolocationPosition;
  metars: MetarsGeoJSON;
  fratForm?: FratStateForm
}

export interface FratStateForm {
  model?: Frat;
  // dirty: boolean;
  status: string;
  // errors: {}
}

const STATE_NAME = 'fratState';

export const FRAT_FORM_PATH = `${STATE_NAME}.fratForm`;

export const FRAT_STATE_TOKEN = new StateToken<FratStateModel>(STATE_NAME);

@State({
  name: FRAT_STATE_TOKEN,
  defaults: {
    loading: false,
    position: null,
    metars: null,
  }
})
@Injectable()
export class FratState {
  @Selector([FRAT_STATE_TOKEN])
  static loading(state: FratStateModel): boolean {
    return state.loading;
  }

  @Selector([FRAT_STATE_TOKEN])
  static frat(state: FratStateModel): Frat {
    return state.fratForm.model;
  }

  @Selector([FRAT_STATE_TOKEN])
  static postion(state: FratStateModel) {
    const coords = state.position.coords;
    return [coords.longitude, coords.latitude];
  }

  @Selector([FRAT_STATE_TOKEN, QuestionsState.totalPossibleValue])
  static scores(state: FratStateModel, totalPossibleValue: number): FratValues {
    let [staticValue, dynamicValue, totalScore] = [0, 0, 0];
    if (totalPossibleValue <= 0) {
      return { staticValue, dynamicValue, totalScore, totalPossibleValue }
    }
    state.fratForm.model?.sections.map(section => section?.questions
      .map(quesiton => quesiton.selected && quesiton.type === 'Static'
        ? staticValue = staticValue + (quesiton.selected.length ? quesiton.selected[0]?.value : 0)
        : dynamicValue = dynamicValue + (quesiton.selected.length ? quesiton.selected[0]?.value : 0)
      ));
    totalScore = staticValue + dynamicValue;
    return { staticValue, dynamicValue, totalScore, totalPossibleValue };
  }

  @Selector([FRAT_STATE_TOKEN, QuestionsState.totalPossibleValue])
  static riskPercentage(state: FratStateModel, totalPossibleValue: number) {
    const selectedRisks = [];
    state.fratForm.model?.sections
      .map(section => section?.questions
        .map(quesiton => quesiton?.selected ? selectedRisks.push(quesiton?.selected) : selectedRisks.push([])));
    const selectedRisksValue = selectedRisks.reduce((pv, cv, i) => pv + (cv[0] ? cv[0].value : 0), 0)
    return selectedRisksValue * 100 / totalPossibleValue;
  }

  constructor(
    private store: Store,
  ) { }


  @Action(FratActions.New)
  newFrat(ctx: StateContext<FratStateModel>, { action }: FratActions.New): void {
    const state = produce(ctx.getState(), draft => {
      draft.fratForm.model.id = null;
      draft.fratForm.model.status.flight = null;
      draft.fratForm.model.mitigationNotes = null;
      draft.fratForm.model.sections = action === 'dynamic'
        ? this.clearDynamicQuestions(draft.fratForm.model.sections)
        : this.clearAllQuestions(draft.fratForm.model.sections);
    });
    ctx.setState(state);
  }

  @Action(FratActions.SetMetars)
  setMetars(ctx: StateContext<FratStateModel>, { payload }: FratActions.SetMetars): void {
    // const metars = this.store.selectSnapshot(state => state.metars.metars);
    const metars = payload
    ctx.patchState({
      metars,
    });
  }

  @Action(FratActions.SetPosition)
  setPosition(ctx: StateContext<FratStateModel>, { payload }: FratActions.SetPosition): void {
    const position = this.store.selectSnapshot(state => state.position);
    ctx.patchState({
      position,
    });
  }

  @Action(FratActions.SetStatus)
  setStatus({ dispatch }, { payload }: FratActions.SetStatus): void {
    dispatch(new UpdateFormValue({
      path: FRAT_FORM_PATH,
      value: {
        flight: payload
      },
      propertyPath: 'status'
    }))
  }

  /** Helpers */
  clearDynamicQuestions(sections: FratSectionV2[]): FratSectionV2[] {
    return sections.map(section => {
      section.questions.map(question => {
        if (question.type.toLowerCase() === 'dynamic') {
          question.selected = [];
        }
        return question;
      })
      return section;
    })
  }

  clearAllQuestions(sections: FratSectionV2[]): FratSectionV2[] {
    return sections.map(section => {
      section.questions.map(question => {
        question.selected = [];
        return question;
      })
      return section;
    })
  }
}
