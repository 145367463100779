import { Routes } from '@angular/router';
import { AlseHomeComponent } from './alse/alse-home/alse-home.component';
import { ItemContainerComponent } from './alse/item-container/item-container.component';
import { ItemsTableComponent } from './alse/items-container/items-table/items-table.component';

export const ALSE_ROUTES: Routes = [
  {
    path: '',
    component: AlseHomeComponent,
  },
  {
    path: 'items/:id/edit',
    component: ItemContainerComponent,
  },
  {
    path: 'items/:id',
    component: ItemContainerComponent,
  },
  {
    path: 'items',
    component: ItemsTableComponent,
  },
];
