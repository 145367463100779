<mat-card>
  <mat-card-header>
    <mat-card-title>
      Item Inspection Records
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let record of records$ | async">
        <h3 matLine> S/N: {{ record.ssn }} | {{ record.itemType }}
          <a
          *ngIf="record?.attachment?.downloadURL"
          [href]="record?.attachment?.downloadURL"
          target="_blank">
            <mat-icon >attachment</mat-icon>
          </a>
        </h3>
        <p matLine> {{ record.type }} completed by {{ record.user }} on {{ record.datePerformed | fsDate | date:'short' }} </p>
        <p matLine> Notes: {{ record.notes }} </p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
