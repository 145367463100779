<!-- Hide add crew member module if in readonly mode -->
<fieldset *ngIf="!form.formGroup.disabled">
  <legend>
    Add crew member
  </legend>

  <div fxFlex fxLayout="row wrap" fxLayoutAlign="center">
    <button *ngFor="let role of roles" mat-stroked-button color="primary" (click)="addCrewMember(role)"
      [disabled]="form.formGroup.disabled" class="add-role-button">
      {{ role }}
    </button>
  </div>
</fieldset>


<fieldset [formGroup]="form.formGroup"
[class.invalid]="form.formGroupErrors">
  <legend>
    Crew
     <small>(Minimum 1)</small>
    </legend>

  <div fxLayoutAlign="row" [formArrayName]="form.formControlNames.crewMembers"
    *ngFor="let crewMemberControl of form.formGroup.controls.crewMembers.controls; let i = index">

    <form-crewmember-control [formControl]="crewMemberControl" fxFlex></form-crewmember-control>

    <button *ngIf="!form.formGroup.disabled" mat-icon-button color="warn" (click)="removeCrewMember(i)" [disabled]="form.formGroup.disabled">
      <mat-icon>delete</mat-icon>
    </button>

  </div>
</fieldset>
