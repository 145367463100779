<fieldset [formGroup]="form.formGroup" class="container">
  <legend>Misc Item form</legend>

  <mat-form-field>
    <mat-select
      data-select-misc-type
      placeholder="Select misc type"
      [formControlName]="form.formControlNames.itemType"
    >
      <mat-option
        data-select-misc-type-option
        *ngFor="let type of MiscType | keyvalue"
        [value]="type.value"
      >
        {{ type.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div [ngSwitch]="form.formGroup.value?.itemType">
    <app-test-item *ngSwitchCase="MiscType.TEST"
     [formControlName]="form.formControlNames.test"></app-test-item>
  </div>

</fieldset>
