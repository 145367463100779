import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';

import { CurrencyAppComponent } from './currency-app.component';
import { CurrencyTableComponent } from './views/currency-table/currency-table.component';
import { RouterModule } from '@angular/router';
import { CURRENCY_ROUTES } from './currency.routes';
import { MaterialDesignModule } from 'global-shared/modules/material-design.module';

const providers = [];

@NgModule({
  declarations: [
    CurrencyAppComponent,
    CurrencyTableComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule,
    AngularFireAuthGuardModule,
    MaterialDesignModule,
    RouterModule.forRoot(CURRENCY_ROUTES, {})
  ],
  providers,
  bootstrap: [CurrencyAppComponent]
})
export class CurrencyModule { }

@NgModule({})
 export class CurrencySharedModule {
   static forRoot(): ModuleWithProviders<CurrencyModule> {
     return {
       ngModule: CurrencyModule,
       providers
     };
   }
 }
