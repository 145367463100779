import { Size } from '../item-base/alse-base';

export enum HarnessType {
  CHAMELEON = 'Chameleon',
  TRITON = 'Triton',
}

export enum HarnessTypeNumber {
  CHAMELEON = '001',
  TRITON = '002',
}

export interface BaseHarness {
  size: Size;
}

export interface CHAMELEON extends BaseHarness {
  itemType: HarnessType.CHAMELEON;
  itemNumber: HarnessTypeNumber.CHAMELEON;
}

export interface TRITON extends BaseHarness {
  itemType: HarnessType.TRITON;
  itemNumber: HarnessTypeNumber.TRITON;
}

export type OneHarness = CHAMELEON | TRITON;
