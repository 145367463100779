import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FlightTime } from 'src/app/log/models/log.model';

@Component({
  selector: 'flight-time-control',
  templateUrl: './flight-time.component.html',
  styleUrls: ['./flight-time.component.css'],
  providers: subformComponentProviders(FlightTimeControl)
})
export class FlightTimeControl {

  public form = createForm<FlightTime>(this, {
    formType: FormType.SUB,
    formControls: {
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
      startTime: new UntypedFormControl(null),
      endTime: new UntypedFormControl(null),
      flightTime: new UntypedFormControl(null),
    }
  })

  calculateFlightTime() {
    const {end, start, startTime, endTime} = this.form.formGroup.value;

    if (start && end && startTime && endTime) {
      const flightTime = Math.abs(Number(end ) - Number(start)) / 36e5;
      this.form.formGroup.controls.flightTime.setValue(Number(flightTime.toFixed(2)));
    }
  }

  addTimesToDates() {
    const {end, start, startTime, endTime} = this.form.formGroup.value;
    if (start && end && startTime && endTime && typeof endTime != 'object') {
      const [endTimehh, endTimemm] = endTime?.split(':');
      const [startTimehh, startTimemm] = startTime?.split(':');
      this.form.formGroup.value.end.setHours(Number(endTimehh), Number(endTimemm), 0o0);
      this.form.formGroup.value.start.setHours(Number(startTimehh), Number(startTimemm), 0o0);
    }
    this.calculateFlightTime();
  };
}
