import { AlseCategoryNumber, AlseCategoryType, STATUS } from './alse-base';

import { HardwareItem } from '../item-categories/hardware-category';
import { HarnessItem } from '../item-categories/harness-category';
import { MiscItem } from '../item-categories/misc-category';
import { PpeItem } from '../item-categories/ppe-category';

import { AlseItemInspection } from '../item-inspections/alse-inspection';
import { Attachment } from '../item-records/alse-file-attachment';
import { ManufacturerDetails } from './manufacturer-details';
import { SurvivalItem } from '../item-categories/survival-category';

export class FirestoreAlseItem {
  id: string;
  ssn: string;
  tenant: string;
  category: AlseCategory;
  status: STATUS;
  location: string;
  manufacturerDetails: ManufacturerDetails;
  startInspectionsDate: Date;
  inspections: AlseItemInspection[];
  attachments: Attachment[];
  item: any;
  createdAt: Date;
  updatedAt: Date;
  constructor(data: OneAlseItem) {
    this.id = data.id || null;
    this.ssn = data.ssn || null;
    this.tenant = data.tenant || null;
    this.category = data.category || null;
    this.status = data.status || null;
    this.location = data.location || null;
    this.manufacturerDetails = data.manufacturerDetails || null;
    this.startInspectionsDate = data.startInspectionsDate || null;
    this.inspections = data.inspections || null;
    this.attachments = data.attachments || null;
    this.item = data.item || null;
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }
}

export interface AlseCategory {
  categoryType: AlseCategoryType;
  categoryNumber: AlseCategoryNumber;
}

export type OneAlseItem =
  HardwareItem |
  HarnessItem |
  SurvivalItem |
  MiscItem |
  PpeItem;

