import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Actions, ofActionCompleted, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { LayoutState } from './state/layout.state';
import { RouterNavigation, RouterState } from '@ngxs/router-plugin';
import { Layout } from './state/layout.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  private destroy$ = new Subject<void>();
  isHandset$: Observable<boolean>;
  showSidenav$: Observable<boolean>;
  tenant$: Observable<string>;

  constructor(
    private actionsS: Actions,
    private store: Store,
  ) {
    this.isHandset$ = this.store.select(LayoutState.isHandset);
    this.showSidenav$ = this.store.select(LayoutState.showSidenav);
    this.tenant$ = this.store.select(PreferencesState.tenant);

    // actionsS.pipe(
    //   ofActionSuccessful(RouterNavigation),
    //   takeUntil(this.destroy$),
    // ).subscribe(action => this.store.dispatch(new Layout.CloseSidenav()));
  }

}
