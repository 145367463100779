export enum SurvivalType {
  // EPIRB = '406 Personal Locator Beacon',
  // RADIO = 'IComm Radio',
  LASER = 'Signal Laser',
}

export enum SurvivalTypeNumber {
  // EPIRB = '001',
  LASER = '004'
}

export interface BaseSurvivalItem {

}

export interface LASER extends BaseSurvivalItem {
  itemType: SurvivalType.LASER;
  itemNumber: SurvivalTypeNumber.LASER;
}

export type OneSurvival = LASER;
