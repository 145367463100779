import { Component, Input, Output } from '@angular/core';
import { createForm, FormType } from 'ngx-sub-form';
import { FormControl, Validators } from '@angular/forms';
import { InspectionStatus } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';
import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-peform-inspection-form',
  templateUrl: './peform-inspection-form.component.html',
  styleUrls: ['./peform-inspection-form.component.css']
})
export class PeformInspectionFormComponent {
  public InspectionStatus = InspectionStatus;

  private input$: Subject<InspectionRecord | undefined> = new Subject();
  @Input() set input(value: InspectionRecord | undefined) {
    this.input$.next(value);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() formUpdate: Subject<InspectionRecord> = new Subject();

  public manualSave$ = new Subject<void>();

  public form = createForm<InspectionRecord>(this, {
    formType: FormType.ROOT,
    input$: this.input$,
    output$: this.formUpdate,
    manualSave$: this.manualSave$,
    formControls: {
      id: new FormControl(null),
      itemId: new FormControl(null),
      inspectionId: new FormControl(null),
      ssn: new FormControl(null),
      tenant: new FormControl(null),
      itemType: new FormControl(null),
      categoryNumber: new FormControl(null),
      itemNumber: new FormControl(null),
      location: new FormControl(null),
      type: new FormControl(null),
      status: new FormControl(null, Validators.required),
      user: new FormControl(null, Validators.required),
      qaUser: new FormControl(null),
      notes: new FormControl(null, Validators.required),
      dateDue: new FormControl(null),
      datePerformed: new FormControl(null, Validators.required),
      attachment: new FormControl(null),
    }
  })

  get cardLink(): string {
    return `/assets/alse-cards/${this.form.formGroup.value?.categoryNumber}-${this.form.formGroup.value?.itemNumber}.pdf`;
  }

  handleAttachment(event): void {
    this.form.formGroup.controls?.attachment.setValue(event);
  }
}
