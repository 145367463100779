export enum MiscType {
  TEST = 'TEST',
}

export enum MiscTypeNumber {
  TEST = '999',
}

export interface BaseMiscItem {
}


export interface TEST extends BaseMiscItem {
  itemType: MiscType.TEST;
  itemNumber: MiscTypeNumber.TEST;
}

export type OneMisc = TEST;
