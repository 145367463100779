import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '@angular/fire/auth';

import { UserService } from 'global-shared/services/users/user.service';

import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';

import { AppUser } from 'global-shared/models/user-account.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  user$: Observable<AppUser>;

  constructor(
    private auth: Auth,
    private store: Store,
    private userService: UserService
  ) {
    this.user$ = this.store.select(AuthState.user)
  }

  async signUp(email: string, password: string): Promise<void> {
    const response = await createUserWithEmailAndPassword(this.auth, email, password);
    const user = AppUser.fromSignup(response.user);
    this.userService.upsertUser(response.user.uid, user);
  }

  async login(email: string, password: string): Promise<void> {
    const response = await signInWithEmailAndPassword(this.auth, email, password);
    const user = AppUser.fromLogin(response.user);
    this.userService.upsertUser(response.user.uid, user);
  }

}
