import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrewmemberArrayComponent } from './crewmember-array/crewmember-array.component';
import { CrewmemberControlComponent } from './crewmember-array/crewmember-control/crewmember-control.component';
import { UnitControlsComponent } from './unit-controls/unit-controls.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialDesignModule } from 'global-shared/modules/material-design.module';
import { FlightDetailsControl } from './flight-details/flight-details.component';
import { FlightTimeControl } from './flight-time/flight-time.component';
import { MaintanenceDetailsControl } from './maintanence-details/maintanence-details.component';
// import { HHmmInput } from './select-time/select-time.component';
import { CallLogArrayComponent } from './call-log-array/call-log-array.component';
import { CallControl } from './call-log-array/call-control/call-control.component';
import { HoistFlightControl } from './hoist/hoist.control';
import { HoistEvolutionsArrayControl } from './hoist/hoist-evolutions-array/hoist-evolutions-array.control';

const COMPONENTS = [
  CallControl,
  CallLogArrayComponent,
  CrewmemberArrayComponent,
  CrewmemberControlComponent,
  FlightDetailsControl,
  FlightTimeControl,
  // HHmmInput,
  HoistFlightControl,
  HoistEvolutionsArrayControl,
  MaintanenceDetailsControl,
  UnitControlsComponent,
]

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    ReactiveFormsModule,
  ],
  exports: COMPONENTS,
})
export class SharedFormsModule { }
