import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { MetarsGeoJSON } from 'global-shared/models/metars/metars.model';
import { MetarsService } from 'global-shared/services/metars/metars.service';
import { map, tap } from 'rxjs/operators';
import { GetMetars, SetLoading } from './metars.actions';

import { Observable } from 'rxjs';
import { distance } from 'global-shared/turf/turf';
import { PositionState } from '../position/position.state';

export interface MetarsStateModel {
  loading: boolean;
  metars: MetarsGeoJSON;
  bbox: any;
}

@State<MetarsStateModel>({
  name: 'metars',
  defaults: {
    loading: false,
    metars: null,
    bbox: null,
  }
})
@Injectable()
export class MetarsState {
  @Selector()
  static bbox(state: MetarsStateModel) {
    const bounds = state.bbox?.split(',').map(Number)
    return state.bbox ? [[bounds[0], bounds[1]], [bounds[2], bounds[3]]] : null;
  }

  @Selector()
  static metarsLoading(state: MetarsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static metars(state: MetarsStateModel): MetarsGeoJSON {
    return state.metars;
  }

  constructor(
    private metarsService$: MetarsService,
    private store: Store,
  ) { }

  @Action(SetLoading)
  setLoading(ctx: StateContext<MetarsStateModel>, { payload }: SetLoading): void {
    ctx.patchState({ loading: payload });
  }

  @Action(GetMetars)
  getMetars(ctx: StateContext<MetarsStateModel>, action: GetMetars): Observable<MetarsGeoJSON> {
    this.store.dispatch(new SetLoading(true));
    const coords = this.store.selectSnapshot(PositionState.coords);
    const center = [coords?.longitude, coords?.latitude];
    let bbox;

    return this.metarsService$.fetchMetars().pipe(
      // tap(console.log),
      map((metars: MetarsGeoJSON) => {
        metars.features.map(feature => {
          if (feature.properties?.bbox) {
            bbox = feature.properties.bbox
          } else {
            feature.properties.distance = distance(center, feature.geometry.coordinates, { units: 'miles' })
          }
        })
        metars.features.sort((a, b) => a.properties.distance - b.properties.distance)
        // metars.features.shift();
        return metars;
      }),
      tap((metars: MetarsGeoJSON) => {
        ctx.patchState({
          loading: false,
          metars,
          bbox
        });
      })
    );
  }
}
