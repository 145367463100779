<mat-card>
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <table mat-table matSort [dataSource]="dataSource" aria-label="Frats" class="full-width-table">

      <ng-container matColumnDef="status_icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <mat-icon>{{ item.status?.flight == 'ACCEPTED' ? 'flight_takeoff' : 'airplanemode_inactive' }}</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let item"> {{ item.id | slice:1:5 }} </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef> Score </th>
        <td mat-cell *matCellDef="let item">
          <span>
            <p> {{ item.scores.totalScore }} / {{ item.scores.totalPossibleValue }}</p>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="user.displayName">
        <th mat-header-cell *matHeaderCellDef> User </th>
        <td mat-cell *matCellDef="let item"> {{item.user.displayName}} </td>
      </ng-container>

      <ng-container matColumnDef="unit.callSign">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
        <td mat-cell *matCellDef="let item"> {{item.unit.callSign}} </td>
      </ng-container>

      <ng-container matColumnDef="timestamps.createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let item"> {{item.timestamps.createdAt.toMillis() | date:'MMM d HH:mm' }} </td>
      </ng-container>

      <ng-container matColumnDef="status.flight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let item">
          <!-- <mat-icon>{{ item.status?.flight == 'ACCEPTED' ? 'flight_takeoff' : 'airplanemode_inactive' }}</mat-icon> -->
          {{ item.status?.flight }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-sticky"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" (click)="openDialog(row)"></tr>

    </table>

    <mat-paginator #paginator
     [pageIndex]="0"
     [pageSizeOptions]="[5, 10, 20, 50, 100]"
     aria-label="Select page of frat entries">
    </mat-paginator>

  </mat-card-content>
</mat-card>
