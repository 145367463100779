import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { collection, collectionData, CollectionReference, doc, Firestore, getCountFromServer, limit, orderBy, query, setDoc, startAfter, updateDoc } from '@angular/fire/firestore';
import { FratsActions } from '../core/state/frats/frats.actions';

@Injectable({
  providedIn: 'root'
})
export class FratsService {
  tenantId$: Observable<string>;

  tenantCollection: CollectionReference;

  cursor$: BehaviorSubject<any>;
  queryConstraints$: BehaviorSubject<{
    active: string,
    direction,
    pageSize: number
  } | null>;

  constructor(
    private firestore: Firestore,
    private store: Store
  ) {
    this.tenantId$ = this.store.select(PreferencesState.tenant);

    this.cursor$ = new BehaviorSubject(null);
    this.queryConstraints$ = new BehaviorSubject(null);

    this.tenantId$.pipe(
      map((tenant: string) => collection(this.firestore,`tenants/${tenant}/frat/metadata/frats`)),
    ).subscribe(collection => this.tenantCollection = collection);
  }

  dynmaicQueryFrats() {
    return combineLatest([
      this.tenantId$,
      this.cursor$,
      this.queryConstraints$,
    ]).pipe(
      switchMap(([tenant, cursor, constraints]) => {

        const { active, direction, pageSize } = constraints;

        const queryConstraints = [];
        if (active) queryConstraints.push(orderBy(active, direction));
        if (cursor) queryConstraints.push(startAfter(cursor));
        if (pageSize) queryConstraints.push(limit(pageSize));

        const fratsCollection = collection(this.firestore, `tenants/${tenant}/frat/metadata/frats`);

        this.getCount(fratsCollection);

        const fratsQuery = query(fratsCollection, ...queryConstraints);
        return collectionData(fratsQuery);
      })
    )
  }

  async getCount(query: any) {
    const count = (await getCountFromServer(query)).data().count;
    this.store.dispatch(new FratsActions.SetCount(count));
  }

  listFrats() {
    return this.tenantId$.pipe(
      switchMap(tenant => {
        return collectionData(
          query(
            collection(this.firestore, `tenants/${tenant}/frat/metadata/frats`),
            orderBy('timestamps.createdAt', 'desc'),
            limit(5)
          )
        )
      })
    )
  }

  public upsert$(id: string, value) {
    return id
      ? this.update$(id, value)
      : this.create$(value);
  }

  private async create$(value) {
    const docRef = doc(this.tenantCollection);
    const id = docRef.id;
    value.id = id;
    await setDoc(docRef, value);
    return of(id);
  }
  private async update$(id: string, value) {
    const docRef = doc(this.tenantCollection, id);
    await updateDoc(docRef, value);
    return of(id);
  }
}
