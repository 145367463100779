<form-user-profile *ngIf="(user$ | async) as user" [person]="user" (personUpdate)="userUpdated($event)"></form-user-profile>

<mat-divider></mat-divider>

<mat-card>
  <mat-card-header>
    <mat-card-subtitle>
      Auth options
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions>
    <button mat-stroked-button color="warn" (click)="signout()">Sign out</button>
  </mat-card-actions>
</mat-card>
