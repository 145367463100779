import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialDesignModule } from 'global-shared/modules/material-design.module';

import { NearestPointsListComponent } from 'global-shared/views/nearest-points-list/nearest-points-list.component';
import { MetarsComponent } from '../views/metars/metars.component';
import { RiskScoreBarComponent } from '../views/risk-score-bar/risk-score-bar.component';
import { FormsModule } from '@angular/forms';

const COMPONENTS = [
  NearestPointsListComponent,
  MetarsComponent,
  RiskScoreBarComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialDesignModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class CoreModule { }
