import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

import { AlseCategoryType, AlseCategoryNumber } from 'global-shared/models/alse-base-models/item-base/alse-base';
import { AlseCategory } from 'global-shared/models/alse-base-models/item-base/alse-main-item';

@Component({
  selector: 'app-item-category',
  templateUrl: './item-category.component.html',
  styleUrls: ['./item-category.component.css'],
  providers: subformComponentProviders(ItemCategoryComponent),
})
export class ItemCategoryComponent {
  public AlseCategoryType: typeof AlseCategoryType = AlseCategoryType;
  public AlseCategoryNumber: typeof AlseCategoryNumber = AlseCategoryNumber;

  public form = createForm<AlseCategory>(this, {
    formType: FormType.SUB,
    formControls: {
      categoryType: new FormControl(null),
      categoryNumber: new FormControl(null),
    },
    fromFormGroup: (formValue: AlseCategory | null): AlseCategory => {
      return {
        categoryType: formValue.categoryType,
        categoryNumber: AlseCategoryNumber[formValue.categoryType?.toUpperCase()],
      };
    },
  })

}
