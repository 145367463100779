export class AppUser {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
  defaultTenant?: string;
  tenants?: {};
  refreshToken?: string;

  constructor(data?: any) {
    this.uid = data.uid;
    this.displayName = data.displayName;
    this.email = data.email;
    this.photoURL = data.photoURL;
    this.tenants = {};
  }

  static fromLogin(data: any): AppUser {
    const appUser = {
      uid: data.uid,
      displayName: data.displayName,
      email: data.email,
      photoURL: data.photoURL
      // DO NOT PUT TENANTS IN HERE!!!!!!!
    };
    return appUser;
  }

  static fromSignup(data: any): AppUser {
    const appUser = {
      uid: data.uid,
      displayName: data.displayName,
      email: data.email,
      photoURL: data.photoURL,
      tenants: {}
    };
    return appUser;
  }

  static toFrat({uid, displayName, email}: AppUser) {
    return {
      uid,
      displayName,
      email
    }
  }
}

export interface AppUserMetadata {
  defaultTenant: string;
  customClaims: {};
  refreshTime?: Date;
  uid?: string;
}
