import { AfterViewInit, Component } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { CrewMember, CrewMemberRole } from 'global-shared/models/crewmember.model';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

interface CrewMemberArray {
  crewMembers: CrewMember[];
}

@Component({
  selector: 'form-crewmember-array',
  templateUrl: './crewmember-array.component.html',
  styleUrls: ['./crewmember-array.component.css'],
  providers: subformComponentProviders(CrewmemberArrayComponent),
})
export class CrewmemberArrayComponent implements AfterViewInit{
  roles = Object.values(CrewMemberRole);

  ngAfterViewInit(): void {
    this.form.formGroup.value.crewMembers.length < 1
    ? this.addCrewMember(CrewMemberRole.PIC)
    : null
  }

  public form = createForm<CrewMember[], CrewMemberArray>(this, {
    formType: FormType.SUB,
    formControls: {
      crewMembers: new FormArray([], {
        validators: formControl => (formControl.value.length >= 1 ? null : { minimumCrewMemberCount: 1 }),
      })
    },
    toFormGroup: (obj: CrewMember[]): CrewMemberArray => {
      return {
        crewMembers: !obj ? [] : obj,
      }
    },
    fromFormGroup: (formValue: CrewMemberArray): CrewMember[] => {
        return formValue.crewMembers;
    },
    createFormArrayControl: (key, value) => {
      switch (key) {
        case 'crewMembers':
          return new FormControl(value, [Validators.required]);
        default:
          return new FormControl(value);
      }
    }
  })

  public removeCrewMember(index: number): void {
    this.form.formGroup.controls.crewMembers.removeAt(index);
  }

  public addCrewMember(role): void {
    this.form.formGroup.controls.crewMembers.push(
      this.form.createFormArrayControl('crewMembers', {
        role,
        user: null,
      }),
    );
  }

}
