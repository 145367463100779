import { Component } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FratQuestionV2 } from 'global-shared/models/question.model';

interface QuestionsArrayForm {
  questions: FratQuestionV2[]
}

@Component({
  selector: 'app-frat-questions-array',
  templateUrl: './frat-questions-array.component.html',
  styleUrls: ['./frat-questions-array.component.css'],
  providers: subformComponentProviders(FratQuestionsArrayComponent),
})
export class FratQuestionsArrayComponent {

  public form = createForm<FratQuestionV2[], QuestionsArrayForm>(this, {
    formType: FormType.SUB,
    formControls: {
      questions: new FormArray([], Validators.required)
    },
    toFormGroup: (obj: FratQuestionV2[]): QuestionsArrayForm => {
      return {
        questions: !obj ? [] : obj,
      }
    },
    fromFormGroup: (formValue: QuestionsArrayForm): FratQuestionV2[] => {
      return formValue.questions;
    },
    createFormArrayControl: (key, value) => {
      switch (key) {
        case 'questions':
          return new FormControl(value, Validators.required);
        default:
          return new FormControl(value, Validators.required);
      }
    }
  })
}
