import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { CARABINER, DELTALINK, MAPRING, HardwareType, OneHardware } from 'global-shared/models/alse-base-models/item-types/hardware-types';

interface OneHardwareForm {
  carabiner: CARABINER | null;
  deltaLink: DELTALINK | null;
  mapRing: MAPRING | null;
  itemType: HardwareType | null;
}

@Component({
  selector: 'app-hardware-item-form',
  templateUrl: './hardware-item-form.component.html',
  styleUrls: ['./hardware-item-form.component.css'],
  providers: subformComponentProviders(HardwareItemFormComponent),
})
export class HardwareItemFormComponent {

  public HardwareType = HardwareType;

  public form = createForm<OneHardware, OneHardwareForm>(this, {
    formType: FormType.SUB,
    formControls: {
      carabiner: new FormControl(null),
      deltaLink: new FormControl(null),
      mapRing: new FormControl(null),
      itemType: new FormControl(null, { validators: [Validators.required] }),
    },
    toFormGroup: (obj: OneHardware): OneHardwareForm => {
      return {
        carabiner: obj.itemType === HardwareType.CARABINER ? obj : null,
        deltaLink: obj.itemType === HardwareType.DELTALINK ? obj : null,
        mapRing: obj.itemType === HardwareType.MAPRING ? obj : null,
        itemType: obj.itemType,
      };
    },
    fromFormGroup: (formValue: OneHardwareForm): OneHardware => {
      switch (formValue.itemType) {
        case HardwareType.CARABINER:
          return formValue.carabiner;
        case HardwareType.DELTALINK:
          return formValue.deltaLink;
        case HardwareType.MAPRING:
          return formValue.mapRing;
        case null:
          return null;
      }
    }
  })
}
