import { Component, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppUser } from 'global-shared/models/user-account.model';
import { FormType, createForm } from 'ngx-sub-form';
import { Subject, tap } from 'rxjs';

export interface AppUserForm {
  displayName: string;
}

@Component({
  selector: 'form-user-profile',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.css']
})
export class UserProfileFormComponent {
  private input$: Subject<AppUser | undefined> = new Subject();
  private _person: AppUser;

  @Input() set person(person: AppUser | undefined) {
    this._person = person;
    this.input$.next(person);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() personUpdate: Subject<AppUser> = new Subject();

  public manualSave$$: Subject<void> = new Subject();

  public form = createForm<AppUser, AppUserForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.personUpdate,
    manualSave$: this.manualSave$$,
    formControls: {
      displayName: new FormControl(null, Validators.required)
    },
    toFormGroup: (obj: AppUser): AppUserForm => {
      const { displayName } = obj;
      return { displayName };
    },
    fromFormGroup: (formValue: AppUserForm): AppUser => {
      return { ...this._person, ...formValue }
    }
  })
}
