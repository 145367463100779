<mat-card class="mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>
      {{ form.formGroup.value[form.formGroup.value.category?.categoryType?.toLowerCase()+"Item"]?.itemType }} | {{
      form.formGroup.value.ssn }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <form [formGroup]="form.formGroup" fxLayout="column" autocomplete="off">

      <control-item-status [formControlName]="form.formControlNames.status"></control-item-status>

      <app-item-category [formControlName]="form.formControlNames.category">
      </app-item-category>

      <div [ngSwitch]="form.formGroup.value.category?.categoryType">

        <app-hardware-item-form *ngSwitchCase="AlseCategoryType.HARDWARE"
          [formControlName]="form.formControlNames.hardwareItem"></app-hardware-item-form>

        <app-harness-item-form *ngSwitchCase="AlseCategoryType.HARNESS"
          [formControlName]="form.formControlNames.harnessItem">
        </app-harness-item-form>

        <alse-survival-item-form *ngSwitchCase="AlseCategoryType.SURVIVAL"
          [formControlName]="form.formControlNames.survivalItem">
        </alse-survival-item-form>

        <app-misc-item-form *ngSwitchCase="AlseCategoryType.MISC" [formControlName]="form.formControlNames.miscItem">
        </app-misc-item-form>

        <app-ppe-item-form *ngSwitchCase="AlseCategoryType.PPE" [formControlName]="form.formControlNames.ppeItem">
        </app-ppe-item-form>

      </div>

      <app-item-manufactor-detail-form [formControlName]="form.formControlNames.manufacturerDetails">
      </app-item-manufactor-detail-form>


      <fieldset>
        <legend>Inspections</legend>


        <mat-form-field>
          <input matInput [matDatepicker]="startInspectionsDate" placeholder="Start Inspections Date"
            [formControlName]="form.formControlNames.startInspectionsDate">
          <mat-datepicker-toggle matSuffix [for]="startInspectionsDate"></mat-datepicker-toggle>
          <mat-datepicker touchUi #startInspectionsDate></mat-datepicker>
        </mat-form-field>

        <mat-nav-list>
          <a mat-list-item [href]="" target="_blank" *ngFor="let inspection of form.formGroup.value.inspections">
            {{ inspection.type }} every {{ inspection.interval }} days, next on {{ inspection.nextInspectionDate.toMillis() | date}}
          </a>
        </mat-nav-list>

      </fieldset>

    </form>

  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button color="primary" routerLink="edit" [disabled]="!form.formGroup.disabled">Edit item</button>

    <button mat-raised-button color="primary" (click)="manualSave$$.next()"
      [disabled]="form.formGroup.invalid">Save item</button>

    <app-delete-button (delete)="handleDelete()"></app-delete-button>

  </mat-card-actions>

</mat-card>

<!-- <section fxLayout="row">
  <pre>{{ form.formGroupErrors | json }}</pre>
  <pre>{{ form.controlValue$ | async | json }}</pre>
  <pre>{{ form.formGroup.value | json }}</pre>
</section> -->
