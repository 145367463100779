import { AppUser } from "global-shared/models/user-account.model";

export namespace UsersActions {
  export class GetAllByTenant {
    static readonly type = "[USERS] Get all by tenant";
  }

  export class GetAll {
    public static readonly type = '[USERS] GetAll';
  }

  export class Get {
    public static readonly type = '[USERS] Get';
    constructor(public payload: string) { }
  }

  export class GetQuery {
    public static readonly type = '[USERS] Get query';
  }

  export class Set {
    public static readonly type = '[USERS] Set';
    constructor(public payload: AppUser[]) {}
  }

  export class SetCount {
    public static readonly type = '[USERS] Set count';
    constructor(public payload: number) {};
  }

  export class Upsert {
    public static readonly type = '[USERS] Upsert';
    constructor(public payload: Partial<AppUser>) { }
  }
}
