import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { AppUser } from "global-shared/models/user-account.model";
import { UserService } from "global-shared/services/users/user.service";
import { tap } from "rxjs/operators";
import { TenantState } from "../tenant/tenant.state";
import { UsersActions } from "./users.actions";
import { OrderByDirection } from "@angular/fire/firestore";

export interface UsersStateModel {
  loading: boolean;
  users: AppUser[];
  queryConstraints: {
    active: string,
    direction: OrderByDirection,
    pageSize: number,
  },
  queryCount: number;
}

export const USERS_STATE_TOKEN = new StateToken<UsersStateModel>('usersTableState');

@State({
  name: USERS_STATE_TOKEN,
  defaults: {
    loading: false,
    users: [],
    queryConstraints: null,
    queryCount: null,
  }
})
@Injectable()
export class UsersState {
  @Selector([UsersState, TenantState])
  public static usersByTenant(state: UsersStateModel, tenant: any): AppUser[] {
    return tenant?.users
  }

  @Selector()
  static queryCount(state: UsersStateModel): number {
    return state.queryCount;
  }

  @Selector()
  static queriedUsers(state: UsersStateModel): AppUser[] {
    return state.users;
  }

  constructor(
    private userService: UserService,
  ) { }

  @Action(UsersActions.GetAll)
  getAll({ patchState }: StateContext<UsersStateModel>) {
    return this.userService.listUsers().pipe(
      tap((users: AppUser[]) => patchState({ users }))
    ).subscribe();
  }

  @Action(UsersActions.GetQuery)
  getQuery({ patchState }: StateContext<UsersStateModel>): void {
    this.userService.dyamicQueryUsers().pipe(
      tap((users: AppUser[]) => patchState({
        users
      }))
    ).subscribe();
  }

  /** Setters to state */
  @Action(UsersActions.Set)
  set({ patchState }: StateContext<UsersStateModel>, { payload }: UsersActions.Set) {
    return patchState({
      users: payload,
    })
  }

  @Action(UsersActions.SetCount)
  setCount({ patchState }: StateContext<UsersStateModel>, { payload }: UsersActions.SetCount) {
    return patchState({
      queryCount: payload,
    })
  }

}
