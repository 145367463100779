import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { UpdatePosition } from 'global-shared/states/position/position.actions';

import { FratActions } from '../core/state/frat/frat.actions';
import { FratsActions } from '../core/state/frats/frats.actions';

import { FratState, FRAT_FORM_PATH } from '../core/state/frat/frat.state';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { AppUser } from 'global-shared/models/user-account.model';
import { SetSync } from '../core/state/tenant-questions/questions.actions';
import { LayoutState } from 'src/app/layout/state/layout.state';
import { Frat} from 'global-shared/models/frat.model';
@Component({
  selector: 'app-frat-root-container',
  templateUrl: './frat-root-container.component.html',
  styleUrls: ['./frat-root-container.component.css'],
})
export class FratRootContainerComponent implements OnDestroy {
  private destroy$ = new Subject();

  isHandset$: Observable<boolean>;
  frat$: Observable<Frat>;
  riskPercentage$: Observable<number>;

  constructor(
    private store: Store,
  ) {
    this.isHandset$ = this.store.select(LayoutState.isHandset);
    this.frat$ = this.store.select(FratState.frat);
    this.riskPercentage$ = this.store.select(FratState.riskPercentage);

    this.store.dispatch([
      new SetSync(false),
      new UpdatePosition(),
      new UpdateFormValue({
        path: FRAT_FORM_PATH,
        value: {
          ...AppUser.toFrat(this.store.selectSnapshot(state => state.auth.user)),
        },
        propertyPath: 'user',
      }),
    ]);
  }

  /** Used for testing */
  handleFratUpdate(event: any) {
    console.log('handleFratUpdate', event);
  }

  saveFrat(): void {
    console.log('save.ran')
    this.store.dispatch([
      new FratActions.SetPosition(this.store.selectSnapshot(state => state.position)),
      new UpdateFormValue({
        path: FRAT_FORM_PATH,
        value: this.store.selectSnapshot(state => state.position),
        propertyPath: 'position'
      }),
      new UpdateFormValue({
        path: FRAT_FORM_PATH,
        value: this.store.selectSnapshot(state => state.metars.metars),
        propertyPath: 'metars'
      }),
      new UpdateFormValue({
        path: FRAT_FORM_PATH,
        value: {
          ...this.store.selectSnapshot(FratState.scores),
        },
        propertyPath: 'scores'
      }),
      new UpdateFormValue({
        path: FRAT_FORM_PATH,
        value: {
          submittedAt: new Date().toDateString(),
          createdAt: new Date(),
          updatedAt: new Date(),
        },
        propertyPath: 'timestamps',
      }),
    ]).subscribe(() => this.store.dispatch([
      new FratsActions.Upsert(this.store.selectSnapshot<Frat>(state => state.fratState.fratForm.model)),
    ]));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
