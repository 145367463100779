import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';

import { AuthState } from 'src/app/auth/state/auth.state';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { SetTenant } from 'global-shared/states/preferences/preferences.actions';

import { MatSelectChange } from '@angular/material/select';
import { Navigate } from '@ngxs/router-plugin';
import { StyleManagerService } from 'src/app/services/style-manager.service';

@Component({
  selector: 'app-select-tenant',
  templateUrl: './select-tenant.component.html',
  styleUrls: ['./select-tenant.component.css']
})
export class SelectTenantComponent {

  allowedTenants$: Observable<any>;

  tenant$: Observable<string>;

  constructor(
    private store: Store,
    private styleManager: StyleManagerService,
  ) {
    this.allowedTenants$ = this.store.select(AuthState.allowedTenants);
    this.tenant$ = this.store.select(PreferencesState.tenant);
  }

  public handleTenantChange(event: MatSelectChange): void {
    this.store.dispatch([
      new SetTenant(event.value),
      // new TenantsActions.GetTenant(event.value),
      new Navigate([event.value, 'home'])
    ]);
    // this.styleManager.setStyle(
    //   "theme",
    //   `assets/${event.value}/css/style.scss`
    // )
  }

}
