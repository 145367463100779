<div fxFlex fxLayoutAlign="center" fxLayout="column">

  <mat-card
    *ngFor="let app of (appModules$ | async)">
    <mat-card-header>
      <mat-card-title>
        {{ app | uppercase }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

    </mat-card-content>
  </mat-card>

</div>
