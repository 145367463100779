import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientJsonpModule } from '@angular/common/http';
import { MaterialDesignModule } from 'global-shared/modules/material-design.module';

import { FratNgxsModule } from './modules/ngxs.module';
import { CoreModule } from './core/core.module';
import { FratFormsModule } from './forms/frat-forms.module';

import { MetarsService } from 'global-shared/services/metars/metars.service';

import { AppComponent } from './app.component';
import { FratRootContainerComponent } from './frat-root-container/frat-root-container.component';
import { FratToolbarComponent } from './frat-root-container/frat-toolbar/frat-toolbar.component';
import { FratBottomToolbarComponent } from './frat-root-container/frat-bottom-toolbar/frat-bottom-toolbar.component';
import { FratGetQuestionsButtonComponent } from './frat-root-container/frat-get-questions-button/frat-get-questions-button.component';
import { MetarsBottomSheetComponent } from './views/metars-bottom-sheet/metars-bottom-sheet.component';
import { FratsContainerComponent } from './views/frats-container/frats-container.component';
import { FratDialogComponent } from './views/frat-dialog/frat-dialog.component';
import { FratsChartsComponent } from './views/frats-charts/frats-charts.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { FratsTableComponent } from './views/frats-table/frats-table.component';

const providers = [
  MetarsService
];

@NgModule({
  declarations: [
    AppComponent,
    FratRootContainerComponent,
    FratToolbarComponent,
    FratBottomToolbarComponent,
    FratGetQuestionsButtonComponent,
    FratsContainerComponent,
    MetarsBottomSheetComponent,
    FratDialogComponent,
    FratsChartsComponent,
    FratsTableComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FratNgxsModule,
    CoreModule,
    FratFormsModule,
    MaterialDesignModule,
    HttpClientJsonpModule,
    GoogleChartsModule.forRoot(),
  ],
  providers,
  bootstrap: [
    AppComponent
  ]
})
export class FratModule { }

@NgModule({})
export class FratSharedModule{
  static forRoot(): ModuleWithProviders<FratModule> {
    return {
      ngModule: FratModule,
      providers,
    };
  }
}
