<fieldset [id]="form.formGroup.value.question"
 [formGroup]="form.formGroup"
 [class.invalid]="form.formGroupErrors"
 class="question-container">
  <legend><strong>{{ form.formGroup.value.question }}</strong></legend>

  <div class="mitigation" fxLayoutAlign="center center"
  [ngClass]="riskLevel[form.formGroup.value.selected ? form.formGroup.value.selected[0]?.value : null]">
    {{ form.formGroup.value.selected ? form.formGroup.value.selected[0]?.mitigation : "" }}
  </div>

  <mat-selection-list #risk dense
  [multiple]="false"
  [hideSingleSelectionIndicator]="true"
  [formControlName]="form.formControlNames.selected"
  [compareWith]="compareFn">
    <mat-list-option *ngFor="let option of form.formGroup.value.options" [value]="option">
     <span class="option"><small>{{ option.risk }}</small></span>
    </mat-list-option>
  </mat-selection-list>

</fieldset>
