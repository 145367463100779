/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-namespace */
// tslint:disable-next-line: no-namespace
export namespace DB {

  export enum Collection {
    AGGREGATION = 'aggregation',
    ATTACHMENTS = 'attachments',
    FRATS = 'frats',
    HISTORY = 'history',
    ITEMS = 'items',
    INSPECTIONS = 'inspections',
    METADATA = 'metadata',
    RESPONSES = 'responses',
    TENANTS = 'tenants',
    USERS = 'users',
    USERSMETADATA = 'usersMetadata',
  }

  export enum Documents {
    SYSTEMSERIALNUMBERS = 'systemSerialNumbers',
    NEXTNUMBER = 'nextNumber',
    TOTALCOUNT = 'total_count',
  }

}

// export interface SystemSerialNumbers {
//   nextNumber: number;
// }

// export interface ItemsAggregation {
//   total_count: number;
// }

// export interface TenantAggregation {
//   items: ItemsAggregation;
// }

// export interface TenantMetadata {
//   systemSerialNumbers: SystemSerialNumbers;
// }

// export interface Tenant {
//   id: string;
//   name: string;
//   aggregation: TenantAggregation;
//   metadata: TenantMetadata;
// }
