<form [formGroup]="form.formGroup" autocomplete="off">
  <mat-toolbar class="user-toolbar">
    <mat-toolbar-row>
      {{ form.formGroup.value.user.displayName }}
      <span fxFlex></span>
      {{ form.formGroup.value.unit.callSign }}
    </mat-toolbar-row>
    <mat-toolbar-row>
      <mat-card-subtitle fxFlex>
        <span fxFlex></span>
        {{ form.formGroup.value.scores.totalScore }} / {{ form.formGroup.value.scores.totalPossibleValue }}
        <span fxFlex></span>
        {{ $any(form.formGroup.value.timestamps.createdAt).toMillis() | date:'MMM d HH:mm' }}
        <span fxFlex></span>
        {{ form.formGroup.value.status.flight }}
        <span fxFlex></span>
      </mat-card-subtitle>
    </mat-toolbar-row>

  </mat-toolbar>
  <app-frat-status-form [formControlName]="form.formControlNames.status"></app-frat-status-form>
  <form-unit-controls [formControlName]="form.formControlNames.unit"></form-unit-controls>
  <form-crewmember-array [formControlName]="form.formControlNames.crewMembers"></form-crewmember-array>
  <app-frat-sections-array [formControlName]="form.formControlNames.sections"></app-frat-sections-array>
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Mitigation notes</mat-label>
    <textarea matInput placeholder="Notes..." [formControlName]="form.formControlNames.mitigationNotes"></textarea>
  </mat-form-field>
</form>
