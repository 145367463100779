import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MetarsGeoJSON } from 'global-shared/models/metars/metars.model';
import { GetMetars } from 'global-shared/states/metars/metars.actions';
import { MetarsState } from 'global-shared/states/metars/metars.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-metars-bottom-sheet',
  templateUrl: './metars-bottom-sheet.component.html',
  styleUrls: ['./metars-bottom-sheet.component.css']
})
export class MetarsBottomSheetComponent implements OnInit {

  metarsLoading$: Observable<boolean>;
  metars$: Observable<MetarsGeoJSON>;

  constructor(
    private store: Store,
  ) {
    this.metarsLoading$ = this.store.select(MetarsState.metarsLoading);
    this.metars$ = this.store.select(MetarsState.metars);
  }

  ngOnInit(): void {
  }

  getMetars(): void {
    this.store.dispatch(new GetMetars());
  }

}
