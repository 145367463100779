import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat/app';

@Pipe({
  name: 'fsDate'
})
export class FsDatePipe implements PipeTransform {
  transform(value: firebase.firestore.Timestamp | string): Date {
    if (typeof value === 'string') {
      return new Date(value);
    } else {
      return value.toDate();
    }
  }
}
