import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

// import { ItemsTableDataSource } from './items-table-datasource';
import { ItemService } from '../../services/item.service';
import { OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';

import { DB } from 'global-shared/models/db.model';
@Component({
  selector: 'app-items-table',
  templateUrl: './items-table.component.html',
  styleUrls: [
    './items-table.component.css',
    '../../../../../../../global-shared/styles/tables.global.css'
  ]
})
export class ItemsTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OneAlseItem>;

  dataSource: OneAlseItem[] = [];
  dataLength: Observable<DB.AlseMetadata>;
  isLoading = true;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['ssn', 'itemType', 'location', 'updatedAt'];

  constructor(
    private itemService: ItemService
  ) {
    this.itemService.listItems().subscribe(items => this.dataSource = items)
  }


  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // watch aggregation for total_count of items
    this.dataLength = this.itemService.alseMetadata();

    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     switchMap(values => {
    //       this.isLoading = true;
    //       const matTableQuery = {
    //         filter: null,
    //         active: this.sort.active || null,
    //         sortDirection: this.sort.direction || null,
    //         pageSize: this.paginator.pageSize || null,
    //         pageIndex: this.paginator.pageIndex || null,
    //         previousPageIndex: 0,
    //         ...values,
    //         cursor: this.getCursor(values),
    //       };
    //       return this.itemService.tableItems(
    //         matTableQuery.active,
    //         matTableQuery.sortDirection,
    //         matTableQuery.pageSize,
    //         matTableQuery.pageIndex,
    //         matTableQuery.previousPageIndex,
    //         matTableQuery.cursor,
    //       )
    //         .valueChanges()
    //         .pipe(
    //           catchError(() => of([]))
    //         );
    //     }),
    //     map(data => {
    //       this.isLoading = false;

    //       if (data === null) {
    //         return [];
    //       }

    //       return data;
    //     }),

    //   ).subscribe(data => {
    //     this.dataSource = data;
    //   });
  }

  // Determines the doc snapshot to paginate query
  getCursor(values): any {
    const current = this.dataSource;
    if (current.length && 'previousPageIndex' in values) {
      const { pageIndex, previousPageIndex } = values;
      return previousPageIndex > pageIndex
        ? current[0][this.sort.active]
        : current[current.length - 1][this.sort.active];
    }
    return null;
  }

  ngOnDestroy(): void {
  }

}
