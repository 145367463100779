import { Component } from '@angular/core';
import { FormArray, Validators, FormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FratSectionV2 } from 'global-shared/models/question.model';

interface SectionsArrayForm {
  sections: FratSectionV2[]
}

@Component({
  selector: 'app-frat-sections-array',
  templateUrl: './frat-sections-array.component.html',
  styleUrls: ['./frat-sections-array.component.css'],
  providers: subformComponentProviders(FratSectionsArrayComponent),
})
export class FratSectionsArrayComponent {
  public form = createForm<FratSectionV2[], SectionsArrayForm>(this, {
    formType: FormType.SUB,
    formControls: {
      sections: new FormArray([], Validators.required)
    },
    toFormGroup: (obj: FratSectionV2[]): SectionsArrayForm => {
      return {
        sections: !obj ? [] : obj,
      }
    },
    fromFormGroup: (formValue: SectionsArrayForm): FratSectionV2[] => {
      return formValue.sections;
    },
    createFormArrayControl: (key, value) => {
      switch (key) {
        case 'sections':
          return new FormControl(value, [Validators.required]);
        default:
          return new FormControl(value, [Validators.required]);
      }
    }
  })
}
