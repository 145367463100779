<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Users">

      <!-- Id Column -->
      <ng-container matColumnDef="uid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>UID</th>
      <td mat-cell *matCellDef="let row">{{row.uid}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">

            <img *ngIf="row.photoURL" mat-card-avatar mat-card-image [src]="row.photoURL" alt="Photo of {{ row.displayName }}">
            {{row.displayName}}

        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" (click)="openDialog(row)"></tr>
    </table>

    <!-- <mat-paginator #paginator
     [pageIndex]="0"
     [pageSizeOptions]="[10, 25, 50]">
    </mat-paginator> -->

  </mat-card-content>

</mat-card>
