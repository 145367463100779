import { Size } from '../item-base/alse-base';

export enum PpeType {
  HELMET = 'Helmet',
  NVG = 'NVG',
}

export enum PpeTypeNumber {
  HELMET = '001',
  NVG = '002'
}

export interface BasePpeItem {
}

export interface HELMET extends BasePpeItem {
  itemType: PpeType.HELMET;
  itemNumber: PpeTypeNumber.HELMET;
  size: Size;
}

export interface NVG extends BasePpeItem {
  itemType: PpeType.NVG;
  itemNumber: PpeTypeNumber.NVG;
}

export type OnePpe = HELMET | NVG;
