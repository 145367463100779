import { Injectable } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private tenantId$: Observable<string>;

  constructor(
    private firestore: Firestore,
    private store: Store
  ) {
    this.tenantId$ = this.store.select(PreferencesState.tenant);
  }

  getCurrentTenant(): Observable<any> {
    return this.tenantId$.pipe(
      switchMap(tenant => {
        const ref = doc(this.firestore, `tenants/${tenant}`);
        return docData(ref)
      })
    )
  }

}
