import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CARABINER, CarabinerType, HardwareType, HardwareTypeNumber } from 'global-shared/models/alse-base-models/item-types/hardware-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'app-carabiner-item',
  templateUrl: './carabiner-item.component.html',
  styleUrls: ['./carabiner-item.component.css'],
  providers: subformComponentProviders(CarabinerItemComponent),
})
export class CarabinerItemComponent {
  public CarabinerType = CarabinerType;

  public form = createForm<CARABINER>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(HardwareType.CARABINER, { validators: [Validators.required] }),
      itemNumber: new FormControl(HardwareTypeNumber.CARABINER, { validators: [Validators.required] }),
      type: new FormControl(null, { validators: [Validators.required] }),
    }
  })
}
