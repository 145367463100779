import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Call } from '../call-log-array.component';

@Component({
  selector: 'call-control',
  templateUrl: './call-control.component.html',
  styleUrls: ['./call-control.component.css'],
  providers: subformComponentProviders(CallControl),
})
export class CallControl {
  @Input() types: string[];

  public form = createForm<Call>(this, {
    formType: FormType.SUB,
    formControls: {
      arrest: new FormControl(null),
      type: new FormControl(null, Validators.required),
      notes: new FormControl(null, Validators.required),
    }
  })

  compareWith(o1, o2) {
    return o1 == o2 ? true : false;
  }
}
