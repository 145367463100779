<mat-card>
  <mat-card-header>
    <mat-card-title>
      About HEC133.app
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="content">
    <p><strong>Mode:</strong> {{ environment?.name | uppercase }}</p>
    <p><strong>AppVersion:</strong> {{ environment?.appVersion }}</p>
    <p><strong>Build:</strong> {{ environment?.version }}</p>
  </mat-card-content>
  <mat-card-footer class="footer">
    <mat-card-subtitle>
      <p>Developed by Acadiana Apps, LLC</p>
      <p><a href="mailto:info@acadianaapps.com" target="_blank">info@acadianaapps.com</a></p>
    </mat-card-subtitle>
  </mat-card-footer>
</mat-card>
