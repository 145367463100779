import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Size } from 'global-shared/models/alse-base-models/item-base/alse-base';
import { HarnessType, HarnessTypeNumber, TRITON } from 'global-shared/models/alse-base-models/item-types/harness-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';


@Component({
  selector: 'app-triton-item',
  templateUrl: './triton-item.component.html',
  styleUrls: ['./triton-item.component.css'],
  providers: subformComponentProviders(TritonItemComponent),
})
export class TritonItemComponent {

  public HarnessSize = Size;

  public form = createForm<TRITON>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(HarnessType.TRITON, Validators.required),
      itemNumber: new FormControl(HarnessTypeNumber.TRITON, Validators.required),
      size: new FormControl(null, Validators.required),
    }
  })
}
