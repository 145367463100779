import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';
import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';
import { ItemInspectionRecordService } from '../../../services/item-inspection-record.service';

@Component({
  selector: 'app-item-history',
  templateUrl: './item-history.component.html',
  styleUrls: ['./item-history.component.css']
})
export class ItemHistoryComponent implements OnInit {
  @Input() item: OneAlseItem;
  records$: Observable<InspectionRecord[]>;

  constructor(
    private itemRecordService: ItemInspectionRecordService,
  ) { }

  ngOnInit(): void {
    this.records$ = this.itemRecordService.listItemInspectionRecords(this.item.id);
  }

}
