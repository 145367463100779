import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Size } from 'global-shared/models/alse-base-models/item-base/alse-base';
import { CHAMELEON, HarnessType, HarnessTypeNumber } from 'global-shared/models/alse-base-models/item-types/harness-types';


@Component({
  selector: 'app-chameleon-item',
  templateUrl: './chameleon-item.component.html',
  styleUrls: ['./chameleon-item.component.css'],
  providers: subformComponentProviders(ChameleonItemComponent),
})
export class ChameleonItemComponent {

  public HarnessSize = Size;

  public form = createForm<CHAMELEON>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(HarnessType.CHAMELEON, Validators.required),
      itemNumber: new FormControl(HarnessTypeNumber.CHAMELEON, Validators.required),
      size: new FormControl(null, Validators.required),
    }
  })

}
