import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { ItemInspectionService } from '../services/item-inspection.service';

import { ItemPerformInspectionComponent } from '../item-perform-inspection/item-perform-inspection.component';
import { AlseItemInspection } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';

@Component({
  selector: 'app-items-due-container',
  templateUrl: './items-due-container.component.html',
  styleUrls: ['./items-due-container.component.css']
})
export class ItemsDueContainerComponent implements OnInit {

  itemsDue$: Observable<AlseItemInspection[]>;

  constructor(
    public dialog: MatDialog,
    private itemInspectionService: ItemInspectionService,
  ) {
    this.itemsDue$ = this.itemInspectionService.listInspectionsDue();
  }

  openDialog(inspection: AlseItemInspection): void {
    const dialogRef = this.dialog.open(ItemPerformInspectionComponent, {
      data: inspection,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', {result});
    });
  }

  ngOnInit(): void {
  }

}
