import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LayoutState } from 'src/app/layout/state/layout.state';

@Component({
  selector: 'app-frats-container',
  templateUrl: './frats-container.component.html',
  styleUrls: ['./frats-container.component.css']
})
export class FratsContainerComponent {

  isHandset$: Observable<boolean>;

  constructor(
    private store: Store,
  ) {
    this.isHandset$ = this.store.select(LayoutState.isHandset);
  }

}


