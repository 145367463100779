import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { CoreModule } from '../core/core.module';
import { MaterialDesignModule } from 'global-shared/modules/material-design.module';

import { FratRootFormComponent } from './frat-root-form/frat-root-form.component';
// import { FratUnitFormComponent } from '../../../../../global-shared/forms/frat-unit-form/frat-unit-form.component';
import { FratActionsBarComponent } from './frat-root-form/frat-actions-bar/frat-actions-bar.component';
import { FratSectionsArrayComponent } from './frat-root-form/frat-sections-array/frat-sections-array.component';
import { FratSectionControlComponent } from './frat-root-form/frat-sections-array/frat-section-control/frat-section-control.component';
import { FratQuestionsArrayComponent } from './frat-root-form/frat-sections-array/frat-section-control/frat-questions-array/frat-questions-array.component';
import { FratQuestionSelectFormComponent } from './frat-root-form/frat-sections-array/frat-section-control/frat-questions-array/frat-question-select-form/frat-question-select-form.component';
import { FratStatusFormComponent } from './frat-status-form/frat-status-form.component';
import { FratReadOnlyComponent } from './frat-read-only/frat-read-only.component';
import { SharedFormsModule } from 'global-shared/forms/shared-forms.module';

const COMPONENTS = [
  FratRootFormComponent,
  FratActionsBarComponent,
  FratSectionsArrayComponent,
  FratSectionControlComponent,
  FratQuestionsArrayComponent,
  FratQuestionSelectFormComponent,
  FratStatusFormComponent,
  // FratUnitFormComponent,
  FratReadOnlyComponent,
]

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    CoreModule,
    MaterialDesignModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    SharedFormsModule,
  ],
  exports: COMPONENTS
})
export class FratFormsModule { }
