<mat-sidenav-container class="sidenav-container" [ngClass]="'{{(tenant$ | async)}}-dark-theme'">

  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

    <mat-toolbar>Menu</mat-toolbar>

    <mat-divider></mat-divider>

    <app-select-tenant></app-select-tenant>

    <mat-divider></mat-divider>

    <app-nav-tree></app-nav-tree>

  </mat-sidenav>

  <mat-sidenav-content>

    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <img *ngIf="!(isHandset$ | async) && (tenant$ |async) as tenant" class="logo" [src]="'../../assets/'+tenant+'/icons/icon-36x36.png'" [alt]="tenant+' Logo'">

      <span fxFlex></span>

      <span *ngIf="(tenant$ |async) as tenant">{{ tenant | uppercase }}</span>

      <span fxFlex></span>

      <span></span>

      <button type="button" aria-label="About" mat-icon-button routerLink="/about">
        <mat-icon aria-label="About">info</mat-icon>
      </button>

    </mat-toolbar>

    <router-outlet></router-outlet>

  </mat-sidenav-content>

</mat-sidenav-container>
