import * as moment from 'moment';

export enum InspectionType {
  ADDED = 'Added To System',
  BUILDUP = 'Build Up',
  INSPECT = 'Inspect',
  FUNCTIONAL = 'Functional',
  POSTUSE = 'Post Use Inspect',
  REBUILD = 'Rebuild',
  ENDOFLIFE = 'End of Life',
}

export enum InspectionStatus {
  DUE = 'Due',
  INPROGRESS = 'In Progress',
  PENDINGQA = 'Pending QA',
  COMPLETE = 'Complete',
}


export interface Inspection {
  type: InspectionType;
  interval: number;
}

export class AlseItemInspection implements Inspection {
  id: string;
  itemId: string;
  currentRecordId: string;
  ssn: string;
  tenant: string;
  itemType: string;
  categoryNumber: string;
  itemNumber: string;
  type: InspectionType;
  interval: number;
  nextInspectionDate: Date;
  lastInspectionDate?: Date;
  status: string;

  static bumpInspectionCycle(inspection: AlseItemInspection, dateToBumpFrom: Date): AlseItemInspection {
    const nextDate = moment(dateToBumpFrom).add(inspection.interval, 'days').toDate();
    inspection.nextInspectionDate = nextDate;
    return inspection;
  }

  static aggregateInspection(inspection: AlseItemInspection) {
    const aggregateInspection = {
      type: inspection.type,
      date: inspection.nextInspectionDate
    };
    return aggregateInspection;
  }
}
