import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CHAMELEON, TRITON, HarnessType, OneHarness } from 'global-shared/models/alse-base-models/item-types/harness-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';


interface OneHarnessForm {
  chameleon: CHAMELEON | null;
  triton: TRITON | null;
  itemType: HarnessType | null;
}

@Component({
  selector: 'app-harness-item-form',
  templateUrl: './harness-item-form.component.html',
  styleUrls: ['./harness-item-form.component.css'],
  providers: subformComponentProviders(HarnessItemFormComponent),
})
export class HarnessItemFormComponent {

  public HarnessType = HarnessType;

  public form = createForm<OneHarness, OneHarnessForm>(this, {
    formType: FormType.SUB,
    formControls: {
      chameleon: new FormControl(null),
      triton: new FormControl(null),
      itemType: new FormControl(null, { validators: [Validators.required] }),
    },
    toFormGroup: (obj: OneHarness | null): OneHarnessForm => {
      return {
        chameleon: obj.itemType === HarnessType.CHAMELEON ? obj : null,
        triton: obj.itemType === HarnessType.TRITON ? obj : null,
        itemType: obj.itemType,
      }
    },
    fromFormGroup: (formValue: OneHarnessForm): OneHarness => {
      switch (formValue.itemType) {
        case HarnessType.CHAMELEON:
          return formValue.chameleon;
        case HarnessType.TRITON:
          return formValue.triton;
        case null:
          return null;
      }
    }
  })

}
