<fieldset [formGroup]="form.formGroup">

  <legend>Status & Info</legend>

  <mat-form-field>
    <mat-label>Location</mat-label>
    <mat-select [formControlName]="form.formControlNames.location">
      <mat-option *ngFor="let location of (locations$ | async)" [value]="location">{{ location }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select [formControlName]="form.formControlNames.status">
      <mat-option *ngFor="let status of Status | keyvalue" [value]="status.value">
        {{ status.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</fieldset>
