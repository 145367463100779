import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';

import firebase from 'firebase/compat/app';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';

import { AppUser, AppUserMetadata } from 'global-shared/models/user-account.model';

import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { CollectionReference, Firestore, OrderByDirection, collection, collectionData, doc, docData, getCountFromServer, limit, orderBy, query, setDoc, startAfter, where } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  tenantId$: Observable<string>;

  tenantCollection: CollectionReference;

  cursor$: BehaviorSubject<any>;
  queryConstraints$: BehaviorSubject<{
    active: string,
    direction,
    pageSize: number
  } | null>;

  constructor(
    // private db: AngularFirestore,
    private firestore: Firestore,
    private store: Store,
  ) {
    this.tenantId$ = this.store.select(PreferencesState.tenant);

    this.cursor$ = new BehaviorSubject(null);
    this.queryConstraints$ = new BehaviorSubject(null);

    // this.tenantId$.pipe(
    //   map((tenant: string) => collection(this.firestore,`tenants/${tenant}/frat/metadata/frats`)),
    // ).subscribe(collection => this.tenantCollection = collection);
  }

  dyamicQueryUsers() {
    return combineLatest([
      this.tenantId$,
      this.cursor$,
      this.queryConstraints$,
    ]).pipe(
      switchMap(([tenant, cursor, constraints]) => {
        const { active, direction, pageSize } = constraints;

        const queryConstraints = [];
        if (active) queryConstraints.push(orderBy(active, direction));
        if (cursor) queryConstraints.push(startAfter(cursor));
        if (pageSize) queryConstraints.push(limit(pageSize));

        const usersQuery = query(
          collection(this.firestore, 'users'),
          where('tenant', '==', tenant),
          ...queryConstraints
        )
        this.getCount(usersQuery);

        return collectionData(usersQuery)
      })
    )
  }

  async getCount(query: any) {
    const count = (await getCountFromServer(query)).data().count;
    // this.store.dispatch(new FratsActions.SetCount(count));
    return count;
  }

  getUser(uid: string): Observable<AppUser> {
    return docData(doc(this.firestore, `users/${uid}`)) as Observable<AppUser>;
  }

  /** This will need to be moved to a tenants service */
  /** @deprecated Will be accomplished with a selector */
  getUsersByTenant(tenant: string): Observable<any> {
    return docData(doc(this.firestore, `tenants/${tenant}`));
  }

  listUsers(): Observable<AppUser[]> {
    return collectionData(collection(this.firestore, 'users'), { idField: 'uid' }) as Observable<AppUser[]>;
  }

  upsertUser(uid: string, data): Promise<void> {
    return setDoc(doc(this.firestore, `users/${uid}`), data, { merge: true });
  }

  // User meta data section
  getUserMetadata(uid: string): Observable<AppUserMetadata> {
    return docData(doc(this.firestore, `usersMetadata/${uid}`)) as Observable<AppUserMetadata>;
  }

  setDevUserTenantData() {
    return {
      'aramco': {
        'alse': {
          'role': 'admin'
        },
        'role': 'admin'
      },
      'hec133': {
        'alse': {
          'role': 'editor'
        },
        'role': 'admin'
      },
      'sr3': {
        'alse': {
          'role': 'admin'
        },
        'frat': {
          'role': 'admin'
        },
        'role': 'admin'
      }
    };

  }
}
