<!-- <div class="container" *ngIf="(frat$ | async)?.sections; else getQuestionsButton"> -->
  <div class="container">

  <div class="frat-container">
    <!-- <app-risk-score-bar class="side-risk-bar" [value]="riskPercentage$ | async"></app-risk-score-bar> -->

    <div class="frat-internal-container">

      <app-frat-toolbar></app-frat-toolbar>

      <app-frat-root-form (fratUpdate)="saveFrat()">
      </app-frat-root-form>

      <!-- This is only a place holder to prevent hidden content -->
      <mat-toolbar></mat-toolbar>
      <app-frat-bottom-toolbar class="footer mat-elevation-z8"></app-frat-bottom-toolbar>

    </div>

    <!-- <app-risk-score-bar class="side-risk-bar" [value]="riskPercentage$ | async"></app-risk-score-bar> -->
  </div>

</div>

<ng-template #getQuestionsButton>
  <app-frat-get-questions-button></app-frat-get-questions-button>
</ng-template>
