import packageInfo from '../../package.json';

export const environment = {
  name: 'production',
  production: true,
  useEmulators: false,
  appVersion: packageInfo.version,
  version: packageInfo.version,
  firebaseConfig: {
    apiKey: 'AIzaSyBV9XqBjypMVEWE-LWWDHlUUqy33nlg2PU',
    authDomain: 'auth.hec133.app',
    databaseURL: 'https://hec133-global.firebaseio.com',
    projectId: 'hec133-global',
    storageBucket: 'hec133-global.appspot.com',
    messagingSenderId: '211844799390',
    appId: '1:211844799390:web:81c8a817e63c160127548f',
    measurementId: 'G-XDNRL3FFQE'
  },
  appCheck: {
    recaptcha3SiteKey: '6LfhGQ8kAAAAAL09U-u_HxHKjheLr6syA4IvcoL5',
    debugAppCheckToken: null
  },
  ngxs: {
    plugins: []
  },
  sentry: {
    dsn: 'https://f7a569b18014466bb63f0f9de3d221cf@o234584.ingest.sentry.io/5480740',
    release: `hec133@${packageInfo.version}`
  },
  endpoints: {
    fratQuestions: 'https://script.google.com/macros/s/AKfycbxFoI1n84tYbezJezzCu0jJ9lZ26OiES0s_jdrNf3UAbBz_xI6hDCo5xQ/exec'
  },
  box: {
    accessToken: 'lS372mGfC14CBf5VFiOOMUkFhn43Ek4x',
    folderId: '140567333427',
    proxy: 'https://us-central1-hec133-global.cloudfunctions.net/boxProxy'
  },
  mapbox: {
    accessToken: "pk.eyJ1IjoiYWNhZGlhbmFhcHBzIiwiYSI6ImNsZGRyNGxkazA2Y2Uzbm9kMnNkYnAyNGwifQ.iIM20fq1G-glInYtdTv7WQ"
  }
};
