import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DELTALINK, HardwareType, HardwareTypeNumber } from 'global-shared/models/alse-base-models/item-types/hardware-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'app-delta-link-item',
  templateUrl: './delta-link-item.component.html',
  styleUrls: ['./delta-link-item.component.css'],
  providers: subformComponentProviders(DeltaLinkItemComponent),
})
export class DeltaLinkItemComponent {
  public form = createForm<DELTALINK>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(HardwareType.DELTALINK),
      itemNumber: new FormControl(HardwareTypeNumber.DELTALINK),
    }
  })

}
