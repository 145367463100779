<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="full-width-table"
     matSort matSortActive="ssn" matSortDirection="asc"
     aria-label="Alse Items">
      <!-- Id Column -->
      <ng-container matColumnDef="ssn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
        <td mat-cell *matCellDef="let row">{{row.ssn}}</td>
      </ng-container>

      <!-- Item Type Column -->
      <ng-container matColumnDef="itemType">
        <th mat-header-cell *matHeaderCellDef>Item Type</th>
        <td mat-cell *matCellDef="let row">{{row.item?.itemType}}</td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let row">{{row.location}}</td>
      </ng-container>

      <!-- UpdatedAt Column -->
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified</th>
        <td mat-cell *matCellDef="let row">{{ row.updatedAt.toMillis() | date }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]"></tr>
    </table>

    <mat-paginator
      [length]="(dataLength | async)?.aggregation.items.total_count"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100, 250]">
    </mat-paginator>
  </mat-card-content>
</mat-card>
