<mat-card>
  <mat-card-header>
    <mat-form-field>
      <mat-label>Lookup Employee Id#</mat-label>
      <input matInput placeholder="Ex. 0008689" #queryId autocomplete="off">
    </mat-form-field>
    <button mat-button (click)="lookup(queryId.value)">
      <mat-icon>search</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>

    <ng-container *ngIf="loading | async">
      <mat-spinner class="loader"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="error">
      <div class="error">
        <span>{{ error.error }}</span>
        <p>{{ error.status }}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="traineeCurrency">

      <div *ngIf="traineeCurrency?.current; else notCurrent" class="status-img">
        <img src="../assets/img/current.png" >
      </div>

      <ng-template #notCurrent>
        <div class="status-img">
          <img src="../assets/img/not-current.png">
        </div>
      </ng-template>

    </ng-container>

  </mat-card-content>
</mat-card>
