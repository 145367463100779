import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { QuestionsService } from '../../../services/questions.service';
import { GetQuestions, SetSync } from './questions.actions';

import { FratSectionV2 } from '../../../../../../../global-shared/models/question.model';

import { UpdateFormValue } from '@ngxs/form-plugin';

// import { FAKE_QUESTIONS } from '../../../config/fake-questions';

export interface QuestionsStateModel {
  syncing: boolean;
  tenantQuestions: FratSectionV2[];
}

export const QUESTIONS_STATE_TOKEN = new StateToken<QuestionsStateModel>('questions');

@State({
  name: QUESTIONS_STATE_TOKEN,
  defaults: {
    syncing: false,
    tenantQuestions: [],
  }
})
@Injectable()
export class QuestionsState {
  @Selector([QUESTIONS_STATE_TOKEN])
  static syncing(state: QuestionsStateModel): boolean {
    return state.syncing;
  }

  @Selector([QUESTIONS_STATE_TOKEN])
  static tenantQuestions(state: QuestionsStateModel): FratSectionV2[] {
    return state.tenantQuestions;
  }

  /** ToDo this count will be incorrect need to count actual questions */
  @Selector([QuestionsState.tenantQuestions])
  static totalPossibleValue(sections: FratSectionV2[]): number {
    let questionsCount = 0;
    sections.map(section => questionsCount = questionsCount + section.questions.length)
    return questionsCount * 4;
  }

  constructor(
    private questionsService: QuestionsService,
  ) { }

  @Action(SetSync)
  setSync(ctx: StateContext<QuestionsStateModel>, { payload }: SetSync): void {
    ctx.patchState({ syncing: payload });
  }

  @Action(GetQuestions, { cancelUncompleted: true })
  getQuestions(ctx: StateContext<QuestionsStateModel>): Observable<void> {
    ctx.patchState({ syncing: true });
    return this.questionsService.getQuestions().pipe(
      tap(console.log),
      tap((tenantQuestions: FratSectionV2[]) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          syncing: false,
          tenantQuestions
        });
      }),
      mergeMap((tenantQuestions) => ctx.dispatch([
        new UpdateFormValue({
          path: 'fratState.fratForm',
          value: tenantQuestions,
          propertyPath: 'sections'
        }),
      ]))
    );
  }
}
