import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Store } from '@ngxs/store';

import * as moment from 'moment';

import { DB } from 'global-shared/models/db.model';
import { convertTimestampsPipe } from 'global-shared/pipes/convert-firebase-timestamp/convert-firebase-timestamp.pipe';
import { OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';
import { AlseItemInspection } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';

// import { AppContextService } from 'src/app/services/app-context.service';

@Injectable({
  providedIn: 'root'
})
export class ItemInspectionService {

  constructor(
    // private appContext: AppContextService,
    private db: AngularFirestore,
    private store: Store,
  ) { }

  listInspectionsDue(): Observable<AlseItemInspection[]> {
    const daysFromNow = moment(new Date()).add(15, 'days').toDate();
    return this.db.collectionGroup<AlseItemInspection>(DB.Collection.INSPECTIONS, ref =>
      ref
      .where('tenant', '==', this.store.selectSnapshot(state => state.preferences.tenant))
      .where('nextInspectionDate', '<=', firebase.firestore.Timestamp.fromDate(daysFromNow))
    ).valueChanges().pipe(
      convertTimestampsPipe(),
    );
  }

  listManufacturerEndOfLife(): Observable<OneAlseItem[]> {
    const daysFromNow = moment(new Date()).add(15, 'days').toDate();
    return this.db.collection<OneAlseItem>('this.BASE_ITEM_URL', ref =>
      // ref.orderBy('manufacturerDetails.manufacturerDate', 'desc')
      ref.where('manufacturerDetails.manufacturerDate', '<=', firebase.firestore.Timestamp.fromDate(daysFromNow))
    ).valueChanges().pipe(
      convertTimestampsPipe(),
    );
  }
}
