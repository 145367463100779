import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getConfig(tenant: string): Observable<any> {
    return this.http.get(`assets/${tenant}/config.json`);
  }
}
