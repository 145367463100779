import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap, tap} from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';
import { PROJECT_CONFIG } from '../config/project.config';

import { FratQuestionV2, FratSectionV2 } from '../../../../../global-shared/models/question.model';

const QUESTIONS_ENDPOINT = environment.endpoints.fratQuestions;

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  projectConfig = PROJECT_CONFIG;

  private readonly questionsUrl = QUESTIONS_ENDPOINT;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
  }

  getQuestions(): Observable<FratSectionV2[] | null> {
    return this.store.select(state => state?.preferences?.tenant).pipe(
      switchMap(tenant => this.projectConfig[tenant].fratKeyId
        ? this.http.get<FratSectionV2[]>(`${this.questionsUrl}?fratKeyId=${this.projectConfig[tenant].fratKeyId}&version=${this.projectConfig[tenant].version}`)
        : of(null)
      ),
    );
  }
}
