import { ManufacturerDetails } from './manufacturer-details';

import { AlseItemInspection } from '../item-inspections/alse-inspection';

import { Attachment } from '../item-records/alse-file-attachment';

import { AlseCategory } from './alse-main-item';

export enum STATUS {
  RFI = 'RFI',
  NONRFI = 'Non-RFI',
}

export enum AlseCategoryType {
  MISC = 'Misc',
  HARDWARE = 'Hardware',
  // WEBBING = 'Webbing',
  // ROPE = 'Rope',
  // FALL = 'Fall',
  // PATIENTLIFTING = 'Patient Lifting',
  // HELICOPTEREQUIPMENT = 'Helicopter Equipment',
  HARNESS = 'Harness',
  SURVIVAL = 'Survival',
  // SWIMMERGEAR = 'Swimmer Gear',
  PPE = 'PPE',
  // MEDICALGEAR = 'Medical Gear',
}

export enum AlseCategoryNumber {
  MISC = '00',
  HARDWARE = '10',
  WEBBING = '20',
  ROPE = '21',
  FALL = '30',
  PATIENTLIFTING = '40',
  HELICOPTEREQUIPMENT = '50',
  HARNESS = '60',
  SURVIVAL = '70',
  SWIMMERGEAR = '80',
  PPE = '90',
  MEDICALGEAR = '100',
}

export enum Size {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'XLarge',
  XXLARGE = 'XXLarge',
}

export interface BaseAlseItem {
  id: string;
  ssn: string;
  tenant: string;
  category: AlseCategory;
  status: STATUS;
  location: string;
  manufacturerDetails: ManufacturerDetails;
  startInspectionsDate: Date;
  inspections: AlseItemInspection[];
  attachments: Attachment[];
  createdAt: Date;
  updatedAt: Date;
}


