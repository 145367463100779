<mat-list *ngIf="metars">

  <mat-list-item *ngFor="let metar of metars.features.slice()" [ngClass]="metar.properties.fltcat" lines="3">
    <span matListItemTitle *ngIf="!metar.properties.bbox">
      {{ metar.properties.id }}
      <span fxFlex></span>
      {{ metar.properties.site }}
      <span fxFlex></span>
      {{ metar.properties.distance | number: '1.0-0' }} | mi
    </span>

    <span matListItemLine></span>

    <span>{{ metar.properties.rawOb }}</span>
  </mat-list-item>

</mat-list>
