import { isDevMode, NgModule } from '@angular/core';

import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider, AppCheckModule } from '@angular/fire/app-check';
import { getAuth, provideAuth } from '@angular/fire/auth';

import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import {
  connectAuthEmulatorInDevMode,
  connectFirestoreEmulatorInDevMode,
  connectFunctionsEmulatorInDevMode,
  connectStorageEmulatorInDevMode
} from 'src/environments/emulators/emulators';

import { getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { environment } from 'src/environments/environment';


import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR, ORIGIN as FUNCTIONS_ORIGIN } from '@angular/fire/compat/functions';

@NgModule({
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    ...[
      environment.useEmulators
        ? [AppCheckModule]
        : [
          provideAppCheck(() => {
            const provider = new ReCaptchaV3Provider(environment.appCheck.recaptcha3SiteKey);
            return initializeAppCheck(getApp(), { provider, isTokenAutoRefreshEnabled: true });
          })
        ]
    ],
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = getAuth();
      connectAuthEmulatorInDevMode(auth);
      return auth;
    }),
    provideFirestore(() => {
      const firestore = initializeFirestore(getApp(), {
        ignoreUndefinedProperties: true,
      })
      connectFirestoreEmulatorInDevMode(firestore);
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      connectFunctionsEmulatorInDevMode(functions);
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      connectStorageEmulatorInDevMode(storage);
      return storage;
    })
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    // compat providers
    {
      provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig
    },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    { provide: FUNCTIONS_ORIGIN, useFactory: () => isDevMode() ? undefined : location.origin },
  ]
})
export class FirebaseModule { }
