import { Component, Inject } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { NullableObject } from 'ngx-sub-form';

import { ItemService } from '../services/item.service';
import { ItemInspectionRecordService } from '../services/item-inspection-record.service';
import { AlseItemInspection } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';
import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';

@Component({
  templateUrl: './item-perform-inspection.component.html',
  styleUrls: ['./item-perform-inspection.component.css']
})
export class ItemPerformInspectionComponent {

  user: string;

  constructor(
    private itemService: ItemService,
    private itemInspectionRecordService: ItemInspectionRecordService,
    public dialogRef: MatDialogRef<ItemPerformInspectionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: AlseItemInspection,
    private store: Store,
  ) {
    this.store.selectOnce(state => state.auth.user)
    .subscribe(user => this.user = user?.displayName);
  }

  public record$: Observable<NullableObject<InspectionRecord>> = of(this.data).pipe(
    switchMap(inspection => {
      if (inspection.currentRecordId && inspection.itemId) {
        return this.itemInspectionRecordService.getItemInspectionRecord(inspection.itemId, inspection.currentRecordId);
      }
      return of(null);
    }),
    map(record => (record ? record : this.emptyRecord())),
  );

  private emptyRecord(): NullableObject<InspectionRecord> {
    return new InspectionRecord({
      id: this.itemService.createItemId(),
      itemId: this.data.itemId,
      inspectionId: this.data.id,
      ssn: this.data.ssn,
      tenant: this.data.tenant,
      itemType: this.data.itemType,
      categoryNumber: this.data.categoryNumber,
      itemNumber: this.data.itemNumber,
      type: this.data.type,
      user: this.user,
      dateDue: this.data.nextInspectionDate,
      datePerformed: new Date(),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveRecord(record: InspectionRecord): void {
    this.itemInspectionRecordService.upsertItemInspectionRecord(this.data, record).then(res => {
      this.dialogRef.close();
    });
  }

}
