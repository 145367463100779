export enum HardwareType {
  CARABINER = 'Carabiner',
  MAPRING = 'Map Ring',
  DELTALINK = 'Delta Link',
}

export enum HardwareTypeNumber {
  CARABINER = '001',
  MAPRING = '002',
  DELTALINK = '003',
}

export interface BaseHardwareItem {
}

export enum CarabinerType {
  STEEL = 'Steel',
  ALUMINIUM = 'Aluminium'
}

export interface CARABINER extends BaseHardwareItem {
  itemType: HardwareType.CARABINER;
  itemNumber: HardwareTypeNumber.CARABINER;
  type: CarabinerType;
}

export interface MAPRING extends BaseHardwareItem {
  itemType: HardwareType.MAPRING;
  itemNumber: HardwareTypeNumber.MAPRING;
}

export interface DELTALINK extends BaseHardwareItem {
  itemType: HardwareType.DELTALINK;
  itemNumber: HardwareTypeNumber.DELTALINK;
}

export type OneHardware = CARABINER | MAPRING | DELTALINK;
