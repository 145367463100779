import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';
import { PreferencesState } from 'global-shared/states/preferences/preferences.state';
import { TenantState } from 'src/app/state/tenant/tenant.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  appModules$: Observable<string[]>;
  tenant$: Observable<string>;

  constructor(
    private store: Store
  ) {
    this.appModules$ = this.store.select(TenantState.appModules);
    this.tenant$ = this.store.select(PreferencesState.tenant);
  }
}

