import { Component } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { TenantState } from 'src/app/state/tenant/tenant.state';

export interface Call {
  arrest: boolean;
  type: string;
  notes: string;
}

interface CallLogArray {
  calls: Call[]
}

@Component({
  selector: 'call-log-array',
  templateUrl: './call-log-array.component.html',
  styleUrls: ['./call-log-array.component.css'],
  providers: subformComponentProviders(CallLogArrayComponent),
})
export class CallLogArrayComponent {
  types$: Observable<string[]>;

  constructor(
    private store: Store,
  ) {
    this.types$ = this.store.select(TenantState.callTypes);
  }

  public form = createForm<Call[], CallLogArray>(this, {
    formType: FormType.SUB,
    formControls: {
      calls: new FormArray([])
    },
    toFormGroup: (obj: Call[]): CallLogArray => {
      return {
        calls: !obj ? [] : obj,
      }
    },
    fromFormGroup: (formValue: CallLogArray): Call[] => {
      return formValue.calls;
    },
    createFormArrayControl: (key, value) => {
      switch (key) {
        case 'calls':
          return new FormControl(value, Validators.required);
        default:
          return new FormControl(value);
      }
    }
  })

  public handleRemove(index: number): void {
    this.form.formGroup.controls.calls.removeAt(index);
  }

  public addCall(type): void {
    this.form.formGroup.controls.calls.push(
      this.form.createFormArrayControl('calls', {
        arrest: false,
        type,
        notes: null,
      }),
    );
  }

}
