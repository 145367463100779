import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { FratsActions } from './frats.actions';
import { FratActions } from '../frat/frat.actions';
import { mergeMap, tap } from 'rxjs/operators';
import { FratsService } from '../../../services/frats.service';
import { Frat } from 'global-shared/models/frat.model';
import { OrderByDirection } from '@angular/fire/firestore';

export interface FratsStateModel {
  frats: Frat[];
  queryConstraints: {
    active: string,
    direction: OrderByDirection,
    pageSize: number,
  },
  queryCount: number;
}

export const FRATS_STATE_TOKEN = new StateToken<FratsStateModel>('frats')

@State<FratsStateModel>({
  name: FRATS_STATE_TOKEN,
  defaults: {
    frats: [],
    queryConstraints: null,
    queryCount: null,
  }
})
@Injectable()
export class FratsState {

  @Selector()
  static frats(state: FratsStateModel): Frat[] {
    return state.frats;
  }

  @Selector()
  static queryCount(state: FratsStateModel): number {
    return state.queryCount;
  }

  constructor(
    private fratsService: FratsService,
  ) { }

  @Action(FratsActions.GetAll)
  getAll({ patchState }: StateContext<FratsStateModel>) {
    return this.fratsService.listFrats().pipe(
      tap((frats: Frat[]) => patchState({ frats }))
    ).subscribe();
  }

  /** Get query frats */
  @Action((FratsActions.GetQuery))
  getQuery({ patchState }: StateContext<FratsStateModel>): void {
    this.fratsService.dynmaicQueryFrats().pipe(
      tap((frats: Frat[]) => patchState({
        frats
      }))
    ).subscribe();
  }


  /** Setters to state */
  @Action(FratsActions.Set)
  set({ patchState }: StateContext<FratsStateModel>, { payload }: FratsActions.Set) {
    return patchState({
      frats: payload,
    })
  }

  @Action(FratsActions.SetCount)
  setCount({ patchState }: StateContext<FratsStateModel>, { payload }: FratsActions.SetCount) {
    return patchState({
      queryCount: payload,
    })
  }

  // Create
  @Action(FratsActions.Create)
  async create({ dispatch }: StateContext<FratsStateModel>, { payload }: FratsActions.Create) {
    await this.fratsService.upsert$(payload.id, payload);
    dispatch(new FratActions.New('dynamic'));
  }

  @Action(FratsActions.Upsert)
  async upsert({ dispatch }: StateContext<FratsStateModel>, { payload }: FratsActions.Upsert) {
    return (await this.fratsService.upsert$(payload.id, payload)).pipe(
      mergeMap(() => dispatch([
        new FratActions.New('dynamic'),
      ]))
    )
  }

}
