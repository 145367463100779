<fieldset [formGroup]="form.formGroup"
[class.invalid]="form.formGroupErrors" fxLayout="column">
  <legend>Maintanence details</legend>

  <section fxFlex fxLayout="row wrap" fxLayoutAlign="center">
    <mat-form-field class="" appearance="fill">
      <mat-label>Col Out</mat-label>
      <input matInput type="number" (change)="proccesFormUpdates()" [formControlName]="form.formControlNames.collectiveOut"
        matTooltip="Collective hours out">
    </mat-form-field>

    <mat-form-field class="" appearance="fill">
      <mat-label>Col In</mat-label>
      <input matInput type="number" (change)="proccesFormUpdates()" [formControlName]="form.formControlNames.collectiveIn"
        matTooltip="Collective hours in">
    </mat-form-field>

    <mat-form-field class="" appearance="fill">
      <mat-label>Col Hours</mat-label>
      <input matInput type="number" [formControlName]="form.formControlNames.collectiveHours"
        matTooltip="Collective hours">
    </mat-form-field>

    <mat-form-field class="" appearance="fill">
      <mat-label>Eng Out</mat-label>
      <input matInput type="number" (change)="proccesFormUpdates()" [formControlName]="form.formControlNames.engineOut" matTooltip="Engine hours out">
    </mat-form-field>

    <mat-form-field class="" appearance="fill">
      <mat-label>Eng In</mat-label>
      <input matInput type="number" (change)="proccesFormUpdates()" [formControlName]="form.formControlNames.engineIn" matTooltip="Engine hours in">
    </mat-form-field>

    <mat-form-field class="" appearance="fill">
      <mat-label>Eng Hours</mat-label>
      <input matInput type="number" [formControlName]="form.formControlNames.engineHours" matTooltip="Engine hours">
    </mat-form-field>
  </section>

  <section fxLayoutAlign="center" fxLayout="column">
    <mat-error *ngIf="form.formGroupErrors?.formGroup?.collectiveInHoursLessThanOut">Collective in hours are less than
      the out hours</mat-error>
    <mat-error *ngIf="form.formGroupErrors?.formGroup?.engineInHoursLessThanOut">Engine in hours are less than the out
      hours</mat-error>
    <mat-error *ngIf="form.formGroupErrors?.formGroup?.collectiveHoursMoreThanEngine">Collective hours are more than the
      engine hours</mat-error>
  </section>

</fieldset>
