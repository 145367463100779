import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TEST, MiscType, OneMisc } from 'global-shared/models/alse-base-models/item-types/misc-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

interface OneMiscForm {
  test: TEST | null;
  itemType: MiscType | null;
}

@Component({
  selector: 'app-misc-item-form',
  templateUrl: './misc-item-form.component.html',
  styleUrls: ['./misc-item-form.component.css'],
  providers: subformComponentProviders(MiscItemFormComponent),
})
export class MiscItemFormComponent {

  public MiscType = MiscType;

  public form = createForm<OneMisc, OneMiscForm>(this, {
    formType: FormType.SUB,
    formControls: {
      test: new FormControl(null),
      itemType: new FormControl(null, { validators: [Validators.required] }),
    },
    toFormGroup: (obj: OneMisc): OneMiscForm => {
      return {
        test: obj.itemType === MiscType.TEST ? obj : null,
        itemType: obj.itemType,
      }
    },
    fromFormGroup: (formValue: OneMiscForm): OneMisc => {
      switch (formValue.itemType) {
        case MiscType.TEST:
          return formValue.test;
        case null:
          return null;
      }
    }
  })

}
