<ng-container [formGroup]="form.formGroup" class="container" data-call>

  <div fxLayout="row" fxLayout="column" fxFlex>

    <section fxLayout="row">

      <mat-form-field class="call-type-select" appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select [formControlName]="form.formControlNames.type" name="role">
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox [formControlName]="form.formControlNames.arrest">Arrest</mat-checkbox>

    </section>

    <mat-form-field appearance="fill">
      <mat-label>Notes</mat-label>
      <textarea matInput placeholder="Notes..." [formControlName]="form.formControlNames.notes"></textarea>
    </mat-form-field>

  </div>

</ng-container>
