<mat-card class="mat-elevation-z8">
  <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
    <google-chart
    style="width: 100%;"
    [title]="'Status'"
    [type]="chartType"
    [data]="fratsByFlightStatus$ | async"></google-chart>
    <google-chart
    style="width: 100%;"
    [title]="'Users'"
    [type]="chartType"
    [data]="fratsByUser$ | async"></google-chart>
    <google-chart
    style="width: 100%;"
    [title]="'Units'"
    [type]="chartType"
    [data]="fratsByUnit$ | async"></google-chart>
  </mat-card-content>
</mat-card>
