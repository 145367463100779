import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NVG, PpeType, PpeTypeNumber } from 'global-shared/models/alse-base-models/item-types/ppe-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'app-nvg-item',
  templateUrl: './nvg-item.component.html',
  styleUrls: ['./nvg-item.component.css'],
  providers: subformComponentProviders(NvgItemComponent),
})
export class NvgItemComponent {
  public form = createForm<NVG>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(PpeType.NVG, { validators: [Validators.required] }),
      itemNumber: new FormControl(PpeTypeNumber.NVG, { validators: [Validators.required] }),
    }
  })
}
