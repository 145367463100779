import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HardwareType, HardwareTypeNumber, MAPRING } from 'global-shared/models/alse-base-models/item-types/hardware-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';


@Component({
  selector: 'app-map-ring-item',
  templateUrl: './map-ring-item.component.html',
  styleUrls: ['./map-ring-item.component.css'],
  providers: subformComponentProviders(MapRingItemComponent),
})
export class MapRingItemComponent {
  public form = createForm<MAPRING>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(HardwareType.MAPRING, Validators.required),
      itemNumber: new FormControl(HardwareTypeNumber.MAPRING, Validators.required),
    }
  })
}
