import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TEST, MiscType, MiscTypeNumber } from 'global-shared/models/alse-base-models/item-types/misc-types';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'app-test-item',
  templateUrl: './test-item.component.html',
  styleUrls: ['./test-item.component.css'],
  providers: subformComponentProviders(TestItemComponent),
})
export class TestItemComponent {
  public form = createForm<TEST>(this, {
    formType: FormType.SUB,
    formControls: {
      itemType: new FormControl(MiscType.TEST),
      itemNumber: new FormControl(MiscTypeNumber.TEST),
    }
  })

}
