import { Component, OnInit } from '@angular/core';
import { ResetForm } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FratActions } from '../../core/state/frat/frat.actions';
import { FRAT_FORM_PATH } from '../../core/state/frat/frat.state';
import { GetQuestions } from '../../core/state/tenant-questions/questions.actions';
import { QuestionsState } from '../../core/state/tenant-questions/questions.state';

@Component({
  selector: 'app-frat-toolbar',
  templateUrl: './frat-toolbar.component.html',
  styleUrls: ['./frat-toolbar.component.css']
})
export class FratToolbarComponent implements OnInit {

  syncing$: Observable<boolean>;

  constructor(
    private store: Store,
  ) {
    this.syncing$ = this.store.select(QuestionsState.syncing);
  }

  ngOnInit(): void {
  }

  syncQuestions(): void {
    this.store.dispatch(new GetQuestions());
  }

  clearQuestions(type: 'dynamic' | 'new'): void {
    this.store.dispatch(new FratActions.New(type));
  }
}
