import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { merge, Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Frat } from 'global-shared/models/frat.model';
import { FratsActions } from '../../core/state/frats/frats.actions';
import { FratsService } from '../../services/frats.service';
import { FratDialogComponent } from '../frat-dialog/frat-dialog.component';

import { DataSource } from '@angular/cdk/table';
import { FratsState } from '../../core/state/frats/frats.state';

@Component({
  selector: 'app-frats-table',
  templateUrl: './frats-table.component.html',
  styleUrls: [
    '../../../../../../global-shared/styles/tables.global.css',
    './frats-table.component.css'
  ]
})
export class FratsTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: FratsTableDataSource;

  displayedColumns: string[] = [
    // 'id',
    'status_icon',
    'score',
    'user.displayName',
    'unit.callSign',
    'timestamps.createdAt',
    'status.flight',
  ]

  constructor(
    private dialog: MatDialog,
    private fratsService: FratsService,
    private store: Store,
  ) {
    this.fratsService.queryConstraints$.next({
      active: 'timestamps.createdAt', direction: 'desc', pageSize: 5
    })
  }

  ngOnInit(): void {
    this.store.dispatch(new FratsActions.GetQuery());
    this.dataSource = new FratsTableDataSource(this.store);
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((values) => {
          this.fratsService.queryConstraints$.next({
            ...this.fratsService.queryConstraints$.value,
            ...values
          });
        }),
      ).subscribe();
  }

  openDialog(frat): void {
    this.dialog.open(FratDialogComponent, {
      data: frat,
    });
  }

}

class FratsTableDataSource extends DataSource<Frat> {
  frats$: Observable<Frat[]>;
  count$: Observable<number>;

  constructor(
    private store: Store,
  ) {
    super();
    this.frats$ = this.store.select(FratsState.frats);
    this.count$ = this.store.select(FratsState.queryCount);
  }

  connect(): Observable<readonly Frat[]> {
      return this.frats$;
  }

  disconnect(): void {
  }
}
