import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { bbox, circle } from 'global-shared/turf/turf';
import { PositionState } from 'global-shared/states/position/position.state';

import { MetarsGeoJSON } from 'global-shared/models/metars/metars.model';

@Injectable()
export class MetarsService {
  private readonly apiRoot = 'https://aviationweather.gov/cgi-bin/json/MetarJSON.php';

  private _coords: GeolocationCoordinates;

  staticMapImageWithMetars: any;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) { }

  fetchMetars(): Observable<MetarsGeoJSON> {
    let bboxString = [-130, 20, -60, 60].toString();

    this._coords = this.store.selectSnapshot(PositionState.coords);
    const center = [this._coords?.longitude, this._coords?.latitude];

    if (center[0] && center[1]) {
      bboxString = this.getCircleBbox(center, 100);
    }

    const apiUrl = `${this.apiRoot}?jsonp=JSONP_CALLBACK&bbox=${bboxString}&filter=prior&taf=1`;

    return this.http.jsonp<MetarsGeoJSON>(apiUrl, 'JSONP_CALLBACK').pipe(
      catchError(this.handleError)
    );
  }

  private getCircleBbox(center: number[], radius: number): string {
    return bbox(circle(center, radius, { units: 'miles' })).toString();
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An Metars error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Metars returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error('Something bad happened with METARS; please try again later.'));
  }
}
