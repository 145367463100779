<mat-card fxLayoutAlign="center center">
  <button mat-raised-button (click)="resetApp()" color="warn">
    Reset application
  </button>
</mat-card>

<mat-card>
  <p>Remote config</p>
  <pre> {{ change$ | async | json }}</pre>
</mat-card>

<mat-card>
  <mat-card-header>
    This card is used for appliation testing only, ignore anything.
  </mat-card-header>
  <mat-card-content>
    <mat-divider></mat-divider>
    <p>Tenant State</p>
    <pre>
      {{ tenantState$ | async |json }}
    </pre>
    <mat-divider></mat-divider>
    <P>Tenant Users</P>
    <pre>
      {{ tenantUsers$ | async | json }}
    </pre>
    <P>Tenant Units</P>
    <pre>
      {{ tenantUnits$ | async | json }}
    </pre>
    <mat-divider></mat-divider>
    <p>Users State</p>
    <pre>
      {{ usersState$ | async | json }}
    </pre>
  </mat-card-content>
</mat-card>

