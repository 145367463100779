<mat-toolbar class="action-bar" *ngIf="(scores$ | async) as scores">
  <span fxFlex></span>
  <p>Static: {{ scores.staticValue }}</p>
  <span fxFlex></span>
  <p>Dynamic: {{ scores.dynamicValue }}</p>
  <span fxFlex></span>
  <p>Total: {{ scores.totalScore }} / {{ scores.totalPossibleValue }}</p>
  <span fxFlex></span>
</mat-toolbar>
<mat-toolbar>
  <button data-button-submit color="primary" mat-raised-button (click)="handleSubmission(_flightStatus.ACCEPTED)"
    [disabled]="submitReady">Accept</button>

  <button data-button-submit color="warn" mat-raised-button (click)="handleSubmission(_flightStatus.DECLINED)"
    [disabled]="submitReady">Decline</button>
</mat-toolbar>
