<ng-container *ngIf="(claims$ | async) as claims; else signin">

  <ng-container *ngIf="(tenant$ | async) as tenant">

    <mat-tree *ngIf="treeNodes$ | async" [dataSource]="dataSource" [treeControl]="treeControl">

      <mat-tree-node *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
        [routerLink]="transformRouteLink(tenant, node, claims?.user_id)"
        routerLinkActive>
        <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'" matListIcon>
          {{ node.icon }}
        </mat-icon>
        {{node.name}}
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
         matTreeNodeToggle
         matTreeNodePadding
         [class.parent]="hasChild" >
          <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'" matListIcon>
            {{ node.icon }}
          </mat-icon>
          {{node.name}}
      </mat-tree-node>

    </mat-tree>

  </ng-container>

</ng-container>

<ng-template #signin>
  <mat-nav-list>
    <a mat-list-item routerLink="signin" routerLinkActive>Login
      <mat-icon matListIcon>login</mat-icon>
    </a>
  </mat-nav-list>
</ng-template>
