import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FratQuestionV2, FratOptionV2 } from 'global-shared/models/question.model';

const RiskLevel = {
  "1": 'acceptable',
  "2": 'caution',
  "4": 'high-risk'
}

@Component({
  selector: 'app-frat-question-select-form',
  templateUrl: './frat-question-select-form.component.html',
  styleUrls: ['./frat-question-select-form.component.css'],
  providers: subformComponentProviders(FratQuestionSelectFormComponent)
})
export class FratQuestionSelectFormComponent {

  riskLevel = RiskLevel;

  public form = createForm<FratQuestionV2>(this, {
    formType: FormType.SUB,
    formControls: {
      section: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      question: new FormControl(null, Validators.required),
      selected: new FormControl(null, Validators.required),
      options: new FormControl(null, Validators.required),
    }
  });

  compareFn(o1: FratOptionV2, o2: FratOptionV2): boolean {
    return o1 && o2 ? o1.risk === o2.risk : o1 === o2;
  }

}
