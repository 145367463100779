import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';

if (environment.production) {

  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.name,
    release: environment.sentry.release,
  });


  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
