import { Inject, Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Layout } from './layout.actions';

export interface LayoutStateModel {
  showSidenav: boolean;
  isHandset: boolean;
}

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    showSidenav: false,
    isHandset: false,
  }
})
@Injectable()
export class LayoutState {
  @Selector()
  public static showSidenav(state: LayoutStateModel): boolean {
    return state.showSidenav;
  }

  @Selector()
  public static isHandset(state: LayoutStateModel): boolean {
    return state.isHandset;
  }

  @Action(Layout.IsHandset)
  setHandset({ patchState }: StateContext<LayoutStateModel>, { payload }: Layout.IsHandset): void {
    patchState({ isHandset: payload });
  }

  @Action(Layout.OpenSidenav)
  openSidenav({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ showSidenav: true });
  }

  @Action(Layout.CloseSidenav)
  closeSidenav({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ showSidenav: false });
  }
}
