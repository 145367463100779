<div fxLayout="column">

  <button mat-raised-button
   matTooltip="Upload attachment for this inspection."
   color='accent'
   (click)="fileInput.click()">
    <mat-icon>cloud_upload</mat-icon>
    <input #fileInput type="file" (change)="uploadFile($event)" hidden>
  </button>

  <div *ngIf="percentage | async as pct">
    <mat-progress-bar mode="determinate" [value]="pct" max="100"></mat-progress-bar>
    {{ pct | number }}%
  </div>

  <div *ngIf="snapshot | async as snap">
    <!-- <div *ngIf="downloadURL as url">
      <h3>Attachment Saved</h3>
    </div> -->
  </div>
