<mat-card *ngIf="(loading$ | async) as loading" fxLayoutAlign="center center">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-card>

<mat-card *ngIf="!(loading$ | async) && (user$ | async) === null as user">
  <mat-card-header>
    <mat-card-title>
      Sign Providers
    </mat-card-title>
    <mat-card-subtitle>
      Choose a provider to sign in with.
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content fxLayout="column" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" (click)="googleLogin()">
      <mat-icon>account_circle</mat-icon>
      Google
    </button>
    <button mat-raised-button color="accent" (click)="microsoftLogin()">
      <mat-icon>account_circle</mat-icon>
      Microsoft
    </button>
    <button mat-raised-button routerLink="/login">
      <mat-icon>login</mat-icon>
      Email & Password
    </button>
  </mat-card-content>

</mat-card>
