import { Routes } from '@angular/router';
import { FratRootContainerComponent } from './frat-root-container/frat-root-container.component';
import { FratsContainerComponent } from './views/frats-container/frats-container.component';

export const FRAT_ROUTES: Routes = [
  {
    path: '',
    component: FratRootContainerComponent,
  },
  {
    path: 'list',
    component: FratsContainerComponent,
  }
];
