// tslint:disable-next-line: no-namespace
export namespace Layout {
  export class IsHandset {
    static readonly type = '[LAYOUT] Is Handset';
    constructor(public payload: boolean) {}
  }
  export class OpenSidenav {
    static readonly type = '[LAYOUT] open sidenav';
  }

  export class CloseSidenav {
    static readonly type = '[LAYOUT] close sidenav';
  }
}
