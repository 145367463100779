import { Selector } from "@ngxs/store";
import { FLIGHT_STATUS_OPTIONS } from "global-shared/models/flight.model";
import { FratsStateModel, FRATS_STATE_TOKEN } from "./frats.state";

export class FratsChartsSelctors {
  @Selector([FRATS_STATE_TOKEN])
  static chartByUnit(state: FratsStateModel) {
    const units = {};
    state.frats.forEach(frat => {
      const unit = frat.unit.tailNumber;
      units[unit] ? units[unit]++ : units[unit] = 1;
    })
    return [...Object.entries(units)];
  }

  @Selector([FRATS_STATE_TOKEN])
  static chartByUser(state: FratsStateModel) {
    const users = {};
    state.frats.forEach(frat => {
      const user = frat.user.displayName;
      users[user] ? users[user]++ : users[user] = 1;
    })
    return [...Object.entries(users)];
  }


  @Selector([FRATS_STATE_TOKEN])
  static chartByFlightStatus(state: FratsStateModel): (string | number)[][] {
    let accpeted = 0;
    let declined = 0;
    let uncategorized = 0;

    state.frats.forEach(frat => {
      switch (frat.status?.flight) {
        case FLIGHT_STATUS_OPTIONS.ACCEPTED:
          accpeted++;
          break;
        case FLIGHT_STATUS_OPTIONS.DECLINED:
          declined++;
          break;
        default:
          uncategorized++;
          break;
      }
    });

    return [
      ['ACCEPTED', accpeted],
      ['DECLINED', declined],
      ['UNCATEGORIZED', uncategorized],
    ]
  }

}
