import { Frat } from "global-shared/models/frat.model";

export namespace FratsActions {

  export class Create {
    public static readonly type = '[FRATS] Create';
    constructor(public payload: Frat) { }
  }

  export class GetAll {
    public static readonly type = '[FRATS] GetAll';
  }

  export class Get {
    public static readonly type = '[FRATS] Get';
    constructor(public payload: string) { }
  }

  export class GetQuery {
    public static readonly type = '[FRATS] Get query';
  }

  export class Set {
    public static readonly type = '[FRATS] Set';
    constructor(public payload: Frat[]) {}
  }

  export class SetCount {
    public static readonly type = '[FRATS] Set count';
    constructor(public payload: number) {};
  }

  export class Upsert {
    public static readonly type = '[FRATS] Upsert';
    constructor(public payload: Partial<Frat>) { }
  }
}
