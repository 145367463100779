import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { FirestoreService } from 'src/app/core/firebase/firestore.service';

import { Store } from '@ngxs/store';

// import { AppContextService } from 'src/app/services/app-context.service';

import * as moment from 'moment';

import { DB } from 'functions/src/models/db.model';
import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';
import { convertTimestampsPipe } from 'global-shared/pipes/convert-firebase-timestamp/convert-firebase-timestamp.pipe';
import { AlseItemInspection, InspectionStatus, InspectionType } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection';
import { OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';

@Injectable({
  providedIn: 'root'
})
export class ItemInspectionRecordService {

  constructor(
    // private afs: FirestoreService,
    // private appContext: AppContextService,
    private db: AngularFirestore,
    private store: Store,
  ) { }

  get tenant(): string {
    return this.store.selectSnapshot(state => state.preferences.tenant);
  }

  upsertItemInspectionRecord(inspection: AlseItemInspection, record: InspectionRecord): Promise<void> {
    const batch = firebase.firestore().batch();

    const itemDoc = this.db.doc<OneAlseItem>(`tenants/${record.tenant}/alse/metadata/${DB.Collection.ITEMS}/${record.itemId}`);
    const itemInspectionDoc = itemDoc.collection<AlseItemInspection>(DB.Collection.INSPECTIONS).doc(record.inspectionId);
    const itemHistoryCol = itemDoc.collection<InspectionRecord>(DB.Collection.HISTORY).doc(record.id);

    inspection.status = record.status;
    inspection.currentRecordId = record.id;
    batch.set(itemHistoryCol.ref, { ...record });

    if (inspection && record.status === InspectionStatus.COMPLETE) {

      let updateInspection = true;

      if (inspection.type === InspectionType.BUILDUP) {
        batch.delete(itemInspectionDoc.ref);
        updateInspection = false;
      }
      // if (inspection.type === InspectionType.ENDOFLIFE) {
      //   this.afs.deleteCollection(`tenants/${record.tenant}/alse/metadata/${DB.Collection.ITEMS}/${inspection.itemId}/${DB.Collection.INSPECTIONS}`, 10)
      //     .toPromise();
      //   updateInspection = false;
      // }
      if (updateInspection) {
        inspection.status = InspectionStatus.DUE;
        inspection.currentRecordId = null;
        batch.update(itemInspectionDoc.ref, AlseItemInspection.bumpInspectionCycle(inspection, record.datePerformed));
      }
    } else if (inspection && record.status !== InspectionStatus.COMPLETE) {
      batch.update(itemInspectionDoc.ref, inspection);
    }

    batch.update(itemDoc.ref, { updatedAt: new Date() });

    return batch.commit();
  }

  getItemInspectionRecord(itemId: string, id: string): Observable<InspectionRecord> {
    return this.db.doc<OneAlseItem>(`tenants/${this.tenant}/alse/metadata/${DB.Collection.ITEMS}/${itemId}`)
      .collection<InspectionRecord>(`${DB.Collection.HISTORY}`)
      .doc(id)
      .valueChanges().pipe(
        convertTimestampsPipe(),
        map(inspectionRecord => new InspectionRecord(inspectionRecord)),
      );
  }

  listItemInspectionRecords(itemId: string): Observable<InspectionRecord[]> {
    return this.db.doc<OneAlseItem>(`tenants/${this.tenant}/alse/metadata/${DB.Collection.ITEMS}/${itemId}`)
      .collection<InspectionRecord>(`${DB.Collection.HISTORY}`, ref =>
        ref.orderBy('datePerformed', 'desc')
      )
      .valueChanges();
  }

  listRecentInspectionRecords(): Observable<InspectionRecord[]> {
    const startDate = moment(new Date()).subtract(15, 'days').toDate();
    return this.db.collectionGroup<InspectionRecord>(DB.Collection.HISTORY, ref =>
      ref
        .where('tenant', '==', this.tenant)
        .orderBy('datePerformed', 'desc')
        .where('datePerformed', '>=', firebase.firestore.Timestamp.fromDate(startDate))
    ).valueChanges();
  }
}
