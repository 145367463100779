import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { PositionState } from 'global-shared/states/position/position.state';
import { QuestionsState } from '../core/state/tenant-questions/questions.state';
import { FratState } from '../core/state/frat/frat.state';
import { FratsState } from '../core/state/frats/frats.state';
import { MetarsState } from 'global-shared/states/metars/metars.state';

const MODULES = [
  NgxsModule.forFeature([
    PositionState,
    QuestionsState,
    FratState,
    FratsState,
    MetarsState,
  ]),
  NgxsFormPluginModule.forRoot(),
];

@NgModule({
  declarations: [],
  imports: [
    MODULES
  ],
  // exports: [
  //   MODULES
  // ]
})
export class FratNgxsModule { }
