<!-- Hide add crew member module if in readonly mode -->
<fieldset *ngIf="!form.formGroup.disabled">
  <legend>Add call</legend>

  <div fxFlex fxLayout="row wrap" fxLayoutAlign="center">
    <button *ngFor="let type of (types$ | async) as types" mat-stroked-button color="primary" (click)="addCall(type)"
      [disabled]="form.formGroup.disabled" class="add-button">
      {{ type }}
    </button>
  </div>
</fieldset>

<fieldset [formGroup]="form.formGroup" [class.invalid]="form.formGroupErrors">
  <legend>Call log</legend>

  <div fxLayoutAlign="row" [formArrayName]="form.formControlNames.calls"
    *ngFor="let control of form.formGroup.controls.calls.controls; let i = index">

    <call-control [types]="types$ | async" [formControl]="control" fxFlex></call-control>

    <button *ngIf="!form.formGroup.disabled" mat-icon-button color="warn" (click)="handleRemove(i)"
      [disabled]="form.formGroup.disabled">
      <mat-icon>delete</mat-icon>
    </button>

  </div>

</fieldset>
