import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChartType } from 'angular-google-charts';
import { Observable } from 'rxjs';
import { FratsChartsSelctors } from '../../core/state/frats/frats-charts.selectors';

@Component({
  selector: 'app-frats-charts',
  templateUrl: './frats-charts.component.html',
  styleUrls: ['./frats-charts.component.css']
})
export class FratsChartsComponent implements OnInit {

  fratsByFlightStatus$: Observable<(string | number)[][]>
  fratsByUnit$;
  fratsByUser$;

  chartType = ChartType.PieChart;
  chartTitle = 'Status'

  constructor(
    private store: Store
  ) {
    this.fratsByFlightStatus$ = this.store.select(FratsChartsSelctors.chartByFlightStatus);
    this.fratsByUser$ = this.store.select(FratsChartsSelctors.chartByUser);
    this.fratsByUnit$ = this.store.select(FratsChartsSelctors.chartByUnit);
  }

  ngOnInit(): void {
  }

}
