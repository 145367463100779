<mat-card>

  <mat-card-header>

    <mat-card-title>
      Items Due
    </mat-card-title>

    <mat-card-subtitle>
      Inspections past due and with in 15 days.
    </mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <mat-list>
      <mat-list-item
       *ngFor="let item of itemsDue$ | async"
       (click)="openDialog(item)">
        <span matListLineItem>{{item.ssn}} | {{item.itemType}} - {{ item.type }}</span>
        <span matListLineItem>Status: {{ item.status }}</span>
        <span matListLineItem> Due: {{item.nextInspectionDate | date}} </span>
      </mat-list-item>
    </mat-list>

  </mat-card-content>

</mat-card>
