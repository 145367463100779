import { Component, OnInit } from '@angular/core'
import { InspectionRecord } from 'global-shared/models/alse-base-models/item-inspections/alse-inspection-record';
import { Observable } from 'rxjs';
import { ItemInspectionRecordService } from '../../services/item-inspection-record.service';

@Component({
  selector: 'app-recent-inspection-records',
  templateUrl: './recent-inspection-records.component.html',
  styleUrls: ['./recent-inspection-records.component.css']
})
export class RecentInspectionRecordsComponent implements OnInit {

  recentInspectionRecords$: Observable<InspectionRecord[]>;

  constructor(
    private itemInspectionRecordService: ItemInspectionRecordService,
  ) { }

  ngOnInit(): void {
    this.recentInspectionRecords$ = this.itemInspectionRecordService.listRecentInspectionRecords();
  }

}
