<mat-card>
  <mat-card-header>
    <mat-card-title>
      ALSE
    </mat-card-title>
    <mat-card-subtitle>
      Aviation Life Support Equipment
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" [routerLink]="['items']">Items</button>
    <button mat-raised-button color="primary" [routerLink]="['items/new/edit']">Add new item</button>
  </mat-card-actions>
</mat-card>
<mat-divider></mat-divider>
<div>
  <app-items-due-container></app-items-due-container>
  <mat-divider></mat-divider>
  <app-recent-inspection-records></app-recent-inspection-records>
</div>
