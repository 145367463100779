import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { Subscription, Observable, of } from 'rxjs';
import { switchMap, map, tap, skipWhile } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/state/auth.state';

import { NullableObject } from 'ngx-sub-form';

import { FirestoreAlseItem, OneAlseItem } from 'global-shared/models/alse-base-models/item-base/alse-main-item';
import { ItemService } from '../services/item.service';

import { STATUS } from 'global-shared/models/alse-base-models/item-base/alse-base';


@Component({
  selector: 'app-item-container',
  templateUrl: './item-container.component.html',
  styleUrls: ['./item-container.component.css']
})
export class ItemContainerComponent implements OnDestroy {
  public readonlyFormControl: FormControl = new FormControl(false);

  new: boolean;
  id: string;
  urlSubscription: Subscription;

  claims$: Observable<any>;
  item$: Observable<NullableObject<OneAlseItem>>

  constructor(
    private itemService: ItemService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {
    this.claims$ = this.store.select(AuthState.claims);

    this.item$ = this.route.paramMap.pipe(
      // tap(console.log),
      map(params => params.get('id')),
      switchMap(id => {
        if (id === 'new' || !id) {
          this.new = true;
          return of(null);
        }
        return this.itemService.getItem(id);
      }),
      map(item => item ? new FirestoreAlseItem(item) as OneAlseItem : this.emptyItem()),
      tap(item => this.id = item.id),
    );


    // tap(url => {
    //   if (url[3] && url[3].path !== 'edit') {
    //     this.readonlyFormControl.setValue(true);
    //   }
    // })
  }

  private emptyItem(): NullableObject<OneAlseItem> {
    console.log('empty item ran');
    return {
      id: this.itemService?.createItemId(),
      ssn: null,
      tenant: null,
      category: null,
      item: null,
      status: STATUS.NONRFI,
      location: null,
      manufacturerDetails: null,
      startInspectionsDate: new Date(),
      inspections: null,
      attachments: null,
      updatedAt: null,
      createdAt: null,
    };
  }

  public upsertItem(item: OneAlseItem): void {
    console.log('upsertItem.click', item)
    this.itemService.upsertItem(item);
    this.new ? this.router.navigate([item.tenant, 'alse', 'items', item.id, 'edit']) : null;
  }

  public handleDelete(val: boolean): void {
    if (val) {
      // this.itemService.deleteItem(this.id);
      this.router.navigate(['alse/items']);
    }
  }

  ngOnDestroy(): void {
    // this.urlSubscription.unsubscribe();
  }
}
