import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

export interface RouterStateParams {
  tenant: string;
  app: string;
}
// Map the router snapstop to { tenant, app }
export class AppRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
    } = routerState;

    const [root, tenant, app] = url.split('/');

    return { tenant, app };
  }
}
