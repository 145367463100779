import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CrewMember, CrewMemberRole } from 'global-shared/models/crewmember.model';
import { AppUser } from 'global-shared/models/user-account.model';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { TenantState } from 'src/app/state/tenant/tenant.state';

@Component({
  selector: 'form-crewmember-control',
  templateUrl: './crewmember-control.component.html',
  styleUrls: ['./crewmember-control.component.css'],
  providers: subformComponentProviders(CrewmemberControlComponent),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrewmemberControlComponent implements OnInit {
  roles = Object.values(CrewMemberRole);
  users$: Observable<Partial<AppUser>[]>;

  public form = createForm<CrewMember>(this, {
    formType: FormType.SUB,
    formControls: {
      role: new FormControl(null, [Validators.required]),
      user: new FormControl(null, [Validators.required]),
    }
  })

  constructor(
    private store: Store,
  ) {
    this.users$ = this.store.select(TenantState.users);
  }

  ngOnInit(): void {
  }

  compareRoleWith(o1, o2) {
    return o1 == o2 ? true : false;
  }

  compareUserWith(o1, o2) {
    if (o1 && o2) {
      return o1.uid == o2.uid ? true : false;
    }
  }

}
