<form [formGroup]="form.formGroup" autocomplete="off">

  <mat-card>
    <mat-card-header>
      <h3>{{ form.formGroup.value?.ssn }} | {{ form.formGroup.value?.itemType }} -
        <a [href]="cardLink" target="_blank">
          {{ form.formGroup.value?.type }}
        </a>
      </h3>
    </mat-card-header>
    <mat-card-content>

      <div fxLayout.gt-sm="row">

        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Date performed</mat-label>
          <input matInput [matDatepicker]="datePerformed" [formControlName]="form.formControlNames.datePerformed">
          <mat-datepicker-toggle matSuffix [for]="datePerformed"></mat-datepicker-toggle>
          <mat-datepicker touchUi #datePerformed></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Technician</mat-label>
          <input matInput type="text" [formControlName]="form.formControlNames.user">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
          <mat-label>QA Technician</mat-label>
          <input matInput type="text" [formControlName]="form.formControlNames.qaUser">
        </mat-form-field>

      </div>

      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Notes</mat-label>
        <input matInput type="textarea" [formControlName]="form.formControlNames.notes">
      </mat-form-field>

    </mat-card-content>
    <mat-card-footer>

      <div fxLayout="column">

        <mat-form-field fxFlex appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select matNativeControl [formControlName]="form.formControlNames.status">
            <mat-option *ngFor="let status of InspectionStatus | keyvalue" [value]="status.value">
              {{ status.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span fxFlex></span>

        <div>

          <app-upload-file [record]="form.formGroup.value" (attachment)="handleAttachment($event)"></app-upload-file>

          <span fxFlex></span>

          <div *ngIf="form.formGroup.value?.attachment">
            <a [href]="form.formGroup.value?.attachment.downloadURL" target="_blank">
              <mat-icon>attachment</mat-icon>Attachment
            </a>
          </div>

        </div>

        <span fxFlex></span>

        <button mat-raised-button color="primary" (click)="manualSave$.next()"
          [disabled]="form.formGroup.invalid || form.formGroup.disabled">Save item</button>

      </div>

    </mat-card-footer>
  </mat-card>

</form>
